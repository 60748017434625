import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";

const defaultTheme = createTheme();

const PrivacyPolicy: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };
  const sendEmail = (email: any) => {
    const mailtoUrl = `mailto:${email}`;
    window.location.href = mailtoUrl;
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Privacy Policy")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t("Keep it underground")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box className="titleTextOverlayContainerMain">
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "The information in the fine print on this page is significant"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "VicPay and subsidiaries (“Victorum”, “we”, or “us”) welcome you to our home page. We are delighted that you are using our e-services, and that you are interested in our products and services"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "In a world where data breaches, hacking and phishing attacks are on the rise, we pay great attention to protecting your privacy and your personal data"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "We understand that by using our services and technologies, you are entrusting us with personal information about yourself. For this reason, we certify that we are aware of our responsibility for the protection and security of such data"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "We can also guarantee that our company will always use your personal data in a reliable and fair manner, in compliance with all relevant data protection regulations. More information can be found in the Data Protection Declaration"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "We guarantee that we will only disclose your personal data to third parties (authorities, tax authorities, banks, etc.) if this is requested by a court or similar legal ruling"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "You have the right to be accurately informed about how we use your personal data; just click here and we will inform you clearly and in detail about what data about you we store and how we use it"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "If you have any concerns about our use of your personal data, we will be happy to help clarify the issue; simply send us an email to"
                  )}{" "}
                  <span
                    onClick={() =>
                      sendEmail("personal-data@vicpayments.com")
                    }
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    className="linksHover"
                  >
                    {" "}
                    personal-data@vicpayments.com
                  </span>
                  .
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
