import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import "./MobileNavigation.scss";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AddIcon from "@mui/icons-material/Add";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { Grid, SpeedDial, SpeedDialAction, useMediaQuery } from "@mui/material";
import { useAppSelector } from "src/store";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import i18next from "src/i18n";
import CloseIcon from "@mui/icons-material/Close";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";

const MobileNavigation = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [selected, setSelected] = useState("Home");
  const [value, setValue] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery("(min-width:1000px)");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigationLinks = (path: string) => {
    navigate(path);
  };

  const handleNavigation = (
    path: string,
    menuItem: string,
    index: number | null
  ) => {
    setSelected(menuItem);
    setValue(index);
    navigate(path);
    if (!isDesktop) setOpen(false);
  };

  const navigationItems = pathname.includes("admin")
    ? user && user.role === "Employee"
      ? [
          {
            path: "/admin",
            label: "CRM",
            icon: <HomeIcon className="muiIcons" />,
          },
          {
            path: "/transactions-list",
            label: "Transactions",
            icon: <AccountBalanceIcon className="bottomNavBarIcons" />,
          },

          {
            path: "/admin/users",
            label: "Users",
            icon: <GroupIcon className="muiIcons" />,
          },
          {
            path: "/admin/recipients",
            label: "Recipients",
            icon: <GroupAddIcon className="muiIcons" />,
          },
          {
            path: "/admin/balance-list/request-payments",
            label: "Requests",
            icon: <HourglassTopIcon className="muiIcons" />,
          },
          // {
          //   path: "/admin/investments",
          //   label: "Investments",
          //   icon: <ShowChartIcon className="muiIcons" />,
          // },
        ]
      : [
          {
            path:
              user && user.role === "Employee" ? "/transactions-list" : "/home",
            label:
              user && user.role === "Employee"
                ? i18next.t("Transactions")
                : i18next.t("Platform"),
            icon:
              user && user.role === "Employee" ? (
                <AccountBalanceIcon className="bottomNavBarIcons" />
              ) : (
                <ArrowBackIcon className="muiIcons" />
              ),
          },
          {
            path: "/admin",
            label: i18next.t("Admin"),
            icon: <HomeIcon className="muiIcons" />,
          },
          {
            path: "/admin/users",
            label: i18next.t("Users"),
            icon: <GroupIcon className="muiIcons" />,
          },
          {
            path: "/admin/recipients",
            label: i18next.t("Recipients"),
            icon: <GroupAddIcon className="muiIcons" />,
          },
          {
            path: "/admin/balance-list/request-payments",
            label: i18next.t("Requests"),
            icon: <HourglassTopIcon className="muiIcons" />,
          },
          // {
          //   path: "/admin/investments",
          //   label: "Investments",
          //   icon: <ShowChartIcon className="muiIcons" />,
          // },
        ]
    : user && user.role === "Employee"
    ? [
        {
          path: "/admin",
          label: i18next.t("CRM"),
          icon: <HomeIcon className="muiIcons" />,
        },
        {
          path: "/transactions-list",
          label: i18next.t("Transactions"),
          icon: <AccountBalanceIcon className="bottomNavBarIcons" />,
        },
      ]
    : [
        {
          path: "/add-balance",
          label: i18next.t("Add Balance"),
          icon: <AddIcon className="bottomNavBarIcons" />,
        },
        {
          path: "/balance-list/request-payments",
          label: i18next.t("Requests"),
          icon: <HourglassTopIcon className="bottomNavBarIcons" />,
        },
        {
          path: "/account-transfer",
          label: i18next.t("Account Transfer"),
          icon: <MoveUpIcon className="bottomNavBarIcons" />,
        },
        {
          path: "/create-transaction",
          label: i18next.t("Create Transaction"),
          icon: <SwapHorizIcon className="bottomNavBarIcons" />,
        },
        {
          path: "/transactions-list",
          label: i18next.t("Transactions"),
          icon: <AccountBalanceIcon className="bottomNavBarIcons" />,
        },
        // {
        //   path: "/investment",
        //   label: "Investment",
        //   icon: <ShowChartIcon className="bottomNavBarIcons" />,
        // },
      ];

  useEffect(() => {
    let newIndex: number | null = null;

    navigationItems.forEach((item, index) => {
      if (pathname.includes(item.path)) {
        setSelected(item.label);
        newIndex = index;
      }
    });
    if (newIndex === null) {
      setSelected("");
      setValue(null);
    } else {
      setValue(newIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box className="bottomNavigationMain">
      {user ? (
        isDesktop ? (
          <BottomNavigation
            className="bottomNavigationItems"
            showLabels
            value={value}
            onChange={(event, newValue) => {
              const { path, label } = navigationItems[newValue];
              handleNavigation(path, label, newValue);
            }}
          >
            {navigationItems.map((item, index) => (
              <BottomNavigationAction
                disableRipple
                key={"item" + index}
                className="bottomNavigationSingleItem"
                label={<span className="bottomNavLabels">{item.label}</span>}
                icon={item.icon}
                sx={{
                  minHeight: 40,
                  "&.Mui-selected": {
                    color: "#ffffff",
                    scale: "1.1",
                  },
                }}
              />
            ))}
          </BottomNavigation>
        ) : (
          <SpeedDial
            ariaLabel="SpeedDial"
            icon={
              <SpeedDialIcon
                sx={{
                  ".css-i4bv87-MuiSvgIcon-root": {
                    maxWidth: "24px !important",
                    maxHeight: "24px !important",
                    minWidth: "22px !important",
                    minHeight: "22px !important",
                  },
                }}
              />
            }
            openIcon={
              <CloseIcon
                sx={{
                  ".css-i4bv87-MuiSvgIcon-root": {
                    maxWidth: "24px !important",
                    maxHeight: "24px !important",
                    minWidth: "22px !important",
                    minHeight: "22px !important",
                  },
                }}
              />
            }
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            sx={{
              ".css-1cv4892-MuiSpeedDialIcon-root": {
                maxWidth: "24px !important",
                maxHeight: "24px !important",
                minWidth: "22px !important",
                minHeight: "22px !important",
              },
              position: "fixed",
              bottom: 46,
              right: 16,
            }}
            FabProps={{
              sx: {
                width: "44px !important",
                height: "44px !important",
                minWidth: "44px !important",
                minHeight: "44px !important",
                backgroundColor: "#2a5182",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#1e3a63",
                },
              },
            }}
          >
            {navigationItems.map((item, index) => (
              <SpeedDialAction
                key={"item" + index}
                icon={item.icon}
                tooltipTitle={item.label}
                onClick={() => {
                  const { path, label } = navigationItems[index];
                  handleNavigation(path, label, index);
                }}
                sx={{
                  width: "56px !important",
                  height: "56px !important",
                  minHeight: "56px !important",
                  minWidth: "56px !important",
                  "&.MuiSpeedDialAction-fab": {
                    backgroundColor: pathname.includes(item.path)
                      ? "#1e3a63"
                      : "#2a5182",
                    color: pathname.includes(item.path) ? "#fff" : "#fff",
                    "&:hover": {
                      backgroundColor: "#1e3a63",
                      color: "#fff",
                    },
                  },
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#333",
                    color: "#fff",
                  },
                }}
              />
            ))}
          </SpeedDial>
        )
      ) : (
        <></>
      )}

      <Grid sx={{ backgroundColor: "#fff" }} container alignItems={"center"}>
        {/* Left Side */}
        <Grid item md={6} xs={12} alignItems={"center"}>
          <div className="footer-link-wrap">
            <div
              className={`footer-link-item`}
              onClick={() => handleNavigationLinks("/copyright")}
            >
              {i18next.t("Copyright")}
            </div>
            <div
              className={`footer-link-item`}
              onClick={() => handleNavigationLinks("/privacy-policy")}
            >
              {i18next.t("Privacy Policy")}
            </div>
            <div
              className={`footer-link-item`}
              onClick={() => handleNavigationLinks("/declaration")}
            >
              {i18next.t("Declaration")}
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12} textAlign={"right"} alignItems={"center"}>
          <div className="footer-copy">
            {i18next.t("© All rights reserved. 2024 Victorum Capital Services Limited")}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileNavigation;
