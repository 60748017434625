import React, { useEffect, useState } from "react";
import Logo from "src/assets/images/logoPay.png";
import "./HeaderLanding.scss";
import { Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import HeaderDropDownItems from "../HeaderDropDownMenu/HeaderDropDownItems";
import HeaderMobileMenu from "../HeaderMobileMenu/HeaderMobileMenu";
import menuItems from "../HeaderMenuItems.json";
import AvatarIconButton from "../HeaderAvatarIconButton/HeaderAvatarIconButton";
import i18next from "i18next";
import LanguageSelector from "src/components/shared/LanguageSelector/LanguageSelector";

const HeaderLanding = ({ user }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [navClass, setNavClass] = React.useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const isActiveContact = location.pathname === "/contact-us";

  const translatedMenuItemsAccount = menuItems.headerItems.accountItems.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );
  const translatedMenuService = menuItems.headerItems.servicesItems.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );
  const translatedMenuItemsRegulation =
    menuItems.headerItems.regulationItems.map((item) => ({
      text: i18next.t(item.text),
      route: item.route,
    }));
  const translatedMenuItemsNews = menuItems.headerItems.newsItems.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    if (menuOpen) {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  // scroll start
  const SCROLL_THRESHOLD_ADD = 70;
  const SCROLL_THRESHOLD_REMOVE = 10;
  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window && typeof window !== "undefined") {
        const currentScrollY = window.scrollY;

        if (
          currentScrollY >= SCROLL_THRESHOLD_ADD &&
          lastScrollY < SCROLL_THRESHOLD_ADD
        ) {
          setNavClass("smaller-nav");
        } else if (
          currentScrollY <= SCROLL_THRESHOLD_REMOVE &&
          lastScrollY > SCROLL_THRESHOLD_REMOVE
        ) {
          setNavClass("");
        }

        lastScrollY = currentScrollY;
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);
  // scroll end

  return (
    <Box>
      <header id="header-landing" className={`${navClass}`}>
        <Link href="/">
          <img
            src={Logo}
            alt="VicPay Logo"
            className="header-logo headerLandingLogoMobile"
          />
        </Link>
        <nav className="header-nav">
          <HeaderDropDownItems
            triggerText={i18next.t("Accounts")}
            menuItems={translatedMenuItemsAccount}
          />
          <HeaderDropDownItems
            triggerText={i18next.t("Services")}
            menuItems={translatedMenuService}
          />
          <HeaderDropDownItems
            triggerText={i18next.t("Regulation")}
            menuItems={translatedMenuItemsRegulation}
          />
          <HeaderDropDownItems
            triggerText={i18next.t("News")}
            menuItems={translatedMenuItemsNews}
          />
          <Link
            underline="none"
            component="button"
            onClick={(e) => {
              navigate("/contact-us");
            }}
            title={"Contact us"}
          >
            <Typography
              className={
                isActiveContact ? "nav-link activePageText" : "nav-link"
              }
            >
              {i18next.t("Contact")}
            </Typography>
          </Link>
        </nav>
        <Box className="contact-us-btn">
          <Box className="languageSelectorBoxHeaderBox">
            <Box className="languageSelectorBoxHeader">
              <LanguageSelector landing={true} />
            </Box>
            {user && user.id ? (
              <Button
                type="submit"
                variant="contained"
                className="myAccBtn"
                disabled={false}
                onClick={(e) => {
                  navigate("/home");
                }}
              >
                <Typography className="signUpMyAccText">
                  {i18next.t("My Account")}
                </Typography>
              </Button>
            ) : (
              <Box className="languageSelectorBoxHeaderBox">
                <Button
                  type="submit"
                  className="loginBtn"
                  disabled={false}
                  onClick={(e) => {
                    navigate("/login");
                  }}
                >
                  <Typography className="loginText">
                    {i18next.t("Log in")}
                  </Typography>
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="signUpMyAccBtn"
                  disabled={false}
                  onClick={(e) => {
                    navigate("/registration");
                  }}
                >
                  <Typography className="signUpMyAccText">
                    {i18next.t("Sign up")}
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {/* MOBILE MENU */}
        <Box
          sx={{
            display: "none",
            alignItems: "center",
            gap: "20px",
            "@media(max-width: 1023px)": {
              display: "flex",
            },
          }}
        >
          <Box className="languageSelectorBoxHeader">
            <LanguageSelector landing={true} />
          </Box>
          <AvatarIconButton user={user} />
          <Box className="menu-btn">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
              className="landingMobileIconBtns"
              sx={{
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s !important",
                gap: menuOpen ? "0px" : "5px",
                "&:hover": {
                  border: "1px solid #fff",
                  backgroundColor: "#fff",
                },
              }}
            >
              <Box className={`menuBorder ${menuOpen ? "open" : ""}`}></Box>
              <Box className={`menuBorder2 ${menuOpen ? "open" : ""}`}></Box>
            </IconButton>
            <Menu
              id="menu-list-grow"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                className: "mobile-nav-menu-landing",
                style: {
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  height: "100%",
                  marginTop: navClass === "smaller-nav" ? "6px" : "20px",
                  width: "100%",
                  maxWidth: "100%",
                  background: "#fff",
                  color: "#000",
                  boxShadow: "none",
                  left: "0 !important",
                },
              }}
              sx={{
                "& .MuiPaper-root": {
                  left: "0 !important",
                  right: 0,
                },
                "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                  paddingTop: "0",
                  paddingBottom: "0",
                  left: "0 !important",
                },
              }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <HeaderMobileMenu
                closeMenu={handleClose}
                triggerText={"Accounts"}
                menuItems={menuItems.headerItems.accountItems}
              />
              <HeaderMobileMenu
                closeMenu={handleClose}
                triggerText={"Services"}
                menuItems={menuItems.headerItems.servicesItems}
              />
              <HeaderMobileMenu
                closeMenu={handleClose}
                triggerText={"Regulation"}
                menuItems={menuItems.headerItems.regulationItems}
              />
              <HeaderMobileMenu
                closeMenu={handleClose}
                triggerText={"News"}
                menuItems={menuItems.headerItems.newsItems}
              />
              <MenuItem
                onClick={(e) => {
                  navigate("/contact-us");
                }}
                style={{
                  justifyContent: "flex-start",
                  paddingLeft: "0px",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                <Typography
                  className={
                    isActiveContact
                      ? "nav-link-mobile activePageText"
                      : "nav-link-mobile"
                  }
                >
                  {i18next.t("Contact")}
                </Typography>
              </MenuItem>
              <Divider style={{ backgroundColor: "#e4e4e4" }} />
            </Menu>
          </Box>
        </Box>
      </header>
    </Box>
  );
};

export default HeaderLanding;
