import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";

const defaultTheme = createTheme();

const Cryptos: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };
  const handleOpenNewWindow = (route: any) => {
    window.open(route, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Cookies Policy")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Are you hungry? May we offer you some cookies"
                    )}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box className="titleTextOverlayContainerMain">
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "We want to offer you the best online experience, quick log in processes and comfortable app use. This comfort often requires cookies, but what are exactly cookies"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow it) which enables websites to recognize your browser, capture and remember certain information"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Please keep in mind that cookies do not include any data that personally identifies you; they typically contain anonymous information which is required for the better functioning of the website you visit"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "General information on cookies can be found on the website"
                  )}{" "}
                  <span
                    onClick={() => {
                      handleOpenNewWindow("https://allaboutcookies.org/");
                    }}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    className="linksHover"
                  >
                    www.allaboutcookies.org
                  </span>
                  {i18next.t(", run by the All About Privacy, LLC")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Depending on the term the cookies exist, they can be classified either as session or persistent ones"
                  )}
                </Typography>
              </Box>

              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Session cookies")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "These are temporary cookies which exist until you close the relevant browser. They help the website to remember what you chose on the previous page so that you do not need to re-enter information, thus improving your experience"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Persistent cookies")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "These cookies stay on your device for an indefinite period of time after you close the browser. They help to recognize returning users, analyse their behaviour to improve user experiences"
                  )}
                </Typography>
              </Box>

              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "The list below explains the cookies we use and why"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("A. Strictly necessary cookies")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Necessary cookies help to make the website usable by enabling basic functions like page navigation and access to secure areas of the website. You may disable these by changing your browser settings, but this may affect how the website functions"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("B. Preferences")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Preference cookies enable the website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in, remembers filled in forms"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("C. Analytics")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "We'd also like to set analytics cookies that help us to make improvements by measuring how you use the site. These will be set only if you accept. The analytics cookies help us to improve the website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("D. Marketing cookies")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Marketing cookies are used to track visitors across the websites. The intention is to display ads that are relevant and engaging for the individual user, and thereby more valuable for publishers and third-party advertisers"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("How is it possible for me to decline cookies")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "You can choose to decline the use of cookies directly on the website by hitting the decline button displayed to visitors, when they visit the website for the first time"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "You can also deactivate/delete cookies via your browser settings. If you do so, please be aware that some of your individual settings won´t be active anymore"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "You can find further information about how to manage cookies in their browser by visiting Google Chrome, Mozilla Firefox, Apple Safari or Microsoft Bing websites to learn more"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "You are still not sure, if you got this thing with the cookies? Would you like to speak to someone from our IT department"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "No problem, we are glad to help! Please just"
                  )}{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/contact-us")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t("for more contact options")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Cryptos;
