import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import PageInfoWidget from "../components/PageInfoWidget/PageInfoWidget";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";

const defaultTheme = createTheme();

const InstantTransfers: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Instant transfers")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Lightning fast transfers for lowest fees!"
                    )}
                    subtitle={i18next.t(
                      "We know what our clients expect: a regulated and secure environment, low currency exchange rates, transparent fees and lightning fast transfer execution!"
                    )}
                    buttonText={i18next.t("Create an account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <PageInfoWidget />
              <Box sx={{ padding: "2rem 0 2rem 0" }}>
                <TitleTextOverlayImg
                  title={i18next.t("Simplifying Payments Worldwide")}
                  text={i18next.t(
                    "VicPay makes it easy to send and receive payments, no matter if fiat currencies, cryptos or wire SWIFT transfers through dedicated IBAN accounts"
                  )}
                  direction="left"
                />
              </Box>
              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "With our multi-currency accounts, you and your company have access to accounts in 12 major currencies and gain the ability to benefit from 49 currency pairs with instant FX settlement nas well as crypto transactions in BTC, ETH, LTC, USDT, USDC, BCH, XRP, TON, TRX, BNB, TRON."
                  )}
                />
              </Box>
              <Box
                className="titleTextOverlayContainerMain"
                sx={{ padding: "2rem 0 2rem 0" }}
              >
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "Instant Transfers Worldwide with VicPay's Payment Bridge"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Transfers are handled immediately through our payment bridge technology, which is connected to partner banks all over the world in order to ensure almost instant transfers."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  component="ul"
                  className="titleIconSubTitleDescDesc"
                  sx={{ marginLeft: "22px" }}
                >
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "Highest level of regulation through the Canadian Financial Services Authority"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "Maximum protection for funds through governmental protection scheme"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "Get access to cryptocurrencies, fiat currencies and IBAN accounts in just one system"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "Low fees and lightning fast execution through the Victorum payment bridge technology"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "All services available for individuals, sole traders and corporations"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "VISA card for easy access to cash money for low fees"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "Send money instantly between your VicPay accounts and cards"
                    )}
                  </li>
                </Typography>
                <Box>
                  <Box className="titleTextOverlayContainerMain">
                    <Typography
                      variant="h3"
                      className="titleIconSubTitleDescTitle"
                    >
                      {i18next.t(
                        "Secure Accounts, Seamless Payments: Unlock the VicPay Advantage"
                      )}
                    </Typography>

                    <Typography
                      variant="body1"
                      paragraph
                      className="titleIconSubTitleDescDesc"
                    >
                      {i18next.t(
                        "Our company offers together with our partners a fully regulated and safe account environment, an almost 100 % server uptime and dedicated and experienced account managers, available 24/7 for our retail business clients."
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      className="titleIconSubTitleDescDesc"
                    >
                      {i18next.t(
                        "A seamless solution for every single payment in every single currency at any time, so don´t hesitate and"
                      )}{" "}
                      <span
                        className="linksHover"
                        onClick={() => navigate("/registration")}
                      >
                        {i18next.t("click here")}
                      </span>{" "}
                      {i18next.t("to open your VicPay account now!")}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      className="titleIconSubTitleDescDesc"
                    >
                      {i18next.t(
                        "For further questions, we recommend to contact our dedicated support desk - they are able to answer almost all your questions."
                      )}{" "}
                      <span
                        className="linksHover"
                        onClick={() => navigate("/contact-us")}
                      >
                        {i18next.t("click here")}
                      </span>{" "}
                      {i18next.t(
                        "to get to our contact site or dial +1 604-260-0738 to get in touch with our support team!"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default InstantTransfers;
