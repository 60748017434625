import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import "./News.scss";
import { ReactComponent as SearchIcon } from "../../../../assets/images/searchNewsIcon.svg";
import { useState } from "react";
import NewsBox from "./NewsBox";
import NewsDefault from "../../../../assets/images/logoPay.png";
import NewsJson from "./News.json";
import i18next from "i18next";

interface Article {
  id: number;
  image?: string;
  date: string;
  category: string;
  title: string;
  subtitle?: string;
  text?: string;
}

const News = () => {
  const [tab, setTab] = useState("all");
  const [searchNews, setSearchNews] = useState("");

  const [news] = useState<Article[]>(NewsJson.news);

  const handleNewsClick = (id: number) => {
    window.location.href = `/news/${id}`;
  };

  let filteredNews = news;

  if (tab !== "all") {
    filteredNews = filteredNews.filter((article) => article.category === tab);
  }

  if (searchNews.length >= 3) {
    filteredNews = filteredNews.filter((article) =>
      article.title.toLowerCase().includes(searchNews.toLowerCase())
    );
  }

  return (
    <Box>
      <Box>
        <Box className="latesNewsAndSearch">
          <Box>
            <Typography variant="h3" className="blueHeadlineMiniLandingPages">
              {i18next.t("Latest news")}
            </Typography>
          </Box>
          <Box className="newsSearchtextFieldBox">
            <TextField
              fullWidth
              placeholder={i18next.t("Search news")}
              sx={{
                background: "#F5F6F7",
                borderColor: "#F5F6F7",
                height: "70px",
                minWidth: "450px",
                borderRadius: "11px",
                "@media (max-width:768px)": {
                  height: "40px",
                  minWidth: "auto",
                },
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
                "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                  height: "70px",
                  border: "none",
                  "@media (max-width:768px)": {
                    height: "40px",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  color: "#2a5182",
                  "@media (max-width:600px)": {
                    fontSize: "14px",
                  },
                },
                "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                  color: "#3e78c1",
                },
                "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                  color: "#ff3366",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchNews}
              onChange={(e) => setSearchNews(e.target.value)}
            />
          </Box>
        </Box>
        <Box className="newsTabsBox">
          <Box
            onClick={() => {
              setTab("all");
            }}
          >
            <Typography className={tab === "all" ? "newstabActive" : "newstab"}>
              {i18next.t("All")}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setTab("Newsroom");
            }}
          >
            <Typography
              className={tab === "Newsroom" ? "newstabActive" : "newstab"}
            >
              {i18next.t("Newsroom")}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setTab("House workshops");
            }}
          >
            <Typography
              className={
                tab === "House workshops" ? "newstabActive" : "newstab"
              }
            >
              {i18next.t("House workshops")}
            </Typography>
          </Box>
        </Box>
      </Box>
      {filteredNews.length === 0 && (
        <Box sx={{ width: "100%" }}>
          <Typography variant="body1" paragraph className="noArticlesFound">
            {i18next.t("No articles found matching your search.")}
          </Typography>
        </Box>
      )}

      <Box className="newsBoxGrid">
        {filteredNews.length > 0 &&
          filteredNews.map((article) => (
            <NewsBox
              id={article.id}
              key={article.id}
              article={article}
              image={article.image ?? NewsDefault}
              date={article.date}
              category={article.category}
              title={article.title}
              subtitle={article.subtitle ?? ""}
              text={article.text ?? ""}
              onSelectCategory={(category) => setTab(category)}
              onNewsClick={handleNewsClick}
            />
          ))}
      </Box>
    </Box>
  );
};

export default News;
