import IconTextWidget from "../../components/IconTextWidget/IconTextWidget";
import currencyIcon from "../../../../assets/images/currencies-new.svg";
import merchantIcon from "../../../../assets/images/merchant-new.svg";
import languages from "../../../../assets/images/languages-new.svg";
import users from "../../../../assets/images/users-new.svg";
import icon24 from "../../../../assets/images/24-new.svg";
import i18next from "i18next";
import { Box } from "@mui/material";

const PageInfoWidget = () => {
  return (
    <Box>
      <Box className="icon-text-widgets-container">
        <IconTextWidget
          iconSrc={currencyIcon}
          title={i18next.t("Accounts in USD, EUR and other currencies")}
          text={i18next.t("Accounts in USD, EUR and other currencies")}
        />
        <IconTextWidget
          iconSrc={merchantIcon}
          title={i18next.t("Local and international deposits and withdrawals")}
          text={i18next.t("Local and international deposits and withdrawals")}
        />
        <IconTextWidget
          iconSrc={languages}
          title={i18next.t("Multilingual Support")}
          text=""
        />
      </Box>
      <Box className="icon-text-widgets-container2">
        <IconTextWidget
          iconSrc={users}
          title={i18next.t("Robust multi-tier account security")}
          text={i18next.t("Robust multi-tier account security with 2FA")}
        />
        <IconTextWidget
          iconSrc={icon24}
          title={i18next.t("First response in 1 hour or faster")}
          text={i18next.t("First response in 1 hour or faster")}
        />
      </Box>
    </Box>
  );
};

export default PageInfoWidget;
