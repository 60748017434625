import { Box, Typography } from "@mui/material";
import "./TitleIconSubTitleDesc.scss";
import { useNavigate } from "react-router-dom";

interface Item {
  icon?: React.ReactNode;
  subTitle?: string;
  desc?: any;
  desc2?: any;
  email?: any;
  link?: any;
  linkText?: any;
}

interface TitleDescProps {
  title?: any;
  items: Item[];
}

const TitleIconSubTitleDesc: React.FC<TitleDescProps> = ({ title, items }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ marginTop: "8rem", marginBottom: "4rem" }}>
      {title && (
        <Typography
          variant="h3"
          sx={{ paddingBottom: "0rem" }}
          className="titleIconSubTitleDescHeadline"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      {items.map((item, index) => {
        let email = "";
        if (item?.email) {
          email = `<a href="mailto:${item.email}" class="linksHover" style="margin-left: 5px">${item.email}</a>`;
        }

        const combinedContent = `
          ${item.desc || ""}
          ${
            item.link
              ? `<a href="${item.link}" style="font-weight: 700" class="linksHover">${item.linkText}</a>`
              : ""
          }
          ${item.desc2 || ""}
        `;

        return (
          <Box key={index} sx={{ marginTop: "3rem" }}>
            {item?.icon && <Box sx={{ marginBottom: "1rem" }}>{item.icon}</Box>}
            {item?.subTitle && (
              <Typography
                variant="h3"
                sx={{ marginBottom: "1rem" }}
                className="titleIconSubTitleDescTitle"
                dangerouslySetInnerHTML={{
                  __html: item.subTitle,
                }}
              />
            )}
            {combinedContent && (
              <Typography
                variant="body1"
                paragraph
                className="titleIconSubTitleDescDesc"
                dangerouslySetInnerHTML={{ __html: combinedContent + email }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default TitleIconSubTitleDesc;
