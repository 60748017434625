import { Box, Typography } from "@mui/material";
import { useState } from "react";
import "./HeaderDropDownItems.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";

const HeaderDropDownItems = ({ triggerText, menuItems }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleMenuMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMenuMouseLeave = () => {
    setIsOpen(false);
  };

  const handleItemClick = (route: string) => {
    navigate(route);
  };

  const triggerTextActiveClass =
    (location.pathname === "/individual" ||
      location.pathname === "/business" ||
      location.pathname === "/multi-currency" ||
      location.pathname === "/open-account" ||
      location.pathname === "/download-app" ||
      location.pathname === "/contact-account-team" ||
      location.pathname === "/fees") &&
    triggerText === i18next.t("Accounts")
      ? "nav-link textActiveItemColor"
      : (location.pathname === "/payments" ||
          location.pathname === "/cryptos" ||
          location.pathname === "/mezzanine-capital" ||
          location.pathname === "/iban-accounts" ||
          location.pathname === "/instant-transfers" ||
          location.pathname === "/escrow-service" ||
          location.pathname === "/download-app" ||
          location.pathname === "/visa-card") &&
        triggerText === i18next.t("Services")
      ? "nav-link textActiveItemColor"
      : (location.pathname === "/client-security" ||
          location.pathname === "/license" ||
          location.pathname === "/regulation" ||
          location.pathname === "/safety-of-funds" ||
          location.pathname === "/compliance-desk" ||
          location.pathname === "/aml-policy") &&
        triggerText === i18next.t("Regulation")
      ? "nav-link textActiveItemColor"
      : (location.pathname === "/online-news-room" ||
          location.pathname === "/in-house-workshops") &&
        triggerText === i18next.t("News")
      ? "nav-link textActiveItemColor"
      : "nav-link";
  return (
    <Box
      className="headerTextMainBox"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box className="headerTextItemAndIconBox">
        <Typography className={`nav-link ${triggerTextActiveClass}`}>
          {triggerText}
        </Typography>
        <KeyboardArrowDownIcon fontSize="small" />
      </Box>
      {(isOpen || isOpen) && (
        <Box
          className="headerDropDownMenu"
          // className={`headerDropDownMenu ${
          //   isOpen || isOpen ? " headerDropDownMenu show-items" : ""
          // }`}
          onMouseEnter={handleMenuMouseEnter}
          onMouseLeave={handleMenuMouseLeave}
        >
          {menuItems.map((item: any, index: any) =>
            item.route ? (
              <Typography
                key={index}
                className={`headerDropDownMenuItem ${
                  location.pathname === item.route
                    ? "headerDropDownMenuItemActive"
                    : ""
                }`}
                onClick={() => handleItemClick(item.route)}
              >
                {item.text}
              </Typography>
            ) : (
              <Typography key={index} className="headerDropDownMenuItem">
                {item.text}
              </Typography>
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default HeaderDropDownItems;
