import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import { useNavigate } from "react-router-dom";
// import paymentsImage from "../../../assets/images/instantPaymentsImgBlacked.png";
// import paymentsNoise from "../../../assets/images/payments-noise.svg";
import paymentsImage from "../../../assets/images/paymentsImage.png";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import { ReactComponent as ShieldBlue } from "../../../assets/images/shield-blue.svg";
import { ReactComponent as GlobalBlue } from "../../../assets/images/global-blue.svg";
import { ReactComponent as RewindBlue } from "../../../assets/images/rewind-forward.svg";
import { ReactComponent as RubleBlue } from "../../../assets/images/ruble-blue.svg";

const items = [
  {
    icon: <ShieldBlue />,
    subTitle: i18next.t("Your safety as first priority"),
    desc: i18next.t(
      "We take data security extremely seriously. Your vicpayments.com account has more protection tools than most similar platforms."
    ),
  },
  {
    icon: <GlobalBlue />,
    subTitle: i18next.t("Receive Payments from anywhere"),
    desc: i18next.t(
      "Global payment solution, with the ability to receive payments across every major currency into a single IBAN. Real-time payment rails and FX capabilities ensure swift, secure transactions wherever you are receiving them from."
    ),
  },
  {
    icon: <RewindBlue />,
    subTitle: i18next.t("Unlock real-time payment rails"),
    desc: i18next.t(
      "Make payments faster than ever, Never wait for a payment to reach your account again, with our access to real-time payment rails across Europe."
    ),
  },
  {
    icon: <RubleBlue />,
    subTitle: i18next.t("Harness every major currency"),
    desc:
      i18next.t("All in one place") +
      " " +
      i18next.t(
        "With global payment rails, regulatory coverage and multi-currency accounts, you can now receive more payments from more places"
      ),
  },
];

const defaultTheme = createTheme();
const InstantPayments: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToPages = (event: any, route: any) => {
    navigate(route);
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Payments")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Are you ready for the next financial revolution?"
                    )}
                    subtitle={i18next.t(
                      "Seamlessly Connect, Transact, and Secure Your Funds Globally with vicpayments.com"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create an account")}
                    onClickFunction={(event: any) =>
                      navigateToPages(event, "/registration")
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box sx={{ marginTop: 5, marginBottom: 5 }}>
                <ImageWithText2
                  backgroundImage={paymentsImage}
                  // noiseImage={paymentsNoise}
                  title={i18next.t("Send payments worldwide")}
                  text={i18next.t(
                    "Get paid by employers or affiliate programs instantly and spend anywhere in the world with our debit card - coming soon (availability depending on region). Instantly distribute funds in your freelancer team."
                  )}
                />
              </Box>
              <Box sx={{ padding: "4rem 0 4rem 0" }}>
                <TitleTextOverlayImg
                  title={i18next.t("Instant internal transfers")}
                  text={i18next.t(
                    "USD, EUR and other currencies. Load and withdraw via a credit or debit card, bank transfers, local transfers, digital currencies and more."
                  )}
                  direction="left"
                />
              </Box>
              <Box>
                <Box className="getYourCardContainer">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Typography className="getYourCardText">
                      {i18next.t("Cards to spend worldwide!")}
                    </Typography>
                    <Typography className="getYourCardDescText">
                      {i18next.t(
                        "Get an instantly loaded plastic or virtual card for hassle-free worldwide ATM and POS payments"
                      )}
                      <br />- {i18next.t("coming soon")}
                    </Typography>
                    <Box className="getYourCardImage grow-vpitem"></Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default InstantPayments;
