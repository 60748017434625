import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import paymentsImage from "../../../assets/images/paymentsImage.png";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import QualityIcon from "../../../assets/images/qualityAboutUsIcon.svg";
import BalancedIcon from "../../../assets/images/balancedAboutIcon.svg";
import SeamlessIcon from "../../../assets/images/seamlessAboutUsIcon.svg";
import FutureIcon from "../../../assets/images/futureAboutUsIcon.svg";
import PremiumIcon from "../../../assets/images/premiumSegmentAboutIcon.svg";
import FeesIcon from "../../../assets/images/feesAboutIcon.svg";
import MarketsIcon from "../../../assets/images/marketsAboutIcon.svg";
import VicPayAboutIcon from "../../../assets/images/vicpayAboutIcon.svg";

const defaultTheme = createTheme();

const AboutUs: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigateToPartner = (event: any) => {
    window.open(
      "https://www.victorum-capital.com/kunden/#beteiligung",
      "_blank"
    );
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("About us")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t("Always one step ahead")}
                    subtitle={i18next.t(
                      "VicPay is one of the most modern and innovative companies between FinTechs, NeoBanks and other modern participants in the international financial industry"
                    )}
                    buttonText={i18next.t("Our partner")}
                    onClickFunction={navigateToPartner}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "With state-of-the-art IT systems, AI and innovative security technologies, we make global transactions with fiat and cryptocurrencies, currency conversion and the hedging of foreign currency risks simpler, cheaper - and above all: significantly faster than customers were previously used to"
                  )}
                />
              </Box>
              <Box sx={{ marginTop: 5, marginBottom: 5 }}>
                <ImageWithText2
                  backgroundImage={paymentsImage}
                  title={i18next.t("Quality Over Quantity")}
                  text={i18next.t(
                    "Our aim is not always to be the provider that is the cheapest or the fastest - or the one with the most functions on its platform"
                  )}
                />
              </Box>
              <Box>
                <TitleTextOverlayImg
                  icon={QualityIcon}
                  title={i18next.t("Quality Over Discounts")}
                  text={i18next.t(
                    "We are not a discount provider and do not want to become one"
                  )}
                  hasOverlayImg={false}
                />
                <TitleTextOverlayImg
                  icon={BalancedIcon}
                  title={i18next.t("Balanced Price and Performance")}
                  text={i18next.t(
                    "It was clear to us from the outset that we wanted to offer a balanced relationship between price and performance, regardless of whether you work with us as a private or business customer"
                  )}
                  hasOverlayImg={false}
                  direction="right"
                />
                <TitleTextOverlayImg
                  icon={SeamlessIcon}
                  title={i18next.t("Seamless Integration with VicPay Services")}
                  text={i18next.t(
                    "In addition, our customers benefit from the smooth integration of VicPay with other services within the Victorum Group of Companies"
                  )}
                  hasOverlayImg={false}
                />
                <TitleTextOverlayImg
                  icon={FutureIcon}
                  title={i18next.t(
                    "Future Growth in Central and South America and Africa"
                  )}
                  text={i18next.t(
                    "We are convinced that Central and South America and Africa will see the highest growth in the coming years - which is why VicPay is planning to successively open a total of six more locations in North, Central and South America by 2028"
                  )}
                  hasOverlayImg={false}
                  direction="right"
                />
                <TitleTextOverlayImg
                  icon={PremiumIcon}
                  title={i18next.t("Not Just for the Premium Segment")}
                  text={i18next.t(
                    "It was and is not our business model to only serve the premium segment"
                  )}
                  hasOverlayImg={false}
                />
                <TitleTextOverlayImg
                  icon={FeesIcon}
                  title={i18next.t("Simple and Understandable Fees")}
                  text={i18next.t(
                    "One of our unique selling points is our simple and understandable fee structure, which makes it easy for both our private and business customers to choose us"
                  )}
                  hasOverlayImg={false}
                  direction="right"
                />
                <TitleTextOverlayImg
                  icon={MarketsIcon}
                  title={i18next.t("Expanding into Underserved Markets")}
                  text={i18next.t(
                    "The demographic changes worldwide are our incentive to grow in markets that many of our competitors are not paying full attention to today"
                  )}
                  hasOverlayImg={false}
                />
                <TitleTextOverlayImg
                  icon={VicPayAboutIcon}
                  title={i18next.t(
                    "VicPay's Comprehensive Financial Ecosystem and Upcoming Innovations"
                  )}
                  text={i18next.t(
                    "We have created an entire financial ecosystem for our international customers in which the possibilities could hardly be more extensive - and you can be sure of that The next innovation at VicPay will surprise you soon Curious to find out which innovation it is and how you can benefit from it? If you can't wait, we understand - just contact and we'll help"
                  )}
                  hasOverlayImg={false}
                  direction="right"
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default AboutUs;
