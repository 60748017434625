import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";

const defaultTheme = createTheme();

const Declaration: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Declaration")}
                  </Typography>
                </Box>
                <Box>
                  {/* <HeadlineVvidgetSmall2
                  headline={}
                  subtitle={}
                  buttonText={}
                  onClickFunction={}
                  /> */}
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              {/* <Box>
                <Typography
                  className="your-details-text d-flex jc-start pageTextFontSize"
                  component="h1"
                  variant="h4"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  The information in the fine print on this page is significant.
                </Typography>
                <Typography
                  className="pageTextFontSize"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    color: "#454745",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  b. Declaration (to be developed together with the German and
                  Canadian commissioners of the General Data Protection
                  Regulation after preparing a detailed description of the
                  organizational and technical measures){" "}
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Declaration;
