import React, { useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import ContactUsForm from "../components/LandingSections/ContactUs";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import { ReactComponent as ClockSquare } from "../../../assets/images/clock-square.svg";

const defaultTheme = createTheme();

const ContactUs: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToContainer = () => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const sendEmail = (email: any) => {
    const mailtoUrl = `mailto:${email}`;

    window.location.href = mailtoUrl;
  };
  const items = [
    {
      icon: <ClockSquare />,
      subTitle: i18next.t("Open 5 Days a Week"),
      desc: i18next.t(
        "We are open Mon - Fri from 10 AM to 5 PM - just drop in to meet the team"
      ),
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Contact us")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Get the information you need when you need it"
                    )}
                    subtitle={i18next.t(
                      "You have questions? Do you need technical support or do you have difficulties opening an account"
                    )}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={scrollToContainer}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Typography
                variant="h3"
                sx={{ marginBottom: "3rem !important" }}
                className="titleIconSubTitleDescTitle"
              >
                {i18next.t("Here are our full contact details at a glance")}
              </Typography>
              <Box className="contactUsHeadingBoxRowReverse">
                <Box>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage"
                  >
                    <span className="boldBlueFont">
                      {" "}
                      {i18next.t("Address")}:
                    </span>{" "}
                    <br />
                    <span className="textsLandingsmallNoFontSizeNew addressBoxAlignments">
                      Victorum Capital Services Limited
                      <br />
                      2/3 48 West George Street,
                      <br />
                      Glasgow, Scotland, G2 1BP
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage columnDirectionContactMobile"
                  >
                    <span className="boldBlueFont">{i18next.t("Email")}:</span>{" "}
                    <a
                      href="mailto:info@vicpayments.com"
                      className="basicFontLinkHover linksMarginMobile"
                      style={{
                        marginLeft: "5px",
                        fontWeight: "300",
                        fontFamily: "Montserrat",
                      }}
                    >
                      info@vicpayments.com
                    </a>
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage columnDirectionContactMobile"
                  >
                    <span className="boldBlueFont">
                      {i18next.t("Media & Press")}:
                    </span>{" "}
                    <a
                      href="mailto:
                  press@vicpayments.com"
                      className="basicFontLinkHover linksMarginMobile"
                      style={{
                        marginLeft: "5px",
                        fontWeight: "300",
                        fontFamily: "Montserrat",
                      }}
                    >
                      press@vicpayments.com
                    </a>
                  </Typography>
                  <Box>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont">
                        {i18next.t("Administration")}:
                      </span>{" "}
                      <a
                        href="mailto:
                  administration@vicpayments.com"
                        className="basicFontLinkHover linksMarginMobile"
                        style={{
                          marginLeft: "5px",
                          fontWeight: "300",
                          fontFamily: "Montserrat",
                        }}
                      >
                        administration@vicpayments.com
                      </a>
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage columnDirectionContactMobile"
                  >
                    <span className="boldBlueFont">{i18next.t("Company")}:</span>{" "}
                    <span className="basicFont">
                      {i18next.t("Registered in Scotland, United Kingdom")}
                    </span>
                  </Typography>

                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage columnDirectionContactMobile"
                  >
                    <span className="boldBlueFont">
                      {i18next.t("Company number")}
                    </span>{" "}
                    <span className="basicFont">SC745907</span>
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsLandingPage columnDirectionContactMobile"
                  >
                    <span className="boldBlueFont"> {i18next.t("CEO")} </span>
                    <span className="basicFont">Osbert Döhl</span>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "4rem" }}>
                <LogoTextVvidget2
                  text={i18next.t(
                    "You want to speak to one of our transfer and currency experts? Would you like to handle transactions or do you need more technology insights before deciding to open an account? We've got you covered Mon - Fri from 10 AM to 5 PM to write us an email to"
                  )}
                  email={"info@vicpayments.com"}
                />
              </Box>
              <Box ref={containerRef} sx={{ marginTop: "2rem" }}>
                <ContactUsForm isContactUsPage={true} />
              </Box>
              <Box>
                <TitleIconSubTitleDesc
                  title={i18next.t("Would you like to meet us")}
                  items={items}
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default ContactUs;
