import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import paymentsImage from "../../../assets/images/newsBB.jpg";
import News from "../components/News/News";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";

const defaultTheme = createTheme();

const Individual: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const navigateToMediaPress = (event: any) => {
    navigate("/media-press");
  };

  const items = [
    {
      subTitle: i18next.t("Get in touch with us"),
      desc: i18next.t(
        "For journalists and press services, we recommend to contact our dedicated media & press desk - simply click"
      ),
      link: "/media-press",
      linkText: i18next.t("here"),
      desc2: i18next.t(
        "for press photos, interview material and much more to get in touch with our media & press department!"
      ),
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Online Newsroom")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "What could be more valuable than getting news first?"
                    )}
                    subtitle={i18next.t(
                      "The world of finance, currencies and cryptos is changing just as rapidly as the technologies and payment methods."
                    )}
                    buttonText={i18next.t("Contact media & press desk")}
                    onClickFunction={navigateToMediaPress}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box>
                <ImageWithText2
                  backgroundImage={paymentsImage}
                  title={i18next.t("Get valuable news first")}
                  text={i18next.t(
                    "We will keep you informed about the latest news from the world of finance, currencies and cryptos."
                  )}
                />
              </Box>
              <Box sx={{ marginTop: "8rem", marginBottom: "4rem" }}>
                <News />
              </Box>

              <Box sx={{ marginTop: "8rem", marginBottom: "4rem" }}>
                <LogoTextVvidget2
                  text={i18next.t(
                    "To help you benefit from these changes, we keep you up to date in this section of our website and provide you with interesting information on economic and monetary policy, news from the crypto world and insights from our company and the Victorum Group."
                  )}
                />
              </Box>

              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Individual;
