import { useEffect, useState } from "react";
import "./Banking.scss";
import api from "src/store/interceptors/api";
import {
  Box,
  Container,
  createTheme,
  SelectChangeEvent,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Nav from "src/components/layout/nav/Nav";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import BankingNav from "./components/BankingNav";
import AccountConfiguration from "./components/AccountConfiguration";
import DocumentsStep from "./components/DocumentsStep";
import ReviewStep from "./components/ReviewStep";
import { useFormik } from "formik";
import i18next from "i18next";
import * as Yup from "yup";
import VerificationStep from "./components/VerificationStep";
import BankDetailsStep from "./components/BankDetailsStep";
import BalanceDisplay from "./components/BalanceDisplay";
import BankingApiUpdate from "src/hooks/BankingApiUpdate";
import transactionsService from "src/store/transactions/transactions_service";

const defaultTheme = createTheme();
function Banking() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [termsProvider, setTermsProvider] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const { handleApiUpdate, isSubmitting } = BankingApiUpdate(user?.id);

  const [initialFormikValues, setInitialFormikValues] = useState({
    iban_needs: "",
    pay_in_currency: [],
    volume_of_money_in: "",
    pay_out_currency: [],
    volume_of_money_out: "",
    verification_country_code: "",
    bussiness_activities: "",
    company_in_bussiness: "",
  });

  const handleSubmitForm = async (data: any) => {
    if (!user) {
      alert(i18next.t("Please try again or restart your session!"));
      return;
    }

    const values = formik.values;
    const excludedData: any = { ...values };

    if (excludedData.pay_in_currency) {
      excludedData.pay_in_currency = excludedData.pay_in_currency.join(", ");
    }

    if (excludedData.pay_out_currency) {
      excludedData.pay_out_currency = excludedData.pay_out_currency.join(", ");
    }

    const finalData: any = Object.fromEntries(
      Object.entries(excludedData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    if (user && user.id) {
      finalData.id = user.id;

      const formData = new FormData();
      for (const finalDataKey in finalData) {
        if (finalData.hasOwnProperty(finalDataKey)) {
          formData.append(finalDataKey, finalData[finalDataKey]);
        }
      }

      if (data) {
        if (data.members) {
          formData.append("onboarding", JSON.stringify(data.members));
          data.members.forEach((member: any) => {
            formData.append(
              member.id + "_proofOfId_file",
              member.proofOfId.file
            );
            formData.append(
              member.id + "_proofOfAddress_file",
              member.proofOfAddress.file
            );
          });
        }

        if (data.selectedFiles) {
          if (data.selectedFiles.companyRegistryExtract?.file) {
            formData.append(
              "companyRegistryExtract_file",
              data.selectedFiles.companyRegistryExtract.file
            );
          }

          if (data.selectedFiles.certificateOfIncorporation?.file) {
            formData.append(
              "certificateOfIncorporation_file",
              data.selectedFiles.certificateOfIncorporation.file
            );
          }

          
          if (data.selectedFiles.shareholderRegister?.file) {
            formData.append(
              "shareholderRegister_file",
              data.selectedFiles.shareholderRegister.file
            );
          }
          if (data.selectedFiles.annualReport?.file) {
            formData.append(
              "annualReport_file",
              data.selectedFiles.annualReport.file
            );
          }
          if (data.selectedFiles.companyBankStatement?.file) {
            formData.append(
              "companyBankStatement_file",
              data.selectedFiles.companyBankStatement.file
            );
          }
          if (data.selectedFiles.formOfDeals?.file) {
            formData.append(
              "formOfDeals_file",
              data.selectedFiles.formOfDeals.file
            );
          }
        }
      }

      formData.append("verification_type", "onboarding_save");
      formData.append("_method", "PUT");

      try {
        const response = await api.post(
          `/user-onboarding-data/${user.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (!response) {
          alert(i18next.t("Please check the form!"));
          return;
        } else {
          setActiveStep(2);
        }
      } catch (error) {
        console.error(`Error uploading: `, error);
      }
    } else {
      alert(i18next.t("Please try again!"));
      return;
    }
  };
  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    formik.handleChange(e);
    handleApiUpdate(formik.values);
  };

  const handleSelectChange = (e: SelectChangeEvent<any>) => {
    formik.handleChange(e);
    handleApiUpdate(formik.values);
  };

  const handleRadioChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    formik.handleChange(e);
    handleApiUpdate(formik.values);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      await api
        .get("/me")
        .then((response: any) => {
          if (response) {
            if (response && response.data && response.data.data) {
              const userData = response.data.data;
              setUser(userData);

              let pay_in_currency: any = [];
              let pay_out_currency: any = [];
              if (typeof userData.pay_in_currency === "string") {
                pay_in_currency = userData.pay_in_currency
                  .split(",")
                  .map((item: any) => item.trim());
              }
              if (typeof userData.pay_out_currency === "string") {
                pay_out_currency = userData.pay_out_currency
                  .split(",")
                  .map((item: any) => item.trim());
              }
              // Update initial form values based on the fetched user data
              setInitialFormikValues((prevValues: any) => ({
                ...prevValues,
                iban_needs: userData.iban_needs || "",
                pay_in_currency,
                volume_of_money_in: userData.volume_of_money_in
                  ? transactionsService.formatNumber(
                      userData.volume_of_money_in
                    )
                  : "",
                pay_out_currency,
                volume_of_money_out: userData.volume_of_money_out
                  ? transactionsService.formatNumber(
                      userData.volume_of_money_out
                    )
                  : "",
                verification_country_code:
                  userData.verification_country_code || "",
                bussiness_activities: userData.bussiness_activities || "",
                company_in_bussiness: userData.company_in_bussiness || "",
              }));

              if (userData.verification_provider_terms) {
                setTermsProvider(true);
              }

              if (userData.verification_requested) {
                setActiveStep(2);
              }

              if (userData.verification_step) {
                setActiveStep(parseInt(userData.verification_step) - 1);
              }
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
  }, []);

  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: any) => {
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
      } else if (activeStep === 1) {
        return;
      }
    },
    validationSchema: Yup.object(
      activeStep === 0
        ? {
            iban_needs: Yup.string().required(
              i18next.t("This field is required")
            ),
            pay_in_currency: Yup.array()
              .required(i18next.t("This field is required"))
              .min(1, i18next.t("This field is required")),

            volume_of_money_in: Yup.string().required(
              i18next.t("This field is required")
            ),

            pay_out_currency: Yup.array()
              .required(i18next.t("This field is required"))
              .min(1, i18next.t("This field is required")),

            volume_of_money_out: Yup.string().required(
              i18next.t("This field is required")
            ),
          }
        : activeStep === 1
        ? {
            bussiness_activities: Yup.string().required(
              i18next.t("This field is required")
            ),

            company_in_bussiness: Yup.string().required(
              i18next.t("This field is required")
            ),

            verification_country_code: Yup.string().required(
              i18next.t("This field is required")
            ),
          }
        : {}
    ),
  });

  useEffect(() => {
    if (user && user.id) {
      handleApiUpdate(formik.values);
    }
  }, [formik.values, handleApiUpdate, user]);

  return (
    <Box>
      {user && user.account_type === "business" ? (
        activeStep < 5 ? (
          <BankingNav activeStep={activeStep} />
        ) : (
          <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
        )
      ) : (
        <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      )}
      <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          {loading ? (
            <Box className="settingsMain">
              <LoadingIndicator />
            </Box>
          ) : user && user.account_type === "business" && activeStep < 5 ? (
            <Box className="animated-text moveInRight">
              {activeStep === 0 && (
                <AccountConfiguration
                  userId={user?.id}
                  formik={formik}
                  setActiveStep={setActiveStep}
                  handleTextFieldChange={handleTextFieldChange}
                  handleSelectChange={handleSelectChange}
                  handleRadioChange={handleRadioChange}
                  isSubmitting={isSubmitting}
                />
              )}
              {activeStep === 1 && (
                <DocumentsStep
                  formik={formik}
                  handleTextFieldChange={handleTextFieldChange}
                  handleSelectChange={handleSelectChange}
                  handleSubmitForm={handleSubmitForm}
                  isSubmitting={isSubmitting}
                />
              )}
              {activeStep === 2 && <ReviewStep />}
              {activeStep === 3 && <VerificationStep />}
              {activeStep === 4 && (
                <BankDetailsStep termsProvider={termsProvider} />
              )}
            </Box>
          ) : (
            <Box className="animated-text moveInRight">
              {user ? (
                <BalanceDisplay user={user} />
              ) : (
                <Box style={{ marginTop: 20 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    className="welcome-text d-flex jc-start FontSize12"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "left",
                      color: "#000",
                    }}
                  >
                    {i18next.t("No balance")}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default Banking;
