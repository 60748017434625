import { Box, Button, Typography } from "@mui/material";
import "../../pages/LandingPagesGlobal2.scss";

const HeadlineVvidgetSmall2 = ({
  headline,
  subtitle,
  smallText,
  buttonText,
  onClickFunction,
}: any) => {
  return (
    <>
      <Box>
        <Typography
          variant="h4"
          sx={{ marginBottom: "1rem", maxWidth: "652px" }}
          className="blueHeadlineMiniLandingPages"
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
        <Box className="headlineVvidgetMainBoxSmall">
          <Box sx={{ maxWidth: "1005px", width: "100%" }}>
            <Typography
              variant="body1"
              paragraph
              sx={{ maxWidth: "935px", marginBottom: "0px !important" }}
              className="textsLandingPage"
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </Box>
          {buttonText && (
            <Box className="smallTextAndBtnBox">
              <Box>
                <Button className="smallTextBtn" onClick={onClickFunction}>
                  {buttonText}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HeadlineVvidgetSmall2;
