import { Typography } from "@mui/material";
import "./TransactionsSection.scss";
import TransactionListItem from "../TransactionsListItem/TransactionsListItem";
import TransactionListItemBalance from "../TransactionsListItem/TransactionsListItemBalance";
import TransactionListItemCustom from "../TransactionsListItem/TransactionsListItemCustom";
import TransactionsListItemCrypto from "../TransactionsListItem/TransactionsListItemCrypto";
import i18next from "i18next";
import TransactionListItemTransfer from "../TransactionsListItem/TransactionListItemTransfer";

interface Props {
  listOfTransactionItems: any[];
  sectionName: string;
  redirectPage: string | null;
}

function TransactionSection({
  listOfTransactionItems,
  sectionName,
  redirectPage,
}: Props) {
  const formatDate = (sectionName: string) => {
    // Check if sectionName is a valid date
    const date = new Date(sectionName);
    if (!isNaN(date.getTime())) {
      // It's a valid date, format it
      const currentLanguage = i18next.language || "en-US";
      return new Intl.DateTimeFormat(currentLanguage, {
        month: "long",
        day: "numeric",
      }).format(date);
    } else {
      // Not a valid date, return as is
      return sectionName;
    }
  };
  return (
    <div className="transaction-section">
      <Typography
        component="h1"
        className="registration0875RemFontSize"
        variant="h5"
        sx={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: "300",
          color: "#454745",
        }}
      >
        {formatDate(sectionName)}
      </Typography>
      <div className="horizontal-line-list mb-8"></div>
      <div className="transaction-list-container">
        {listOfTransactionItems.map((item, index) =>
          item &&
          item.transaction_type &&
          (item.transaction_type === "balance_in" ||
            item.transaction_type === "balance_in_credit_card" ||
            item.transaction_type === "balance_in_bank" ||
            item.transaction_type === "balance_in_crypto" ||
            item.transaction_type === "balance_out") ? (
            <TransactionListItemBalance
              redirectPage={redirectPage}
              key={index}
              item={item}
            />
          ) : item &&
            (item.transaction_type === "m_transfer_out" ||
              item.transaction_type === "p_transfer_out") ? (
            <TransactionListItemCustom
              redirectPage={redirectPage}
              key={index}
              item={item}
            />
          ) : item &&
            (item.transaction_type === "p_transfer_fee" ||
              item.transaction_type === "p_transfer_auto_out" ||
              item.transaction_type === "p_transfer_auto_in" ||
              item.transaction_type === "p_conversion_in" ||
              item.transaction_type === "p_conversion_out") ? (
            <TransactionListItemTransfer
              redirectPage={redirectPage}
              key={index}
              item={item}
            />
          ) : item && item.transaction_type === "crypto_transfer_out" ? (
            <TransactionsListItemCrypto
              redirectPage={redirectPage}
              key={index}
              item={item}
            />
          ) : (
            <TransactionListItem
              redirectPage={redirectPage}
              key={index}
              item={item}
            />
          )
        )}
      </div>
    </div>
  );
}

export default TransactionSection;
