import { useNavigate } from "react-router-dom";
import "../../pages/LandingPage.scss";
import { Box, Button, Typography } from "@mui/material";
import i18next from "i18next";
import welcompeople from "../../../../assets/images/wellcomePeople.png";

function WelcomeVicPay({ user }: any) {
  const navigate = useNavigate();

  return (
    <Box className="welcomeToVicPayContainer">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography className="welcomeToVicPayText">
          {i18next.t("Welcome to VicPay")}
        </Typography>
        <Box className="boxPartnerCard">
          <Typography className="yourPartnerInWWText">
            {i18next.t("Your partner in worldwide money and crypto payments")}
          </Typography>
          <Box className="welcomeToVPCard"></Box>
        </Box>

        <Box className="buttonImageContainer">
          <Box className="welcommePeopleBox">
            <img
              src={welcompeople}
              alt="1000+ Happy Clients"
              className="welcommePeopleBoxImage"
            />
            <Typography className="welcommePeopleBoxText">
              1000+ Happy
              <br />
              Clients
            </Typography>
          </Box>
          <Button
            className="contactUsButtonWelToVP"
            onClick={(e) => {
              if (user && user.id) {
                navigate("/home");
              } else {
                navigate("/registration");
              }
            }}
          >
            {user && user.id
              ? i18next.t("My Account")
              : i18next.t("Create account now")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default WelcomeVicPay;
