import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import enUS from "./locales/en-US.json";
import deDE from "./locales/de-DE.json";
import esES from "./locales/es-ES.json";
import itIT from "./locales/it-IT.json";
import hrHR from "./locales/hr-HR.json";
import zhCN from "./locales/zh-CN.json";

const resources = {
  "en-US": {
    translation: enUS,
  },
  "de-DE": {
    translation: deDE,
  },
  "es-ES": {
    translation: esES,
  },
  "it-IT": {
    translation: itIT,
  },
  "hr-HR": {
    translation: hrHR,
  },
  "zh-CN": {
    translation: zhCN,
  },
};

const lngStored = localStorage.getItem("appLanguage") || "en-US";

i18next.use(initReactI18next).init({
  resources,
  lng: lngStored,
  fallbackLng: "en-US",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
