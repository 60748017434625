import i18next from "i18next";
import "../../pages/LandingPage.scss";
import { Box, Typography } from "@mui/material";

function GetCreditCard() {
  const translate = i18next.t("Enjoy global acceptance with 65 currencies");
  return (
    <Box className="getYourCardContainer">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography className="getYourCardText">
          {i18next.t("Get Your Personalised Credit Card")}
        </Typography>
        <Typography className="getYourCardDescText">
          {i18next.t("Experience the convenience of a debit card, optimized for daily transactions - Available soon")}
        </Typography>
        <Box className="getYourCardImage grow-vpitem"></Box>
        <div className="getYourCardDescSecText" dangerouslySetInnerHTML={{__html: translate}}/>
      </Box>
    </Box>
  );
}

export default GetCreditCard;
