import {
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import "./ReviewStep.scss";
import { useFormik } from "formik";
import api from "src/store/interceptors/api";
import i18next from "i18next";
import BackNextBtns from "../shared/BackNextBtns/BackNextBtns";
import transactionsService from "src/store/transactions/transactions_service";
const defaultTheme = createTheme();
interface Props {
  quest: any;
  accountData: any;
  setTransfer: (values: any) => void;
  step: number;
  setStep: (value: number) => void;
  accountTransferType: any;
  uuid: any;
}

function ReviewStep({
  quest,
  accountData,
  setTransfer,
  setStep,
  accountTransferType,
  step,
  uuid,
}: Props) {
  const formik = useFormik({
    initialValues: {
      quoteUuid: quest.id,
      reference: "",
      targetAccount: accountData.id,
      customerTransactionId: "",
      details: { transferPurpose: '[{"files":[],"notes":[]}]' },
    },
    onSubmit: async (values: any) => {
      if (
        (!values.targetAccount || values.targetAccount === undefined) &&
        accountData &&
        accountData.targetAccount
      ) {
        values.targetAccount = accountData.targetAccount;
      }

      if (uuid) {
        values.uuid = uuid;
        values.customerTransactionId = uuid;
        values.quest = quest;
        values.accountData = accountData;
        await api
          .post("/transactions", values)
          .then((res: any) => {
            if (res.data) {
              if (res.data.errors) {
                console.log("errors", res.data.errors);
              } else {
                setTransfer(res.data);
                setStep(step + 1);
              }
            }
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    },
  });
  return (
    <>
      <div className="reviewContainer">
        <ThemeProvider theme={defaultTheme}>
          <div className="review-content-container">
            <Typography className="detailsMainText">
              <strong>{i18next.t("Transfer details")}</strong>
            </Typography>

            <div className="review-container-item">
              <Typography className="detailsText">
                {i18next.t("You send exactly")}
              </Typography>
              <Typography className="detailsResultsMainText elipsesMaxWidthReview">
                <strong>
                  {quest.formated.sourceAmount} {quest.formated.sourceCurrency}
                </strong>
              </Typography>
            </div>

            <div className="review-container-item">
              <Typography className="detailsText">
                {i18next.t("Guaranteed rate")}
              </Typography>
              <Typography className="detailsResultsText elipsesMaxWidthReview">
                {quest.rate}
              </Typography>
            </div>

            {accountTransferType === "provider" ? (
              <>
                <div className="review-container-item">
                  <Typography className="detailsText">
                    {i18next.t("Total fees (included)")}
                  </Typography>
                  <Typography className="detailsResultsText elipsesMaxWidthReview">
                    {transactionsService.formatNumber(quest.formated.fee)}{" "}
                    {quest.formated.targetCurrency &&
                    quest.formated.targetCurrency !==
                      quest.formated.soruceCurrency
                      ? quest.formated.targetCurrency
                      : quest.formated.sourceCurrency}
                  </Typography>
                </div>

                <div className="review-container-item">
                  <Typography className="detailsText">
                    {i18next.t("Total amount we'll convert")}
                  </Typography>
                  <Typography className="detailsResultsText elipsesMaxWidthReview">
                    {quest?.rate
                      ? transactionsService.formatNumber(
                          quest.formated.sourceAmount * quest.rate
                        )
                      : transactionsService.formatNumber(
                          quest.formated.sourceAmount
                        )}{" "}
                    {quest.formated.targetCurrency &&
                    quest.formated.targetCurrency !==
                      quest.formated.soruceCurrency
                      ? quest.formated.targetCurrency
                      : quest.formated.sourceCurrency}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <div className="review-container-item">
                  <Typography className="detailsText">
                    {i18next.t("Total fees (included)")}
                  </Typography>
                  <Typography className="detailsResultsText elipsesMaxWidthReview">
                    {transactionsService.formatNumber(quest.formated.fee)}{" "}
                    {quest.formated.soruceCurrency}
                  </Typography>
                </div>

                <div className="review-container-item">
                  <Typography className="detailsText">
                    {i18next.t("Total amount we'll convert")}
                  </Typography>
                  <Typography className="detailsResultsText elipsesMaxWidthReview">
                    {quest.formated.sourceAmount - quest.formated.fee}{" "}
                    {quest.formated.sourceCurrency}
                  </Typography>
                </div>
              </>
            )}

            <div className="review-container-item">
              <Typography className="detailsText">
                {accountData.account_holder ?? "Recipient "} {i18next.t("gets")}
              </Typography>
              <Typography className="detailsResultsMainText elipsesMaxWidthReview">
                <strong>
                  {quest.formated.targetAmount} {quest.formated.targetCurrency}
                </strong>
              </Typography>
            </div>
          </div>

          <div className="review-content-container">
            <Typography className="detailsMainText">
              <strong>{i18next.t("Recipient details")}</strong>
            </Typography>

            <div className="review-container-item">
              <Typography className="detailsText">
                {i18next.t("Account holder name")}
              </Typography>
              <Typography className="detailsResultsText elipsesMaxWidthReview">
                {accountData.account_holder
                  ? accountData.account_holder
                  : accountData.accountHolderName ?? i18next.t("Recipient")}
              </Typography>
            </div>

            {accountData?.meta?.details?.iban ? (
              <>
                <div className="review-container-item">
                  <Typography className="detailsText">
                    {i18next.t("IBAN")}
                  </Typography>
                  <Typography className="detailsResultsText elipsesMaxWidthReview">
                    {accountData.meta.details.iban}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                {accountData?.meta?.details?.sortCode && (
                  <div className="review-container-item">
                    <Typography className="detailsText">
                      {i18next.t("Sort code")}
                    </Typography>
                    <Typography className="detailsResultsText elipsesMaxWidthReview">
                      {accountData?.meta?.details?.sortCode ?? ""}
                    </Typography>
                  </div>
                )}
                {accountData?.meta?.details?.accountNumber && (
                  <div className="review-container-item">
                    <Typography className="detailsText">
                      {i18next.t("Account number")}
                    </Typography>
                    <Typography className="detailsResultsText elipsesMaxWidthReview">
                      {accountData?.meta?.details?.accountNumber ?? ""}
                    </Typography>
                  </div>
                )}
                {accountData?.meta?.details?.email && (
                  <div className="review-container-item">
                    <Typography className="detailsText">
                      {i18next.t("Email")}
                    </Typography>
                    <Typography className="detailsResultsText elipsesMaxWidthReview">
                      {accountData?.meta?.details?.email ?? ""}
                    </Typography>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="review-content-container">
            <Typography className="detailsMainText">
              <strong>{i18next.t("Schedule details")}</strong>
            </Typography>

            <div className="review-container-item">
              <Typography className="detailsText">
                {i18next.t("Sending")}
              </Typography>
              <Typography className="detailsResultsText">
                {i18next.t("Now")}
              </Typography>
            </div>

            <div className="review-container-item">
              <Typography className="detailsText">
                {i18next.t("Repeats")}
              </Typography>
              <Typography className="detailsResultsText">
                {i18next.t("Never")}
              </Typography>
            </div>
          </div>
        </ThemeProvider>
      </div>

      <div className="reviewFooter">
        <TextField
          placeholder={i18next.t("Reference")}
          style={{ width: "100%", marginTop: 10 }}
          type="text"
          value={formik.values.reference}
          onChange={formik.handleChange("reference")}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: "0px!important",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "10px",
              height: "48px",
              "@media (max-width: 600px)": {
                fontSize: "14px",
                height: "42px",
              },
            },
          }}
        />

        <div className="d-flex w-100 ai-center jusifyContentSpaceBetween mt-8">
          <BackNextBtns
            backText={i18next.t("Back")}
            nextText={i18next.t("Continue")}
            backType="button"
            nextType="submit"
            onBackClick={() => setStep(1)}
            onNextClick={(e: any) => formik.handleSubmit(e)}
          />
        </div>
      </div>
    </>
  );
}

export default ReviewStep;
