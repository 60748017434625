import "../../pages/LandingPage.scss";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import api from "src/store/interceptors/api";
import { toast } from "react-toastify";
import { ReactComponent as Tic } from "../../../../assets/images/checkboxTic.svg";
import { useState } from "react";
import { useNavigate } from "react-router";
import i18next from "i18next";

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18next.t("Too Short!"))
    .max(150, i18next.t("Too Long!"))
    .required(i18next.t("Required")),
  organization: Yup.string()
    .min(2, i18next.t("Too Short!"))
    .max(150, i18next.t("Too Long!"))
    .required(i18next.t("Required")),
  email: Yup.string()
    .email(i18next.t("Invalid email"))
    .required(i18next.t("Required")),
  industry: Yup.string()
    .min(2, i18next.t("Too Short!"))
    .max(150, i18next.t("Too Long!"))
    .required(i18next.t("Required")),
  phone: Yup.string()
    .min(2, i18next.t("Too Short!"))
    .max(150, i18next.t("Too Long!"))
    .required(i18next.t("Required")),
  message: Yup.string()
    .min(2, i18next.t("Too Short!"))
    .max(20000, i18next.t("Too Long!"))
    .required(i18next.t("Required")),
});

function ContactUs({ isContactUsPage = false }) {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const navigate = useNavigate();

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };

  return (
    <Box className="conatctContainer">
      <Box id="contact-us" className="contactUsContainer">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <Typography className="contactUsText">
            {!isContactUsPage
              ? i18next.t("Contact us:")
              : i18next.t("Effortless Reach-out Option")}
          </Typography>

          <Typography
            className="contactUsDescText"
            dangerouslySetInnerHTML={{
              __html: !isContactUsPage
                ? i18next.t(
                    "Experience innovation and security with VicPay. be part or a new era or money transfer that offers unparalleled efficiency and security. Don't miss the opportunity to become part of a visionary financial network"
                  )
                : i18next.t(
                    "In case you want to send us a quick message, you can use our easy contact form below"
                  ),
            }}
          />
        </Box>
      </Box>
      <Formik
        initialValues={{
          name: "",
          email: "",
          organization: "",
          phone: "",
          industry: "",
          message: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (isChecked2) {
            let data = { ...values, newsletter: isChecked1 };
            await api
              .post("/newsletters", data)
              .then((res: any) => {
                toast.success(i18next.t("Message sent!"));
                resetForm({
                  values: {
                    name: "",
                    email: "",
                    organization: "",
                    phone: "",
                    industry: "",
                    message: "",
                  },
                });
                setTimeout(() => {
                  navigate("/");
                }, 2000);
              })
              .catch((error: any) => {
                toast.error(i18next.t("Something went wrong"));
                setSubmitting(false);
              });
          } else {
            alert(
              i18next.t(
                "Please agree to allow VicPay to store and process your data!"
              )
            );
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
        }) => (
          <Form>
            <Box className="contactUsFormContainer">
              <Box className="contactUsRowFieldsContainer">
                <TextField
                  fullWidth
                  placeholder={i18next.t("Name")}
                  name="name"
                  onChange={handleChange}
                  error={touched.name && errors.name !== undefined}
                  sx={{
                    background: "#F5F6F7",
                    borderRadius: "11px",
                    borderWidth: 0,
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#b2c9e6",
                      },
                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff3366",
                      },
                    "& .MuiOutlinedInput-input": {
                      color: "#2a5182",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "30px",

                      "@media (max-width:600px)": {
                        fontSize: "14px",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                      color: "#3e78c1",
                    },
                    "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                      color: "#ff3366",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  placeholder={i18next.t("Organization")}
                  name="organization"
                  onChange={handleChange}
                  error={
                    touched.organization && errors.organization !== undefined
                  }
                  sx={{
                    background: "#F5F6F7",
                    borderRadius: "11px",
                    borderWidth: 0,
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#b2c9e6",
                      },
                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff3366",
                      },
                    "& .MuiOutlinedInput-input": {
                      color: "#2a5182",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "30px",

                      "@media (max-width:600px)": {
                        fontSize: "14px",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                      color: "#3e78c1",
                    },
                    "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                      color: "#ff3366",
                    },
                  }}
                />
              </Box>
              <Box className="contactUsRowFieldsContainer">
                <TextField
                  fullWidth
                  placeholder={i18next.t("Email")}
                  name="email"
                  onChange={handleChange}
                  error={touched.email && errors.email !== undefined}
                  sx={{
                    background: "#F5F6F7",
                    borderRadius: "11px",
                    borderWidth: 0,
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#b2c9e6",
                      },
                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff3366",
                      },
                    "& .MuiOutlinedInput-input": {
                      color: "#2a5182",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "30px",

                      "@media (max-width:600px)": {
                        fontSize: "14px",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                      color: "#3e78c1",
                    },
                    "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                      color: "#ff3366",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  placeholder={i18next.t("Phone")}
                  name="phone"
                  onChange={handleChange}
                  error={touched.phone && errors.phone !== undefined}
                  sx={{
                    background: "#F5F6F7",
                    borderRadius: "11px",
                    borderWidth: 0,
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#b2c9e6",
                      },
                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff3366",
                      },
                    "& .MuiOutlinedInput-input": {
                      color: "#2a5182",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "30px",

                      "@media (max-width:600px)": {
                        fontSize: "14px",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                      color: "#3e78c1",
                    },
                    "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                      color: "#ff3366",
                    },
                  }}
                />
              </Box>
              <Box sx={{ mb: "20px" }}>
                <TextField
                  fullWidth
                  placeholder={i18next.t("Industry")}
                  onChange={handleChange}
                  name="industry"
                  error={touched.industry && errors.industry !== undefined}
                  sx={{
                    background: "#F5F6F7",
                    borderRadius: "11px",
                    borderWidth: 0,
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#b2c9e6",
                      },
                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff3366",
                      },
                    "& .MuiOutlinedInput-input": {
                      color: "#2a5182",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "30px",

                      "@media (max-width:600px)": {
                        fontSize: "14px",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                      color: "#3e78c1",
                    },
                    "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                      color: "#ff3366",
                    },
                  }}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={7}
                  name="message"
                  onChange={handleChange}
                  error={touched.message && errors.message !== undefined}
                  placeholder={i18next.t("Message")}
                  sx={{
                    background: "#F5F6F7",
                    borderRadius: "11px",
                    borderWidth: 0,
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#b2c9e6",
                      },
                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ff3366",
                      },
                    "& .MuiOutlinedInput-input": {
                      color: "#2a5182",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "30px",

                      "@media (max-width:600px)": {
                        fontSize: "14px",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-input::placeholder": {
                      color: "#3e78c1",
                    },
                    "& .Mui-error .MuiOutlinedInput-input::placeholder": {
                      color: "#ff3366",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  mt: "20px",
                }}
              >
                <Button
                  className={`ContactUsBtnCheckBox ${
                    isChecked1 ? "ContactUsBtnCheckBoxChecked" : ""
                  }`}
                  onClick={handleCheckboxChange1}
                >
                  {isChecked1 ? <Tic /> : null}
                </Button>
                <Typography
                  className="contactUsCheckboxText"
                  sx={{ opacity: "0.5" }}
                  dangerouslySetInnerHTML={{
                    __html: i18next.t(
                      "I wish to recieve occasional email with VicPays product offers, news etc."
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Button
                  className={`ContactUsBtnCheckBox ${
                    isChecked2 ? "ContactUsBtnCheckBoxChecked" : ""
                  }`}
                  onClick={handleCheckboxChange2}
                >
                  {isChecked2 ? <Tic /> : null}
                </Button>
                <Typography
                  className="contactUsCheckboxText"
                  sx={{ opacity: "0.5" }}
                >
                  {i18next.t(
                    "I agree to allow VicPay to store and process my personal data."
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                className="contactUsFormBtn"
              >
                {i18next.t("Contact Us")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ContactUs;
