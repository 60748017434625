import { Box, Typography } from "@mui/material";
import "./TitleTextVvidget.scss";

const TitleTextVvidget = ({
  title,
  text,
  text2,
  text3,
  showBoxStyle = false,
  icon,
}: any) => {
  return (
    <>
      <Box className={showBoxStyle ? "titleTextVvidgetBoxStyle" : ""}>
        {icon && (
          <Box>
            <img src={icon} alt="icon" />
          </Box>
        )}
        <Box>
          <Typography
            variant="h3"
            sx={{ marginTop: "2rem", marginBottom: "2rem" }}
            className="titleTextVvidgetTitle"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="body1"
            paragraph
            className="titleTextVvidgetText"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <Typography
            variant="body1"
            paragraph
            className="titleTextVvidgetText"
            dangerouslySetInnerHTML={{
              __html: text2,
            }}
          />
          <Typography
            variant="body1"
            paragraph
            className="titleTextVvidgetText"
            dangerouslySetInnerHTML={{
              __html: text3,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default TitleTextVvidget;
