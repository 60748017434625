import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import TextNumbersVvidget from "../components/TextNumbersVvidget/TextNumbersVvidget";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";

const defaultTheme = createTheme();

const OnlineNewsroom: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };

  const items = [
    {
      subTitle: i18next.t("Contact our Support Desk"),
      desc: i18next.t(
        "The fastest way to solve your issue is by reaching out to our dedicated support team"
      ),
    },
    {
      subTitle: i18next.t("Call our dedicated support number"),
      desc: i18next.t(
        "Dial +1 604-260-0738 to speak directly with a member of our support team"
      ),
    },
    {
      subTitle: i18next.t("Use our contact form"),
      desc: i18next.t("Alternatively, you can use our contact form"),
      link: "/contact-us",
      linkText: i18next.t("here"),
      desc2: i18next.t("to submit your issue"),
    },
    {
      subTitle: i18next.t("Resolution within 24 hours"),
      desc: i18next.t(
        "We typically resolve all issues within 24 hours of receiving your complaint"
      ),
    },
    {
      subTitle: i18next.t("Confirmation message"),
      desc: i18next.t(
        "You will receive a written message confirming that we have received your complaint"
      ),
    },
    {
      subTitle: i18next.t("Further information may be requested"),
      desc: i18next.t(
        "If necessary, we may request additional information to assist with resolving your issue"
      ),
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Complains")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t("We are sorry to see you here")}
                    subtitle={i18next.t(
                      "We’re sorry you didn’t get the service you’d expect from us and in case your account has been deactivated, you can make an appeal and we'll let you know about next steps"
                    )}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "We are looking into each case carefully and responding as quickly as possible"
                  )}
                  text2={i18next.t(
                    "We aim to acknowledge your complaint within 24 hours and send you a final response within 7 calendar days, in case you are able to provide all necessary documents"
                  )}
                  text3={i18next.t(
                    "On rare occasions, we may unfortunately need to extend the time period for investigating your case in detail"
                  )}
                />
              </Box>

              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "In case you want to send us written correspondence, please send it to"
                  )}
                </Typography>
                <Box className="contactUsHeadingBoxRowReverse">
                  <Box>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage"
                    >
                      <span className="boldBlueFont">
                        {" "}
                        {i18next.t("Address")}:
                      </span>{" "}
                      <br />
                      <span className="textsLandingsmallNoFontSizeNew addressBoxAlignments">
                        Victorum Capital Services Limited
                        <br />
                        2/3 48 West George Street,
                        <br />
                        Glasgow, Scotland, G2 1BP
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont">
                        {i18next.t("Company")}:
                      </span>{" "}
                      <span className="basicFont">
                        {i18next.t("Registered in Scotland, United Kingdom")}
                      </span>
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont">
                        {i18next.t("Company number")}
                      </span>{" "}
                      <span className="basicFont">SC745907</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont"> {i18next.t("CEO")} </span>
                      <span className="basicFont">Osbert Döhl</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default OnlineNewsroom;
