import { useEffect, useState } from "react";
import {
  Button,
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import api from "src/store/interceptors/api";
import ConfirmationDialog from "src/components/shared/ConfirmationDialog/ConfirmationDialog";
import i18next from "i18next";

const statusOptions = [
  { name: "PUBLISHED", label: "Created from client" },
  { name: "COMPLETED", label: "Payment received" },
  { name: "INVALIDATED", label: "Rejected" },
];
function getStatus(status: string) {
  const obj = statusOptions.find((option) => option.name === status);
  if (obj) return obj;
  const response = { name: status, label: status };
  return response;
}
const formatDate = (sectionName: string) => {
  // Check if sectionName is a valid date
  const date = new Date(sectionName);
  if (!isNaN(date.getTime())) {
    // It's a valid date, format it
    const currentLanguage = i18next.language || "en-US";
    return new Intl.DateTimeFormat(currentLanguage, {
      month: "long",
      day: "numeric",
      minute: "2-digit",
      hour: "2-digit",
    }).format(date);
  } else {
    // Not a valid date, return as is
    return sectionName;
  }
};
interface Props {
  data: any;
  user: any;
}
function PaymentRequestAdminActions({ data, user }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [currentStatus, setCurrentStatus] = useState<any>(null);
  useEffect(() => {
    const status = getStatus(data.status);
    setCurrentStatus(status);
  }, [data]);
  // conf dialog start
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleStatusChange = async () => {
    if (
      transactionStatus &&
      currentStatus &&
      currentStatus.name &&
      data &&
      data.id &&
      data.status &&
      data.status !== transactionStatus
    ) {
      if (currentStatus.name === "COMPLETED") {
        alert(i18next.t("Request was completed, status can't be changed!"));
      } else {
        setIsDialogOpen(true);
      }
    } else {
      alert(i18next.t("Please check the status!"));
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = async (e: any) => {
    setIsDialogOpen(false);
    try {
      setLoading(true);
      const payload = {
        status: transactionStatus,
      };
      const res = await api.post(`/payment-request-action/${data.id}`, payload);
      setLoading(false);
      if (res) {
        window.location.reload();
      } else {
        alert("Please try again!");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error canceling the transfer:", error);
    }
    //handleRemoveFileClick(e);
  };

  const handleTransactionStatusChange = (event: any) => {
    setTransactionStatus(event.target.value);
  };

  return (
    <div>
      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleDialogConfirm}
        content={{
          title: i18next.t("Sure you'd like to change the status?"),
          body: i18next.t("Note that this action cannot be undone."),
          cancelText: i18next.t("Cancel"),
          confirmText: i18next.t("Change"),
        }}
        className="custom-dialog"
      />
      <Box
        sx={{
          marginBottom: "4rem",
        }}
      >
        <Box sx={{ mt: 2 }}>
          <div className="personal-box">
            <div className="transaction-item-details">
              <div className="d-flex ai-center jc-center">
                <Typography
                  className="your-details-text d-flex jc-start FontSize12"
                  component="h1"
                  variant="h5"
                  sx={{
                    fontFamily: "Helvetica, sans-serif",
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Admin actions")}
                </Typography>
              </div>
            </div>
            <Box sx={{ padding: "16px" }}>
              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Created at")}
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {data && data.created_at ? formatDate(data.created_at) : ""}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Updated at")}
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {data && data.updated_at ? formatDate(data.updated_at) : ""}
                </Typography>
              </Box>

              <Box
                columnGap={1}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  className="transaction-detail-label oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t("Current status")}
                </Typography>
                <Typography
                  className="transaction-detail-value oneRemFontSize"
                  sx={{
                    fontWeight: "400",
                    textAlign: "start",
                  }}
                >
                  {i18next.t(
                    currentStatus && currentStatus.label
                      ? currentStatus.label
                      : ""
                  )}
                </Typography>
              </Box>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ padding: 1 }}
              justifyItems={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={6} md={8}>
                <FormControl fullWidth>
                  <InputLabel sx={{ top: "-4px" }}>
                    {i18next.t("Status")}
                  </InputLabel>
                  <Select
                    fullWidth
                    label={i18next.t("Status")}
                    defaultValue={transactionStatus}
                    value={transactionStatus}
                    onChange={handleTransactionStatusChange}
                    sx={{
                      borderRadius: "10px",
                      height: "48px",
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "16.5px 14px 16.5px 14px",
                        },
                      "@media (max-width: 600px)": {
                        height: "42px",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    {statusOptions.map(
                      (statusItem: any, statusItemIndex: any) => {
                        //    console.log(data.status, statusItem.name)
                        return (
                          <MenuItem
                            key={"status" + statusItem.name + statusItemIndex}
                            selected={statusItem.name === data.status}
                            value={statusItem.name}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {i18next.t(statusItem.label)}
                            </Box>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button
                  fullWidth
                  onClick={handleStatusChange}
                  variant="text"
                  className="actionButton oneRemFontSize btnsLoginHeight"
                >
                  {i18next.t("Change status")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default PaymentRequestAdminActions;
