import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import api from "src/store/interceptors/api";

interface Props {
  formik: any;
  initialsField: any;
}
const currencies = ["EUR", "USD", "GBP"];

function EditOnBoardingForm({ formik, initialsField }: Props) {
  const [loggedUser, setLoggedUser] = useState("");

  const getLoggedUserRole = async () => {
    try {
      const response = await api.get(`me`);
      const loggedUserRole =
        response && response.data && response.data.data.role;
      if (loggedUserRole) {
        setLoggedUser(loggedUserRole);
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  useEffect(() => {
    getLoggedUserRole();
  }, [loggedUser]);

  const isFieldDisabled = (field: any) => {
    if (loggedUser === "Admin") {
      return false;
    }
    if (field === "pay_in_currency" || field === "pay_out_currency") {
      if (initialsField[field].length > 0 && loggedUser !== "Admin") {
        return true;
      }
    } else {
      if (loggedUser !== "Admin" && initialsField[field] !== "") {
        return true;
      }
    }
  };
  const handleCountrySelect = (value: any) => {
    formik.setFieldValue("verification_country_code", value);
  };
  return (
    <Box>
      <Box className="mb-big">
        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Money in")}
        </Typography>
        <Box className="radio-group">
          <InputLabel
            id="currency"
            className="oneRemFontSize"
            style={{
              marginBottom: "8px",
              marginTop: "8px",
              color: "#000000",
            }}
          >
            {i18next.t("IBAN needs")}
          </InputLabel>
          <RadioGroup
            className="radioGroupButton"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="iban_needs"
            value={formik.values.iban_needs}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              disabled={isFieldDisabled("iban_needs")}
              value="LU"
              control={<Radio />}
              label="LU"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",

                  "@media (max-width:600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            />
            <FormControlLabel
              disabled={isFieldDisabled("iban_needs")}
              value="NL"
              control={<Radio />}
              label="NL"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",

                  "@media (max-width:600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            />

            <FormControlLabel
              disabled={isFieldDisabled("iban_needs")}
              value="GB IBAN"
              control={<Radio />}
              label="GB IBAN"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",

                  "@media (max-width:600px)": {
                    fontSize: "14px !important",
                  },
                },
              }}
            />
          </RadioGroup>
        </Box>
        <InputLabel
          id="pay_in_currency"
          className="oneRemFontSize"
          style={{
            marginBottom: "8px",
            marginTop: "8px",
            color: "#000000",
          }}
        >
          {i18next.t("Pay-in currencies")}
        </InputLabel>
        <Box className="customInputContainer">
          <Box className="customInputSubContainer">
            <Select
              disabled={isFieldDisabled("pay_in_currency")}
              multiple={true}
              labelId="pay_in_currency"
              id="pay_in_currency"
              name="pay_in_currency"
              style={{ width: "100%" }}
              inputProps={{
                classes: {
                  notchedOutline: "customInputNoBorder",
                  input: "customInputpadding",
                },
              }}
              sx={{
                borderRadius: "16px",
                height: "55px",
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "16.5px 14px 16.5px 14px",
                  },
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              }}
              value={formik.values.pay_in_currency}
              onChange={formik.handleChange}
            >
              {currencies.map((menuItem: any, menuIndex: any) => (
                <MenuItem key={"currenct" + menuIndex} value={menuItem}>
                  {menuItem}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box
          className="mb-big"
          style={{ paddingTop: 5, paddingBottom: 5, marginBottom: 10 }}
        >
          <Typography className="labelsLocalIban">
            {i18next.t("Volume of money in")}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("volume_of_money_in")}
                style={{ width: "100%" }}
                type="number"
                id="volume_of_money_in"
                value={formik.values.volume_of_money_in}
                name="volume_of_money_in"
                onChange={formik.handleChange}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "0px!important",
                  },
                }}
                InputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                  sx: {
                    sx: {
                      "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                        {
                          borderRadius: "16px !important",
                          "-webkit-text-fill-color": "inherit",
                          "-webkit-box-shadow":
                            "0 0 0px 1000px #e0e3e7 inset !important",
                          backgroundColor: "#e0e3e7 !important",
                        },
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Typography
          className="pageTitleFontSize"
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            color: "#0e0f0c",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          {i18next.t("Money out")}
        </Typography>
        <InputLabel
          id="pay_out_currency"
          className="oneRemFontSize"
          style={{
            marginBottom: "8px",
            marginTop: "8px",
            color: "#000000",
          }}
        >
          {i18next.t("Pay-out currencies")}
        </InputLabel>
        <Box className="customInputContainer">
          <Box className="customInputSubContainer">
            <Select
              disabled={isFieldDisabled("pay_out_currency")}
              multiple={true}
              labelId="pay_out_currency"
              id="pay_out_currency"
              name="pay_out_currency"
              style={{ width: "100%" }}
              inputProps={{
                classes: {
                  notchedOutline: "customInputNoBorder",
                  input: "customInputpadding",
                },
              }}
              sx={{
                borderRadius: "16px",
                height: "55px",
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "16.5px 14px 16.5px 14px",
                  },
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              }}
              value={formik.values.pay_out_currency}
              onChange={formik.handleChange}
            >
              {currencies.map((menuItem: any, menuIndex: any) => (
                <MenuItem key={"currenct" + menuIndex} value={menuItem}>
                  {menuItem}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Box
          className="mb-big"
          style={{ paddingTop: 5, paddingBottom: 5, marginBottom: 10 }}
        >
          <Typography className="labelsLocalIban">
            {i18next.t("Volume of money out")}
          </Typography>
          <Box className="customInputContainer">
            <Box className="customInputSubContainer">
              <TextField
                disabled={isFieldDisabled("volume_of_money_out")}
                style={{ width: "100%" }}
                type="number"
                id="volume_of_money_out"
                value={formik.values.volume_of_money_out}
                name="volume_of_money_out"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: "customInputNoBorder",
                    input: "customInputpadding",
                  },
                }}
                sx={{
                  borderRadius: "16px",
                  height: "55px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className="mb-big">
          <Typography
            className="pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#0e0f0c",
              maxWidth: "600px",
              overflow: "hidden",
            }}
          >
            {i18next.t("Business description")}
          </Typography>
          <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "A detailed description of business activities including funds and goods flow overview"
              )}
            </Typography>
            <Box className="customInputContainer">
              <Box className="customInputSubContainer">
                <TextField
                  disabled={isFieldDisabled("bussiness_activities")}
                  minRows={3}
                  style={{ width: "100%" }}
                  multiline={true}
                  value={formik.values.bussiness_activities}
                  name="bussiness_activities"
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={i18next.t("Please enter the information...")}
                  inputProps={{
                    classes: {
                      notchedOutline: "customInputNoBorder",
                      input: "customInputpadding",
                    },
                  }}
                  sx={{
                    borderRadius: "16px",
                    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "16.5px 14px 16.5px 14px",
                      },
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "How long has the company been in business, and where is the business based and managed from?"
              )}
            </Typography>
            <Box className="customInputContainer">
              <Box className="customInputSubContainer">
                <TextField
                  disabled={isFieldDisabled("company_in_bussiness")}
                  minRows={3}
                  style={{ width: "100%" }}
                  multiline={true}
                  value={formik.values.company_in_bussiness}
                  name="company_in_bussiness"
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={i18next.t("Please enter the information...")}
                  inputProps={{
                    classes: {
                      notchedOutline: "customInputNoBorder",
                      input: "customInputpadding",
                    },
                  }}
                  sx={{
                    borderRadius: "16px",
                    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "16.5px 14px 16.5px 14px",
                      },
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "In what countries is the company currently operating and planning to operate in future?"
              )}
            </Typography>
            <Box className="customInputContainer">
              <CountrySelect
                value={formik.values.verification_country_code}
                isTouched={formik.touched.verification_country_code}
                errors={formik.errors.verification_country_code}
                onSelect={handleCountrySelect}
                code={true}
              />
            </Box>
          </Box>
        </Box>

        <Button
          onClick={(e: any) => {
            formik.handleSubmit(e);
          }}
          fullWidth
          variant="contained"
          className="oneRemFontSize btnsLoginHeight"
          sx={{
            color: "white",
            backgroundColor: "#2A5182",
            borderRadius: "24px",
            textTransform: "none",
            marginTop: "2rem",
            fontFamily: "Helvetica, sans-serif",
            marginBottom: "4rem",
          }}
        >
          {i18next.t("Save")}
        </Button>
      </Box>
    </Box>
  );
}

export default EditOnBoardingForm;
