import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Profile.scss";
import Nav from "src/components/layout/nav/Nav";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import profileService, {
  removeProfilePhoto,
} from "src/store/profile/profile_service";
import {
  updateUserProfile,
  updateUserProfilePhoto,
  updateUser,
} from "src/store/profile/profile_slice";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReusableModal from "src/components/shared/PhotoChangeModal/PhotoChangeModal";
import useModalState from "src/hooks/useModal";
import api from "src/store/interceptors/api";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedIcon from "@mui/icons-material/Verified";
import FilePreview from "src/components/shared/FilePreview";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { VisuallyHiddenInput } from "src/components/shared/VisuallyHiddenInput";
import {
  acceptableProofOfId,
  businessFiles,
  businessOnboarding,
  collectedFor,
  companyFiles,
  formatAccountNumber,
  personalFiles,
  proofOfAddress,
  scannedFiles,
} from "src/utils/helpers";
import ScannFaceAndIdModal from "src/components/Registration/ScannFaceAndIdModal";
import LoadingIndicator from "src/components/shared/LoadingIndicator";
import CameraAccessModal from "src/components/shared/CameraAccessModal/CameraAccessModal";
import i18next from "i18next";
import { storeUser } from "src/store/persist/persist";
import UserBankingDetails from "src/components/Users/UserDetails/UserBankingDetails";

const defaultTheme = createTheme();

export default function Home() {
  const dispatch = useAppDispatch();
  const { userProfile } = useAppSelector((state) => state.userProfile);
  const userProfileObject: any = { ...userProfile };
  const [isLoading, setIsLoading] = useState(true);
  const [hasCamera, setHasCamera] = useState<boolean | null>(null);
  const [showCameraAccessModal, setShowCameraAccessModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>({
    frontID: null,
    idBack: null,
    passport: null,
    personalPhoto: null,
    aml: null,
    companyRegistration: null,
    businessLicence: null,
    businessAddress: null,
    sof: null,
    ubo: null,
    tin: null,
    scanned_face_photo: null,
    scanned_id_photo: null,
    scanned_complete_photo: null,
  });
  const [openScannModal, setOpenScannModal] = useState(false);
  const { open, handleOpen, handleClose, modalType } = useModalState();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleCloseScannModal = () => {
    setOpenScannModal(false);
  };
  const handleOpenCameraAccessModal = () => {
    setShowCameraAccessModal(true);
  };
  const handleCloseCameraAccessModal = () => {
    setShowCameraAccessModal(false);
  };
  const checkCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      setOpenScannModal(true);
    } catch (error) {
      handleOpenCameraAccessModal();
    }
  };

  const checkIfAccountDataComplete = () => {
    const isSubmitEnabled: boolean =
      userProfile &&
      userProfile.personal_photo_file &&
      userProfile.scanned_complete_photo &&
      userProfile.scanned_face_photo &&
      userProfile.scanned_id_photo &&
      (userProfile.passport_file ||
        (userProfile.id_card_back_file && userProfile.id_card_front_file)) &&
      userProfile.email &&
      userProfile.first_name &&
      userProfile.last_name &&
      userProfile.country;

    if (userProfile?.account_type && userProfile.account_type === "business") {
      const isSubmitEnabledBusiness: boolean =
        userProfile.business_aml_id &&
        userProfile.business_company_registration_id &&
        userProfile.business_licence_id &&
        userProfile.business_tin_id;
      if (isSubmitEnabled && isSubmitEnabledBusiness) return true;
      else return false;
    }
    return isSubmitEnabled;
  };
  const handleFileRemove = (id: number, kycItem: any) => {
    return async () => {
      const res = await profileService.removeVerificationPhoto(id);
      if (res && res.data) {
        setSelectedFiles((prev: any) => ({
          ...prev,
          [kycItem.file]: null,
        }));
        dispatch(updateUser(res.data));
      }
    };
  };

  const handleRemoveScannedFiled = () => {
    const ids: any = [];
    scannedFiles.forEach((item: any) => {
      if (userProfileObject && userProfileObject[item.id]) {
        ids.push({ id: userProfileObject[item.id].id, kycItem: item });
      }
    });

    ids.forEach(async (item: any) => {
      const res = await profileService.removeVerificationPhoto(item.id);
      if (res && res.data) {
        setSelectedFiles((prev: any) => ({
          ...prev,
          [item.kycItem.file]: null,
        }));
        dispatch(updateUser(res.data));
      }
    });
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    kycItem: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        setSelectedFiles((prev: any) => ({
          ...prev,
          [kycItem.file]: { file: file, preview: URL.createObjectURL(file) },
        }));
        let res = await profileService.kycUploadFile(
          userProfile?.id,
          file,
          kycItem.file
        );
        if (res && res.data) dispatch(updateUser(res.data));
      }
    }
  };

  const handleVerficiationRequest = async () => {
    if (userProfile && userProfile.id) {
      let valuesForBe: any = {};
      valuesForBe.verification_requested = true;
      valuesForBe.id = userProfile.id ?? null;
      dispatch(updateUserProfile(valuesForBe));
    }
  };
  const getUserProfile = async () => {
    if (!isLoading) setIsLoading(true);
    try {
      const response = await api.get(`me`);
      setIsLoading(false);
      if (response && response.data && response.data.data) {
        dispatch(updateUser(response.data.data));
      }
    } catch (exception) {
      //setError("Error loading profile info, please login again!");
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  // ------- handle clicks start
  const handlePhotoClick = (ref: any, hasImage: boolean, modalType: string) => {
    if (hasImage) {
      // Logic to open the modal with specific content or actions based on modalType
      handleOpenModal(modalType);
    } else {
      ref.current?.click();
    }
  };

  const handleOpenModal = (modalType: any) => {
    handleOpen(modalType); // Open the modal with a specific type
  };

  // ------- handle clicks end

  const removeProfilePhotoHandler = (id?: number, photoType?: string) => {
    return async () => {
      if (photoType && photoType === "profilePhoto") {
        const res = await dispatch(removeProfilePhoto()).unwrap();
        await dispatch(updateUser(res.data));
        await storeUser("victorumuser", JSON.stringify(res.data));
      } else {
        const res = await profileService.removeVerificationPhoto(id);
        dispatch(updateUser(res?.data));
      }
    };
  };

  const handleCreateAnAccountClick = (event: any) => {
    navigate("/edit-profile");
  };

  const handleEditBusiness = (event: any) => {
    navigate("/edit-business");
  };

  // ------  handle file changes start
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string, // New parameter to specify the type
    additionalData?: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        switch (type) {
          case "updateProfilePhoto":
            dispatch(updateUserProfilePhoto({ ...additionalData, file }));
            break;
        }
      }
    }
  };

  const handleUploadScannedFile = (
    facePhoto: any,
    idPhoto: any,
    completePhoto: any
  ) => {
    if (facePhoto && idPhoto && completePhoto) {
      handleCloseScannModal();

      const files = [
        { file: facePhoto, id: "scanned_face_photo" },
        { file: idPhoto, id: "scanned_id_photo" },
        { file: completePhoto, id: "scanned_complete_photo" },
      ];

      setSelectedFiles((prev: any) => ({
        ...prev,
        scanned_face_photo: {
          file: facePhoto,
          preview: URL.createObjectURL(facePhoto),
        },
        scanned_id_photo: {
          file: idPhoto,
          preview: URL.createObjectURL(idPhoto),
        },
        scanned_complete_photo: {
          file: completePhoto,
          preview: URL.createObjectURL(completePhoto),
        },
      }));
      files.forEach(async (item) => {
        let res = await profileService.kycUploadFile(
          userProfile?.id,
          item.file,
          item.id
        );
        if (res && res.data) dispatch(updateUser(res.data));
      });
    }
  };

  const handleImageChange = (
    ref: React.RefObject<HTMLInputElement>,
    onClose: () => void
  ) => {
    return (_event: React.MouseEvent) => {
      ref.current?.click();
      onClose();
    };
  };

  const getUserProfileAbbreviatedName = (userProfile: any) => {
    if (!userProfile) return "";

    if (userProfile.account_type === "business" && userProfile.company_name) {
      return userProfile.company_name.substring(0, 2)?.toUpperCase() || "";
    }

    if (userProfile.first_name && userProfile.last_name) {
      return (
        userProfile.first_name.substring(0, 1)?.toUpperCase() +
          userProfile.last_name.substring(0, 1)?.toUpperCase() || ""
      );
    }

    return "";
  };
  // ------- handle file changes end
  useEffect(() => {
    if (userProfile) {
      dispatch(updateUser(userProfile));
    }
  }, [userProfile, dispatch]);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      setHasCamera(false);
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          const cameraDetected = devices.some(function (device) {
            return device.kind === "videoinput";
          });
          setHasCamera(cameraDetected);
        })
        .catch(function (err) {
          setHasCamera(false);
        });
    }
  }, []);

  return (
    <div>
      <Nav step={0} isStepperVisible={false} isProfileSetVisible={true} />
      <div className="content-container-profile main-color">
        <ThemeProvider theme={defaultTheme}>
          {!userProfile && isLoading ? (
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* {userProfile && <BalanceCard user={user} />} */}

                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>
                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    {i18next.t("Your details")}
                  </Typography>
                </div>

                <LoadingIndicator />
              </Box>
            </Container>
          ) : (
            <Container component="main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* {userProfile && <BalanceCard user={user} />} */}
                <Box sx={{ flex: 1, marginBottom: 2 }}>
                  <Typography
                    className="mb-1rem  d-flex jc-start FontSize1275"
                    component="h1"
                    variant="h5"
                    sx={{
                      justifyContent: "flex-end",
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                    }}
                  >
                    {i18next.t("Account number")}:{" "}
                    {userProfile && userProfile.id
                      ? formatAccountNumber(userProfile.id)
                      : ""}
                  </Typography>
                </Box>
                <div className="d-flex ai-center mb-3-rem">
                  <p
                    className="back-button-icon circle-around-text-smaller helvetica mr-1-rem cp"
                    onClick={handleNavigate}
                  >
                    <ArrowBackIcon className="muiIcons" />
                  </p>
                  <Typography
                    className="your-details-text d-flex jc-start pageTitleFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    {i18next.t("Your details")}
                  </Typography>
                </div>

                {/* image start */}
                <div className="d-flex ai-center">
                  {userProfile?.image?.file_url ? (
                    <Avatar
                      src={userProfile.image?.file_url}
                      alt={`${userProfile.first_name} ${userProfile.last_name}`}
                      sx={{ width: "5rem", height: "5rem" }}
                    />
                  ) : (
                    <p className="mr-8 circle-around-text-big helvetica big-text">
                      {getUserProfileAbbreviatedName(userProfile)}
                    </p>
                  )}
                  <Typography
                    className="mb-1rem d-flex jc-start underline registration0875RemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",

                      textAlign: "center",
                      marginLeft: "1rem",
                    }}
                    onClick={() =>
                      handlePhotoClick(
                        fileInputRef,
                        userProfile?.image?.file_url,
                        "fileInputRef"
                      )
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(event) =>
                        handleFileChange(event, "updateProfilePhoto", {
                          userData: { ...userProfile },
                        })
                      }
                    />
                    {userProfile?.image?.file_url
                      ? i18next.t("Edit account photo")
                      : i18next.t("Add account photo")}
                  </Typography>
                  <div>
                    <ReusableModal
                      open={open && modalType === "fileInputRef"}
                      onClose={handleClose}
                      onPhotoChange={handleImageChange(
                        fileInputRef,
                        handleClose
                      )}
                      onPhotoRemove={removeProfilePhotoHandler(
                        userProfile?.image?.id,
                        "profilePhoto"
                      )}
                    />
                  </div>
                </div>

                {/* image end */}
                <Box sx={{ mt: 2 }}>
                  {userProfile && userProfile.account_type === "business" && (
                    <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem mt-16">
                      <Typography
                        className="d-flex jc-start registration1375RemFontSize"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontWeight: "600",
                        }}
                      >
                        {i18next.t("Business information")}
                      </Typography>

                      <Button
                        variant="contained"
                        onClick={handleEditBusiness}
                        className="btnsLoginHeight oneRemFontSize"
                        sx={{
                          color: "#000000",
                          backgroundColor: "transparent",
                          borderRadius: "24px",
                          boxShadow: "none",
                          border: "1px solid #000000",
                          fontWeight: 400,
                          textTransform: "none",
                          fontFamily: "Helvetica, sans-serif",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                      >
                        {userProfile?.companies?.length > 0
                          ? i18next.t("Edit business details")
                          : i18next.t("Add business details")}
                      </Button>
                    </div>
                  )}
                  {userProfile &&
                    userProfile.account_type === "business" &&
                    userProfile.companies &&
                    userProfile.companies.length > 0 && (
                      <div className="personal-box">
                        <div className="one-flex-line">
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Legal name")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0]?.name ?? ""}
                            </Typography>
                          </div>

                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Registration number")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].registration_number}
                            </Typography>
                          </div>
                        </div>

                        <div className="one-flex-line mt-16">
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Date of incorporation")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0]?.incorporation_date}
                            </Typography>
                          </div>

                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Phone")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].country_code ?? ""}{" "}
                              {userProfile?.companies[0].phone ?? ""}
                            </Typography>
                          </div>
                        </div>

                        <div className="one-flex-line mt-16">
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Country")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].country ?? ""}
                            </Typography>
                          </div>
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("City")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].city ?? ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="one-flex-line mt-16">
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Address")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].address ?? ""}
                            </Typography>
                          </div>
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Postal Code")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].postal_code ?? ""}
                            </Typography>
                          </div>
                        </div>
                        <div className="one-flex-line mt-16">
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Web")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].website ? (
                                <a
                                  rel="noreferrer"
                                  href={userProfile?.companies[0].website}
                                  target="_blank"
                                >
                                  {userProfile?.companies[0].website}
                                </a>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </div>
                          <div className="desc-name-box">
                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {i18next.t("Number of employees")}
                            </Typography>

                            <Typography
                              className="mb-1rem  d-flex jc-start"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontSize: "0.875rem",
                                fontWeight: ".875rem",
                                color: "#454745",
                              }}
                            >
                              {userProfile?.companies[0].number_of_employees ??
                                ""}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}

                  {userProfile && userProfile.account_type === "business" && (
                    <UserBankingDetails
                      user={userProfile}
                      loggedUser={userProfile?.role ?? ""}
                      path="user"
                    />
                  )}
                  <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem mt-16">
                    <Typography
                      className="d-flex jc-start registration1375RemFontSize"
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Helvetica, sans-serif",
                        fontWeight: "600",
                      }}
                    >
                      {i18next.t("Personal information")}
                    </Typography>
                    {userProfile && userProfile.role !== "Employee" && (
                      <Button
                        variant="contained"
                        className="oneRemFontSize btnsLoginHeight"
                        onClick={handleCreateAnAccountClick}
                        sx={{
                          color: "#000000",
                          backgroundColor: "transparent",
                          borderRadius: "24px",
                          // height: "48px",
                          boxShadow: "none",
                          border: "1px solid #000000",
                          fontWeight: 400,
                          textTransform: "none",
                          fontFamily: "Helvetica, sans-serif",
                          "&:hover": {
                            backgroundColor: "#2A5182",
                            color: "white",
                          },
                        }}
                      >
                        {i18next.t("Edit personal details")}
                      </Button>
                    )}
                  </div>

                  <div className="personal-box">
                    <div className="one-flex-line">
                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Full legal first and middle names")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.first_name}
                        </Typography>
                      </div>

                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Full legal last name(s)")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.last_name}
                        </Typography>
                      </div>
                    </div>

                    <div className="one-flex-line mt-16">
                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Date of birth")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.birthday}
                        </Typography>
                      </div>

                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Phone")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.country_code ?? ""}{" "}
                          {userProfile?.phone ?? ""}
                        </Typography>
                      </div>
                    </div>

                    <div className="one-flex-line mt-16">
                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Email")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.email}
                        </Typography>
                      </div>
                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Account Verified")}{" "}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          <div className={"d-flex ai-center"}>
                            {userProfile && userProfile.verification_approved
                              ? i18next.t("Verified")
                              : i18next.t("Not verified")}

                            {userProfile &&
                            userProfile.verification_approved ? (
                              <VerifiedIcon style={{ color: "#00c943" }} />
                            ) : (
                              <GppBadIcon style={{ color: "#c90014" }} />
                            )}
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Typography
                    className="mb-1rem  d-flex jc-start registration1375RemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      marginTop: "2rem",
                    }}
                  >
                    {i18next.t("Personal address")}
                  </Typography>

                  <div className="personal-box mb-big">
                    <div className="one-flex-line">
                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Address")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.address
                            ? userProfile.address
                            : i18next.t("No address")}
                        </Typography>
                      </div>

                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("City")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.city}
                        </Typography>
                      </div>
                    </div>

                    <div className="one-flex-line mt-16">
                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Postal Code")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.postal_code}
                        </Typography>
                      </div>

                      <div className="desc-name-box">
                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {i18next.t("Country")}
                        </Typography>

                        <Typography
                          className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile?.country}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {userProfile && !userProfile.verification_approved && (
                    <div>
                      <Typography
                        className="mb-1rem  d-flex jc-start"
                        component="h1"
                        variant="h5"
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "1.375rem",
                          fontWeight: "600",
                          marginTop: "2rem",
                        }}
                      >
                        {i18next.t("Account status")}
                      </Typography>

                      <div style={{ padding: 40 }}>
                        <Typography
                          component="h1"
                          variant="h5"
                          sx={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: ".875rem",
                            color: "#454745",
                          }}
                        >
                          {userProfile.verification_requested
                            ? i18next.t(
                                "Account verification is in review, VicPay platform is reviewing Your account and will update the verification status once the review is completed."
                              )
                            : i18next.t(
                                "Please complete your profile details in order to be approved by VicPay platform. Make sure all fields and all files are added, after that you will be listed for review. By uploading files you give VicPay platform permission to review them, every data on the picture will be checked and compared with entered profile informations."
                              )}
                        </Typography>

                        {checkIfAccountDataComplete() && (
                          <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            {!userProfile.verification_requested && (
                              <Button
                                variant="contained"
                                onClick={handleVerficiationRequest}
                                sx={{
                                  color: "#fff",
                                  borderRadius: "24px",
                                  height: "2rem",
                                  boxShadow: "none",
                                  border: "1px solid #000000",
                                  fontWeight: 400,
                                  textTransform: "none",
                                  fontFamily: "Helvetica, sans-serif",
                                  fontSize: "0.875rem",
                                  "&:hover": {
                                    backgroundColor: "#2A5182",
                                    color: "white",
                                  },
                                }}
                              >
                                {i18next.t("Request verification")}{" "}
                                <VerifiedIcon style={{ color: "#ffffff" }} />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Verification start */}
                  <Typography
                    className="mb-1rem  d-flex jc-start registration1375RemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      marginTop: "2rem",
                    }}
                  >
                    {i18next.t("Personal verification files")}
                  </Typography>

                  <div className="personal-box mb-big">
                    {personalFiles.map((kycPersonalItem, kycPersonalIndex) => (
                      <div
                        key={"kyc" + kycPersonalIndex}
                        className="one-flex-line"
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                      >
                        <div className="verification-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {i18next.t(kycPersonalItem.title)}{" "}
                            {kycPersonalItem.required ? "*" : ""}
                          </Typography>

                          {userProfile &&
                            !userProfile.verification_approved &&
                            !userProfile.verification_requested && (
                              <Button
                                sx={{
                                  marginBottom: 2,
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: "#2A5182",
                                    color: "white",
                                  },
                                }}
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                              >
                                {userProfileObject[kycPersonalItem.file] &&
                                userProfileObject[kycPersonalItem.file].file_url
                                  ? i18next.t("Change file")
                                  : i18next.t("Upload file")}
                                <VisuallyHiddenInput
                                  onChange={(e) =>
                                    handleFileUpload(e, kycPersonalItem)
                                  }
                                  type="file"
                                />
                              </Button>
                            )}

                          {userProfileObject[kycPersonalItem.file] &&
                            userProfileObject[kycPersonalItem.file].file_url &&
                            userProfile &&
                            !userProfile.verification_approved &&
                            !userProfile.verification_requested && (
                              <Button
                                sx={{
                                  marginLeft: 2,
                                  marginBottom: 2,
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: "#2A5182",
                                    color: "white",
                                  },
                                }}
                                component="label"
                                onClick={handleFileRemove(
                                  userProfileObject[kycPersonalItem.id],
                                  kycPersonalItem
                                )}
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<HighlightOffIcon />}
                              >
                                {i18next.t("Remove file")}
                              </Button>
                            )}

                          {selectedFiles &&
                          selectedFiles[kycPersonalItem.file] &&
                          selectedFiles[kycPersonalItem.file].preview ? (
                            <FilePreview
                              src={selectedFiles[kycPersonalItem.file].preview}
                              width="90%"
                              file={selectedFiles[kycPersonalItem.file].file}
                              alt={kycPersonalItem.title}
                            />
                          ) : userProfileObject[kycPersonalItem.file]
                              ?.file_url ? (
                            <FilePreview
                              src={
                                selectedFiles[kycPersonalItem.file]?.preview ??
                                userProfileObject[kycPersonalItem.file].file_url
                              }
                              width="90%"
                              file={
                                selectedFiles[kycPersonalItem.file]?.file ??
                                userProfileObject[kycPersonalItem.file]
                              }
                              alt={kycPersonalItem.title}
                            />
                          ) : (
                            <Typography
                              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: 400, // Adjusted this to a numerical value
                                color: "#454745",
                              }}
                            >
                              {i18next.t("No") +
                                " " +
                                i18next.t(kycPersonalItem.title)}
                            </Typography>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <Typography
                    className="mb-1rem  d-flex jc-start registration1375RemFontSize"
                    component="h1"
                    variant="h5"
                    sx={{
                      fontFamily: "Helvetica, sans-serif",
                      fontWeight: "600",
                      marginTop: "2rem",
                    }}
                  >
                    {i18next.t("Scanned personal verification files")}
                  </Typography>

                  <div className="personal-box mb-big">
                    {userProfile &&
                      !userProfile.verification_approved &&
                      !userProfile.verification_requested &&
                      hasCamera && (
                        <Button
                          sx={{
                            marginBottom: 2,
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#2A5182",
                              color: "white",
                            },
                          }}
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                        >
                          {scannedFiles.every(
                            (item) =>
                              userProfileObject[item.file] &&
                              userProfileObject[item.file].file_url
                          )
                            ? i18next.t("Change files")
                            : i18next.t("Upload files")}
                          <VisuallyHiddenInput
                            onClick={() => checkCameraPermission()}
                          />
                        </Button>
                      )}

                    {scannedFiles.every(
                      (item) =>
                        userProfileObject[item.file] &&
                        userProfileObject[item.file].file_url
                    ) &&
                      userProfile &&
                      !userProfile.verification_approved &&
                      !userProfile.verification_requested && (
                        <Button
                          sx={{
                            marginLeft: 2,
                            marginBottom: 2,
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#2A5182",
                              color: "white",
                            },
                          }}
                          component="label"
                          onClick={handleRemoveScannedFiled}
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<HighlightOffIcon />}
                        >
                          {i18next.t("Remove files")}
                        </Button>
                      )}
                    {scannedFiles.map((kycPersonalItem, kycPersonalIndex) => (
                      <div
                        key={"kyc" + kycPersonalIndex}
                        className="one-flex-line"
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                      >
                        <div className="verification-box">
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {i18next.t(kycPersonalItem.title)}{" "}
                            {kycPersonalItem.required ? "*" : ""}
                          </Typography>

                          {selectedFiles &&
                          selectedFiles[kycPersonalItem.file] &&
                          selectedFiles[kycPersonalItem.file].preview ? (
                            <FilePreview
                              src={selectedFiles[kycPersonalItem.file].preview}
                              width="90%"
                              file={selectedFiles[kycPersonalItem.file].file}
                              alt={kycPersonalItem.title}
                            />
                          ) : userProfileObject[kycPersonalItem.file]
                              ?.file_url ? (
                            <FilePreview
                              src={
                                selectedFiles[kycPersonalItem.file]?.preview ??
                                userProfileObject[kycPersonalItem.file].file_url
                              }
                              width="90%"
                              file={
                                selectedFiles[kycPersonalItem.file]?.file ??
                                userProfileObject[kycPersonalItem.file]
                              }
                              alt={kycPersonalItem.title}
                            />
                          ) : (
                            <Typography
                              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: 400,
                                color: "#454745",
                              }}
                            >
                              {i18next.t("No") +
                                " " +
                                i18next.t(kycPersonalItem.title)}
                            </Typography>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Verification end */}
                </Box>
              </Box>

              {openScannModal && (
                <ScannFaceAndIdModal
                  open={openScannModal}
                  handleClose={handleCloseScannModal}
                  handleUploadScannedFile={handleUploadScannedFile}
                />
              )}
              <CameraAccessModal
                open={showCameraAccessModal}
                handleClose={handleCloseCameraAccessModal}
              />
            </Container>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
