import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import CryptoImg from "../../../assets/images/cryptoImgDark.jpg";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import { ReactComponent as PhoneCallingRounded } from "../../../assets/images/phoneCallingRounded.svg";
import { ReactComponent as ShieldUser } from "../../../assets/images/shield-user.svg";
import { ReactComponent as ShieldBlue } from "../../../assets/images/shield-blue.svg";

const defaultTheme = createTheme();

const SafetyOfFunds: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  const items = [
    {
      icon: <ShieldUser />,
      subTitle: i18next.t("Secure Account Protection"),
      desc: i18next.t(
        "At VicPay your account balance is protected by very strict security measures against any fraudulent access"
      ),
    },
    {
      icon: <ShieldBlue />,
      subTitle: i18next.t("Continuous Security Enhancements at VicPay"),
      desc: i18next.t(
        "Further we are continuously improving the technical security measures in addition to your login details and our system monitors in real-time all activities and only makes changes after you have personally verified them based on some security questions and/or security codes"
      ),
    },
    {
      icon: <PhoneCallingRounded />,
      subTitle: i18next.t("Immediate Support"),
      desc: i18next.t(
        "In case of doubt our dedicated security team gets in touch with you via video chat in order to verify unusual activities"
      ),
      desc2: i18next.t(
        "In case of any questions regarding account and fund security, please do not hesitate to get in touch with our dedicated security team. Just drop a message to"
      ),
      email: "info@vicpayments.com",
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Safety of funds")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "A safe harbour for your fiat and crypto currencies"
                    )}
                    subtitle={i18next.t(
                      "VicPay takes a proactive approach to client protection - far beyond the required regulation guidelines"
                    )}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box sx={{ marginBottom: 5 }}>
                <ImageWithText2
                  backgroundImage={CryptoImg}
                  title={i18next.t("It´s actually not so difficult")}
                  text={i18next.t(
                    "stay transparent, professional and reliable - and clients will always return"
                  )}
                />
              </Box>
              <Box sx={{ padding: "4rem 0 4rem 0" }}>
                <TitleTextOverlayImg
                  title={i18next.t("Secure Funding with VicPay")}
                  text={i18next.t(
                    "Once you have funded your VicPay account, you can be sure that it is secured and protected, no matter under which market circumstances"
                  )}
                  direction="left"
                />
              </Box>
              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "Our team attaches the highest degree of importance to the optimal protection and security of our clients using the latest system technology supported by AI systems"
                  )}
                />
              </Box>
              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default SafetyOfFunds;
