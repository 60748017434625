import { Box, Typography } from "@mui/material";
import "./ImagesWithText.scss";
import i18next from "i18next";

function ImagesWithText() {
  return (
    <Box className="logoContainer">
      <Box className="logoPayVvidget2 logoPayVvidget3">
        <Box>
          <Typography className="textsLandingPageHeading">
            {i18next.t("Our partner")}
          </Typography>
          <Typography
            paragraph
            className="textsLandingPage"
            sx={{ color: "#fff !important" }}
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                "Do you only want one partner for digital transfers, cryptocurrencies, and currency exchange Security and data protection are your top priorities"
              ),
            }}
          />
        </Box>
      </Box>
      <Box className="backgroundBlankContainer payBgContainer">
        <Typography
          className="partnerDesc"
          dangerouslySetInnerHTML={{
            __html: i18next.t(
              "Our partner and we are building a more efficient global payment ecosystem, by bridging the gaps between fiat _digital currencies"
            ),
          }}
        />
      </Box>
    </Box>
  );
}

export default ImagesWithText;
