import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import { useNavigate } from "react-router-dom";
import LogoTextVvidget from "../components/LogoTextVvidget/LogoTextVvidget";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";

const defaultTheme = createTheme();

const MediaPress: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact-us");
  };
  const items = [
    {
      subTitle: i18next.t("For Journalists and Media Representatives"),
      desc: i18next.t(
        "Are you a journalist, media representative, blogger or influencer? Do you work for a TV or radio station or run a podcast"
      ),
    },
    {
      subTitle: i18next.t("Reporting on VicPay and the Victorum Group"),
      desc: i18next.t(
        "Do you want to report on VicPay, the Victorum Group, our technology or our CEO? Do you need information, images or are you interested in an interview"
      ),
      desc2: i18next.t("Then you've come to the right place"),
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Media & press")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "We are giving our insights - as much as we can"
                    )}
                    subtitle={i18next.t(
                      "Are you a journalist, media representative, blogger or influencer? Do you work for a TV or radio station or run a podcast"
                    )}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={navigateToContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box>
                <TitleIconSubTitleDesc
                  title={i18next.t("Media Inquiries and Information Requests")}
                  items={items}
                />
              </Box>

              <Box sx={{ marginBottom: "4rem" }}>
                <LogoTextVvidget2
                  text={i18next.t(
                    "Whether you have an individual interview request or high-resolution video material, please do not hesitate to send your enquiry to our communications department at"
                  )}
                  email={"press@vicpayments.com"}
                  text3={i18next.t(
                    "Please understand that our contacts are only available for enquiries from media representatives"
                  )}
                />
              </Box>

              <Typography
                variant="h3"
                sx={{ marginBottom: "1rem" }}
                className="titleIconSubTitleDescTitle"
              >
                {i18next.t("General Inquiries and Support")}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="titleIconSubTitleDescDesc"
              >
                {i18next.t(
                  "For other enquiries, such as about our products, career opportunities or other areas of the company, simply"
                )}{" "}
                <span
                  className="linksHover"
                  onClick={() => navigate("/contact-us")}
                >
                  {i18next.t("click here")}
                </span>
              </Typography>
              <Typography
                variant="body1"
                paragraph
                className="titleIconSubTitleDescDesc"
              >
                {i18next.t(
                  "If you are not sure which department is the right one, simply select +1 604-260-0738 and we will be happy to help you"
                )}
              </Typography>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default MediaPress;
