import { Box, Button, Divider, Typography } from "@mui/material";
import i18next from "i18next";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { businessOnboarding } from "src/utils/helpers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { VisuallyHiddenInput } from "src/components/shared/VisuallyHiddenInput";
import profileService from "src/store/profile/profile_service";
import FilePreview from "src/components/shared/FilePreview";

interface Props {
  user: any;
  loggedUser: string;
  path: string;
}

function UserBankingDetails({ user, loggedUser, path }: Props) {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [userObject, setUserObject] = useState<any>(user);

  const handleFileRemove = (id: number, kycItem: any) => {
    return async () => {
      let idUser = userId;
      if (!idUser) idUser = userObject && userObject.id ? userObject.id : null;
      if (idUser) {
        const res = await profileService.adminRemoveVerificationPhoto(
          idUser,
          id
        );
        if (res && res.data) {
          setUserObject(res.data);
          setSelectedFiles((prev: any) => ({
            ...prev,
            [kycItem.field_file_name]: null,
          }));
        }
      }
    };
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    kycItem: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      let idUser = userId;
      if (!idUser) idUser = userObject && userObject.id ? userObject.id : null;
      if (idUser) {
        const file = event.target.files[0];
        setSelectedFiles((prev: any) => ({
          ...prev,
          [kycItem.field_file_name]: {
            file: file,
            preview: URL.createObjectURL(file),
          },
        }));
        const resResponse = await profileService.kycUploadFile(
          idUser,
          file,
          kycItem.field_file_name
        );
        if(resResponse && resResponse.data){
          setUserObject(resResponse.data);
        }
      }
    }
  };

  const renderEditButton = () => {
    if (loggedUser === "Employee" && userObject && userObject.role !== "Admin") {
      return true;
    } else if (loggedUser === "Employee" && userObject && userObject.role === "Admin") {
      return false;
    }
    return true;
  };

  const handleEditPersonal = (event: any) => {
    let idUser = userId;
    if (!idUser) idUser = userObject && userObject.id ? userObject.id : null;
    if (idUser) {
      if (path === "admin") {
        navigate(`/admin/users/${idUser}/edit-banking`, { state: { user } });
      } else {
        navigate(`/edit-banking`);
      }
    }
  };
  return (
    <div>
      <div>
        <div className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
          <Typography
            className="mb-1rem  d-flex jc-start pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              marginTop: "2rem",
            }}
          >
            {i18next.t("Business account")}
          </Typography>

          {renderEditButton() && (
            <Button
              variant="contained"
              onClick={handleEditPersonal}
              className="btnsLoginHeight oneRemFontSize"
              sx={{
                color: "#000000",
                backgroundColor: "transparent",
                borderRadius: "24px",
                boxShadow: "none",
                border: "1px solid #000000",
                fontWeight: 400,
                textTransform: "none",
                fontFamily: "Helvetica, sans-serif",
                "&:hover": {
                  backgroundColor: "#2A5182",
                  color: "white",
                },
              }}
            >
              {i18next.t("Edit")} {i18next.t("Business Account")}
            </Button>
          )}
        </div>
      </div>

      {businessOnboarding.map((sectionItem: any, sectionKey) => {
        return (
          <Box key={"businessOnboarding" + sectionKey}>
            <Box className="d-flex jusifyContentSpaceBetween ai-center mb-1rem">
              <Typography
                className="mb-1rem  d-flex jc-start pageTitleFontSize"
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  fontWeight: "600",
                  marginTop: "2rem",
                }}
              >
                {i18next.t(sectionItem.name)}
              </Typography>
            </Box>

            <Box className="personal-box">
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: sectionItem.gridTemplateColumns,
                }}
              >
                {sectionItem.type === "section" &&
                  sectionItem.fields &&
                  sectionItem.fields.length > 0 &&
                  sectionItem.fields.map((fieldItem: any, fieldKey: any) => {
                    if (fieldItem.type !== "file") {
                      return (
                        <Box
                          key={
                            "sectionItem" + sectionKey + "fieldItem" + fieldKey
                          }
                        >
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "bold",
                              color: "#454745",
                            }}
                          >
                            {i18next.t(fieldItem.label)}
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "normal",
                              color: "#454745",
                            }}
                          >
                            {userObject[fieldItem.field_name] ?? " - "}
                          </Typography>
                        </Box>
                      );
                    } else {
                      return (
                        <Box
                          key={
                            "sectionItem" + sectionKey + "fieldItem" + fieldKey
                          }
                        >
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "bold",
                              color: "#454745",
                            }}
                          >
                            {i18next.t(fieldItem.label)}
                          </Typography>
                          <Button
                            sx={{
                              marginBottom: 2,
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            className="btnsLoginHeight oneRemFontSize"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon className="muiIcons" />}
                          >
                            {userObject[fieldItem.field_file_name] &&
                            userObject[fieldItem.field_file_name].file_url
                              ? i18next.t("Change file")
                              : i18next.t("Upload file")}
                            <VisuallyHiddenInput
                              onChange={(e) => {
                                handleFileUpload(e, fieldItem);
                              }}
                              type="file"
                            />
                          </Button>

                          {userObject[fieldItem.field_file_name] &&
                            userObject[fieldItem.field_file_name].file_url && (
                              <Button
                                className="btnsLoginHeight oneRemFontSize"
                                sx={{
                                  marginLeft: 2,
                                  marginBottom: 2,
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor: "#2A5182",
                                    color: "white",
                                  },
                                }}
                                component="label"
                                onClick={handleFileRemove(
                                  userObject[fieldItem.field_name],
                                  fieldItem
                                )}
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={
                                  <HighlightOffIcon className="muiIcons" />
                                }
                              >
                                {i18next.t("Remove file")}
                              </Button>
                            )}

                          {selectedFiles &&
                          selectedFiles[fieldItem.field_file_name] &&
                          selectedFiles[fieldItem.field_file_name].preview ? (
                            <FilePreview
                              src={
                                selectedFiles[fieldItem.field_file_name].preview
                              }
                              width="90%"
                              file={
                                selectedFiles[fieldItem.field_file_name].file
                              }
                              alt={fieldItem.label}
                            />
                          ) : userObject[fieldItem.field_file_name]?.file_url ? (
                            <FilePreview
                              src={userObject[fieldItem.field_file_name].file_url}
                              width="90%"
                              file={userObject[fieldItem.field_file_name]}
                              alt={fieldItem.label}
                            />
                          ) : (
                            <Typography
                              className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                              component="h1"
                              variant="h5"
                              sx={{
                                fontFamily: "Helvetica, sans-serif",
                                fontWeight: 400, // Adjusted this to a numerical value
                                color: "#454745",
                              }}
                            >
                              No file added!
                            </Typography>
                          )}
                          <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />
                        </Box>
                      );
                    }
                  })}

                {sectionItem.type === "json" &&
                  userObject &&
                  userObject[sectionItem.field_name] &&
                  userObject[sectionItem.field_name].director &&
                  userObject[sectionItem.field_name].director.map(
                    (fieldItem: any, fieldKey: any) => {
                      return (
                        <Box
                          key={
                            "sectionItemDirector" +
                            fieldKey +
                            "fieldItem" +
                            fieldItem.id
                          }
                        >
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "bold",
                              color: "#454745",
                            }}
                          >
                            {i18next.t("Director")} {fieldKey + 1}
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "normal",
                              color: "#454745",
                            }}
                          >
                            {i18next.t("Acceptable proof of ID:")}{" "}
                            {i18next.t(fieldItem.proofOfId.title)}
                          </Typography>
                          <FilePreview
                            src={fieldItem.proofOfId.file_path}
                            forcePreview={true}
                            width="90%"
                            alt={
                              i18next.t("Acceptable proof of ID:") +
                              " " +
                              i18next.t(fieldItem.proofOfId.title)
                            }
                          />
                          <Divider sx={{ mb: 3, mt: 3 }} />

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "normal",
                              color: "#454745",
                            }}
                          >
                            {i18next.t("Proof of address:")}{" "}
                            {i18next.t(fieldItem.proofOfAddress.title)}
                          </Typography>
                          <FilePreview
                            src={fieldItem.proofOfAddress.file_path}
                            forcePreview={true}
                            width="90%"
                            alt={
                              i18next.t("Proof of address:") +
                              " " +
                              i18next.t(fieldItem.proofOfAddress.title)
                            }
                          />

                          <Divider sx={{ background: "#ccc", mb: 5, mt: 5 }} />
                        </Box>
                      );
                    }
                  )}

                {sectionItem.type === "json" &&
                  userObject &&
                  userObject[sectionItem.field_name] &&
                  userObject[sectionItem.field_name].shareholder &&
                  userObject[sectionItem.field_name].shareholder.map(
                    (fieldItem: any, fieldKey: any) => {
                      return (
                        <Box
                          key={
                            "sectionItemShareholder" +
                            fieldKey +
                            "fieldItem" +
                            fieldItem.id
                          }
                        >
                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "bold",
                              color: "#454745",
                            }}
                          >
                            {i18next.t("Shareholder")} {fieldKey + 1}
                          </Typography>

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "normal",
                              color: "#454745",
                            }}
                          >
                            {i18next.t("Acceptable proof of ID:")}{" "}
                            {i18next.t(fieldItem.proofOfId.title)}
                          </Typography>
                          <FilePreview
                            src={fieldItem.proofOfId.file_path}
                            forcePreview={true}
                            width="90%"
                            alt={
                              i18next.t("Acceptable proof of ID:") +
                              " " +
                              i18next.t(fieldItem.proofOfId.title)
                            }
                          />
                          <Divider sx={{ mb: 3, mt: 3 }} />

                          <Typography
                            className="mb-1rem  d-flex jc-start registration0875RemFontSize"
                            component="h1"
                            variant="h5"
                            sx={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "normal",
                              color: "#454745",
                            }}
                          >
                            {i18next.t("Proof of address:")}{" "}
                            {i18next.t(fieldItem.proofOfAddress.title)}
                          </Typography>
                          <FilePreview
                            src={fieldItem.proofOfAddress.file_path}
                            forcePreview={true}
                            width="90%"
                            alt={
                              i18next.t("Proof of address:") +
                              " " +
                              i18next.t(fieldItem.proofOfAddress.title)
                            }
                          />

                          <Divider sx={{ background: "#ccc", mb: 5, mt: 5 }} />
                        </Box>
                      );
                    }
                  )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </div>
  );
}

export default UserBankingDetails;
