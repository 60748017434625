import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";

const currencies = ["EUR", "USD", "GBP"];

interface Props {
  setActiveStep: (value: number) => void;
  formik: any;
  userId: any;
  handleTextFieldChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleSelectChange: (e: SelectChangeEvent<any>) => void;
  handleRadioChange: React.ChangeEventHandler<HTMLInputElement>;
  isSubmitting: boolean;
}

function AccountConfiguration({
  setActiveStep,
  formik,
  handleTextFieldChange,
  handleRadioChange,
  handleSelectChange,
  isSubmitting,
}: Props) {
  return (
    <Box className="settingsMain">
      <Box sx={{ paddingBottom: 5 }}>
        <Typography
          className="your-details-text d-flex jc-start"
          component="h1"
          variant="h4"
          sx={{
            fontFamily: "Helvetica, sans-serif",
            fontWeight: "600",
            textAlign: "start",
          }}
        >
          {i18next.t("Business account")}
        </Typography>

        <Typography className="labelsLocalIban">
          {i18next.t("Please indicate onboarding requirements")}
        </Typography>
      </Box>
      <Box className="row-section">
        <Box className="box">
          <Typography
            className="your-details-text d-flex jc-start"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              textAlign: "start",
            }}
          >
            {i18next.t("Money in")}
          </Typography>

          <Box className="form-box">
            <Box>
              <Box className="radio-group">
                <InputLabel
                  id="currency"
                  className="oneRemFontSize"
                  style={{
                    marginBottom: "8px",
                    marginTop: "8px",
                    color: "#000000",
                  }}
                >
                  {i18next.t("IBAN needs")}
                </InputLabel>
                <RadioGroup
                  className="radioGroupButton"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="iban_needs"
                  value={formik.values.iban_needs}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="LU"
                    control={<Radio />}
                    label="LU"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "14px",

                        "@media (max-width:600px)": {
                          fontSize: "14px !important",
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value="NL"
                    control={<Radio />}
                    label="NL"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "14px",

                        "@media (max-width:600px)": {
                          fontSize: "14px !important",
                        },
                      },
                    }}
                  />

                  <FormControlLabel
                    value="GB IBAN"
                    control={<Radio />}
                    label="GB IBAN"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "14px",

                        "@media (max-width:600px)": {
                          fontSize: "14px !important",
                        },
                      },
                    }}
                  />
                </RadioGroup>
              </Box>
              {formik.touched.iban_needs && formik.errors.iban_needs && (
                <Typography className="amountErrorForm">
                  {formik.errors.iban_needs.toString()}
                </Typography>
              )}
            </Box>
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <InputLabel
                id="pay_in_currency"
                className="oneRemFontSize"
                style={{
                  marginBottom: "8px",
                  marginTop: "8px",
                  color: "#000000",
                }}
              >
                {i18next.t("Pay-in currencies")}
              </InputLabel>
              <Select
                multiple={true}
                labelId="pay_in_currency"
                id="pay_in_currency"
                name="pay_in_currency"
                style={{ width: "100%" }}
                error={!!formik.errors.pay_in_currency}
                sx={{
                  borderRadius: "10px",
                  height: "48px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "@media (max-width: 600px)": {
                    height: "42px",
                    fontSize: "14px",
                  },
                }}
                value={formik.values.pay_in_currency}
                onChange={handleSelectChange}
              >
                {currencies.map((menuItem: any, menuIndex: any) => (
                  <MenuItem key={"currenct" + menuIndex} value={menuItem}>
                    {menuItem}
                  </MenuItem>
                ))}
              </Select>

              {formik.touched.pay_in_currency &&
                formik.errors.pay_in_currency && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors.pay_in_currency.toString()}
                  </Typography>
                )}
            </div>

            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Typography className="labelsLocalIban">
                {i18next.t("Volume of money in")}
              </Typography>
              <TextField
                style={{ width: "100%" }}
                error={!!formik.errors.volume_of_money_in}
                type="number"
                id="volume_of_money_in"
                value={formik.values.volume_of_money_in}
                name="volume_of_money_in"
                onChange={handleTextFieldChange}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "0px!important",
                  },
                }}
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    height: "48px",
                    "@media (max-width: 600px)": {
                      fontSize: "14px",
                      height: "42px",
                    },
                  },
                }}
              />

              {formik.touched.volume_of_money_in &&
                formik.errors.volume_of_money_in && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors.volume_of_money_in.toString()}
                  </Typography>
                )}
            </div>
          </Box>
        </Box>
        <Box className="box">
          <Typography
            className="your-details-text d-flex jc-start"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              textAlign: "start",
            }}
          >
            {i18next.t("Money out")}
          </Typography>

          <Box className="form-box">
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <InputLabel
                id="pay_out_currency"
                className="oneRemFontSize"
                style={{
                  marginBottom: "8px",
                  marginTop: "8px",
                  color: "#000000",
                }}
              >
                {i18next.t("Pay-out currencies")}
              </InputLabel>
              <Select
                multiple={true}
                error={!!formik.errors.pay_out_currency}
                labelId="pay_out_currency"
                id="pay_out_currency"
                name="pay_out_currency"
                style={{ width: "100%" }}
                sx={{
                  borderRadius: "10px",
                  height: "48px",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "16.5px 14px 16.5px 14px",
                    },
                  "@media (max-width: 600px)": {
                    height: "42px",
                    fontSize: "14px",
                  },
                }}
                value={formik.values.pay_out_currency}
                onChange={handleSelectChange}
              >
                {currencies.map((menuItem: any, menuIndex: any) => (
                  <MenuItem key={"currenct" + menuIndex} value={menuItem}>
                    {menuItem}
                  </MenuItem>
                ))}
              </Select>

              {formik.touched.pay_out_currency &&
                formik.errors.pay_out_currency && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors.pay_out_currency.toString()}
                  </Typography>
                )}
            </div>

            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Typography className="labelsLocalIban">
                {i18next.t("Volume of money out")}
              </Typography>
              <TextField
                error={!!formik.errors.volume_of_money_out}
                style={{ width: "100%" }}
                type="number"
                value={formik.values.volume_of_money_out}
                name="volume_of_money_out"
                onChange={handleTextFieldChange}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "0px!important",
                  },
                }}
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    height: "48px",
                    "@media (max-width: 600px)": {
                      fontSize: "14px",
                      height: "42px",
                    },
                  },
                }}
              />

              {formik.touched.volume_of_money_out &&
                formik.errors.volume_of_money_out && (
                  <Typography
                    className="amountErrorForm"
                    style={{ marginTop: 8 }}
                  >
                    {formik.errors.volume_of_money_out.toString()}
                  </Typography>
                )}
            </div>
          </Box>
        </Box>
      </Box>

      <div className="button-section">
        <Button
          type="submit"
          onClick={(e: any) => {
            formik.handleSubmit(e);
          }}
          disabled={formik.isSubmitting || isSubmitting}
          className="walletBtn"
        >
          {i18next.t("Continue")}
        </Button>
      </div>
    </Box>
  );
}

export default AccountConfiguration;
