// IconService.tsx
//import React from "react";
//import AddIcon from '@mui/icons-material/Add';
//import RemoveIcon from '@mui/icons-material/Remove';
type TransactionItem = {
  transaction_type: string;
};

export const getTransactionIcon = (item: TransactionItem) => {
  //const style = { width: "1rem", height: "1rem" };

  switch (item.transaction_type) {
    case "balance_out":
    case "m_transfer_out":
    case "p_transfer_out":
    case "p_transfer_auto_out":
    case "p_transfer_fee":
    case "p_conversion_out":
      return "-";
    case "crypto_transfer_out":
      return "- ";
    case "balance_in":
    case "balance_in_credit_card":
    case "balance_in_bank":
    case "balance_in_crypto":
    case "m_transfer_in":
    case "p_transfer_in":
    case "p_transfer_auto_in":
    case "p_conversion_in":
      return "+";
    default:
      return "";
  }
};
