import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "../components/News/News.scss";
import { useNavigate, useParams } from "react-router-dom";
import NewsJson from "../components/News/News.json";
import ScheduleIcon from "@mui/icons-material/Schedule";
import NewsDefault from "../../../assets/images/logoPay.png";
import { ReactComponent as BackIcon } from "../../../assets/images/backIcon.svg";
import i18next from "i18next";

const defaultTheme = createTheme();

const SingleNews: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { newsId } = useParams<{ newsId: string }>();
  const navigate = useNavigate();

  const processText = (text: string) => {
    const parts = text.split(/(\\n|\\b.*?\\b)/g);

    return parts.map((part, index) => {
      if (part === "\\n") {
        return <br key={index} />;
      } else if (part.startsWith("\\b") && part.endsWith("\\b")) {
        return (
          <span
            key={index}
            style={{
              fontWeight: "700",
              color: "#2A5182",
              fontFamily: "Montserrat",
            }}
          >
            {part.slice(2, -2)}
          </span>
        );
      } else {
        return part;
      }
    });
  };

  const singleNew = NewsJson.news.find(
    (article: any) => article.id.toString() === newsId
  );

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="margintTopPagesMainCont">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="singleNewsMainContainer">
              {singleNew ? (
                <Box>
                  <Box
                    onClick={handleNavigate}
                    sx={{
                      display: "flex",
                      gap: "8px",
                      cursor: "pointer",
                      width: "fit-content",
                      paddingTop: "32px",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <BackIcon />
                    <Typography
                      className="goBackText"
                      sx={{
                        "@media (max-width: 767px)": {
                          display: "none",
                        },
                      }}
                    >
                      {i18next.t("Go back")}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    paragraph
                    className="smallTitleNews"
                  >
                    {singleNew.smallTitle}
                  </Typography>

                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "1rem",
                    }}
                    className="singleNewsTitle"
                  >
                    {singleNew.title}
                  </Typography>
                  <Box className="singleNewsDateAndIconBox">
                    <ScheduleIcon
                      sx={{
                        minWidth: "16px",
                        minHeight: "16px",
                        maxWidth: "19px",
                        maxHeight: "19px",
                        color: "rgba(0,0,0)",
                        marginBottom: "16px",
                      }}
                    />
                    <Typography
                      variant="body1"
                      paragraph
                      className="dateHourTextsSingleNews"
                    >
                      {singleNew.date}, {singleNew.hour}
                    </Typography>
                  </Box>
                  <Box
                    className={
                      singleNew.image ? "" : "singleNewsImageDefaultBox"
                    }
                  >
                    <img
                      src={singleNew.image ?? NewsDefault}
                      className={
                        singleNew.image
                          ? "singleNewsImage"
                          : "singleNewsImageDefault"
                      }
                      alt="Single News"
                    />
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "3rem",
                    }}
                    className="subtitleSingleNews"
                  >
                    {singleNew.subtitle}
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    className="textsSingleNews"
                  >
                    {processText(singleNew.text)}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  paragraph
                  className="textsSingleNews"
                >
                  {i18next.t("News not found")}
                </Typography>
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default SingleNews;
