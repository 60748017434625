import { useState } from "react";
import "./FooterLanding.scss";
import { IconButton, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import menuItems from "../FooterItems.json";
import FooterMobileMenu from "../FooterMobileMenu/FooterMobileMenu";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Terms from "src/components/TermsAndCondition/Terms";
import { now } from "moment";
import img2 from "src/assets/images/img2.svg";
import img3 from "src/assets/images/img3.svg";
import i18next from "i18next";

const footerTitles: any = {
  companyItems: i18next.t("Company"),
  supportItems: i18next.t("Support"),
  servicesItems: i18next.t("Services"),
  legalAndFees: i18next.t("Legal & Fees"),
};
const FooterLanding = () => {
  const [openAgreement, setOpenAgreement] = useState<any>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const translatedMenuItemsCompany = menuItems.headerItems.companyItems.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );
  const translatedMenuSupport = menuItems.headerItems.supportItems.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );
  const translatedMenuServices = menuItems.headerItems.servicesItems.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );
  const translatedMenuItemsLegalFees = menuItems.headerItems.legalAndFees.map(
    (item) => ({
      text: i18next.t(item.text),
      route: item.route,
    })
  );

  const handleNavigationLinks = (path: string) => {
    if (path === "/terms-and-conditions") {
      setOpenAgreement(now());
    } else {
      navigate(path);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const smoothScrollTo = (path: any) => {
    if (path === "/terms-and-conditions") {
      setOpenAgreement(now());
    } else {
      navigate(path);
      /* todo implement smooth scroll where needed
      const element = document.getElementById(path);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
      */
    }
  };

  return (
    <div className="footer-container-main">
      <div className="footer-container">
        <div className="links-container">
          {Object.entries(menuItems.headerItems).map(([key, items]) => (
            <div key={"mainGroup" + key} className="single-links-box">
              <Typography className="footer-title">
                {footerTitles[key] ?? key}
              </Typography>
              {items.map((childItem: any, childKey: any) => {
                return (
                  <Typography
                    key={"childKey" + key + "-" + childKey}
                    className={`footer-text ${
                      location.pathname === childItem.route
                        ? "footer-textActive"
                        : ""
                    }`}
                    onClick={() => handleNavigationLinks(childItem.route)}
                  >
                    {i18next.t(childItem.text)}
                  </Typography>
                );
              })}
            </div>
          ))}
        </div>
        <div className="mobile-accordion">
          <FooterMobileMenu
            smoothScrollTo={smoothScrollTo}
            triggerText={i18next.t("Company")}
            menuItems={translatedMenuItemsCompany}
          />
          <FooterMobileMenu
            smoothScrollTo={smoothScrollTo}
            triggerText={i18next.t("Support")}
            menuItems={translatedMenuSupport}
          />
          <FooterMobileMenu
            smoothScrollTo={smoothScrollTo}
            triggerText={i18next.t("Services")}
            menuItems={translatedMenuServices}
          />
          <FooterMobileMenu
            smoothScrollTo={smoothScrollTo}
            triggerText={i18next.t("Legal & Fees")}
            menuItems={translatedMenuItemsLegalFees}
          />
        </div>
        {/* accordion end */}

        <div className="social-and-links-container">
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61565557462280"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                className="svg_icons"
                sx={{
                  color: "black",
                  transition: "color 0.3s",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#2a5182",
                  },
                }}
              />
            </a>

            <a
              href="https://www.instagram.com/vicpayments/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                className="svg_icons"
                sx={{
                  color: "black",
                  transition: "color 0.3s",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#2a5182",
                  },
                }}
              />
            </a>

            <a
              href="https://x.com/VicPayments"
              target="_blank"
              rel="noopener noreferrer"
            >
              <XIcon
                className="svg_icons"
                sx={{
                  color: "black",
                  transition: "color 0.3s",
                  cursor: "pointer",

                  "&:hover": {
                    color: "#2a5182",
                  },
                }}
              />
            </a>

            <a
              href="https://telegram.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon
                className="svg_icons"
                sx={{
                  color: "black",
                  transition: "color 0.3s",
                  cursor: "pointer",

                  "&:hover": {
                    color: "#2a5182",
                  },
                }}
              />
            </a>
          </div>
        </div>

        <div className="three-last-items-container-desc">
          <div className="languageSelectorAndTextDiv">
            <div className="left-last-text">
              <Typography className="small-footer-text-ad">
                Victorum Capital Services Limited, 2/3 48 West George Street,
                Glasgow, Scotland, G2 1BP
              </Typography>
            </div>
          </div>

          <div className="middle-images">
            {/*<img src={img2} height={"auto"} alt="Master" />
            {/* <img src={img3} height={"auto"} alt="Visa" /> */}
            {/* <Box className="welcomeToVPCardSmall"></Box> */}
          </div>

          <div className="right-up">
            <IconButton
              onClick={handleScrollToTop}
              aria-label="scroll back to top"
              sx={{
                backgroundColor: "transparent",
                color: "black",
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: 0,
                  transition: "all 0.2s !important",
                },
                borderRadius: "50%",
                boxShadow: 3,
                padding: 4,
                width: 48,
                height: 48,
              }}
            >
              <ArrowUpwardIcon className="svg_icons" />
            </IconButton>
          </div>
        </div>

        {/* three last items mobile start */}
        <div className="three-last-items-container-mob">
          <div className="languageSelectorAndTextDiv">
            <div className="left-last-text">
              <Typography className="small-footer-text-ad">
                Victorum Capital Services Limited, 2/3 48 West George Street,
                Glasgow, Scotland, G2 1BP
              </Typography>
            </div>
          </div>
          <div className="text-and-upward">
            <div className="middle-images">
              {/*<img width={"50px"} src={img2} height={"auto"} alt="Master" />
              {/* <img width={"60px"} src={img3} height={"auto"} alt="Visa" /> */}
              {/* <Box className="welcomeToVPCardSmall"></Box> */}
            </div>

            <div className="right-up">
              <IconButton
                onClick={handleScrollToTop}
                aria-label="scroll back to top"
                sx={{
                  backgroundColor: "transparent",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: 0,
                    transition: "all 0.2s !important",
                  },
                  borderRadius: "50%",
                  boxShadow: 3,
                  padding: 4,
                  width: 48,
                  height: 48,
                }}
              >
                <ArrowUpwardIcon className="svg_icons" />
              </IconButton>
            </div>
          </div>
        </div>
        <Terms
          margin={5}
          openForce={openAgreement}
          hideButton={true}
          hideCheckbox={true}
          onChecked={() => console.log("Checked")}
          type="customer-agreement-personal"
        />
      </div>
    </div>
  );
};

export default FooterLanding;
