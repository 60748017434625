import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import CurrencyNewIcon from "../../../assets/images/currencies-new.svg";
import MerchantNewIcon from "../../../assets/images/merchant-new.svg";
import LanguagesNewIcon from "../../../assets/images/languages-new.svg";
import UsersNewIcon from "../../../assets/images/users-new.svg";
import Icon24 from "../../../assets/images/24-new.svg";
import IconTextWidget2 from "../components/IconTextWidget2/IconTextWidget2";

const defaultTheme = createTheme();
const OpenAccount: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Open Account")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    subtitle={i18next.t(
                      "VicPay is the partner for anyone who has had enough of slow branch banks and high fees!"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create an account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <div className="icon-text-widgets-container">
                <IconTextWidget2
                  iconSrc={CurrencyNewIcon}
                  title={i18next.t("Accounts in USD, EUR and other currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget2
                  iconSrc={MerchantNewIcon}
                  title={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                  text={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                />
                <IconTextWidget2
                  iconSrc={LanguagesNewIcon}
                  title={i18next.t("Multilingual Support")}
                  text=""
                />
              </div>
              <div className="icon-text-widgets-container2">
                <IconTextWidget2
                  iconSrc={UsersNewIcon}
                  title={i18next.t("Robust multi-tier account security")}
                  text={i18next.t(
                    "Robust multi-tier account security with 2FA"
                  )}
                />
                <IconTextWidget2
                  iconSrc={Icon24}
                  title={i18next.t("First response in 1 hour or faster")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default OpenAccount;
