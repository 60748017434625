import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import CryptoImg from "../../../assets/images/cryptoImgDark.jpg";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import { ReactComponent as PhoneCallingRounded } from "../../../assets/images/phoneCallingRounded.svg";
import { ReactComponent as ShieldUser } from "../../../assets/images/shield-user.svg";
import { ReactComponent as VvidgetIcon } from "../../../assets/images/vvidget-icon.svg";
import { ReactComponent as UserCircle } from "../../../assets/images/user-circle.svg";

const defaultTheme = createTheme();
const Cryptos: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const items = [
    {
      icon: <ShieldUser />,
      subTitle: i18next.t(
        "Empowering SMEs and Startups with Secure Payment Solutions"
      ),
      desc: i18next.t(
        "Our regulated services are not just used by crypto enthusiasts all over the world, but also by a growing number of SME as well as startups, to integrate reliable payment infrastructures. Further we offer online backoffice solutions to help companies to stay compliant, to minimise risks and to monitor all transactions and addresses using industry-leading blockchain analytics solutions."
      ),
    },
    {
      icon: <VvidgetIcon />,
      subTitle: i18next.t("Explore Our Services"),
      desc: i18next.t("Click to learn more"),
      link: "/about-us",
      linkText: i18next.t("about our service"),
    },
    {
      icon: <UserCircle />,
      subTitle: i18next.t("Start Your Account Today"),
      desc: i18next.t("Click to here to"),
      link: "/registration",
      linkText: i18next.t("open an account"),
    },
    {
      icon: <PhoneCallingRounded />,
      subTitle: i18next.t("Contact Us for Assistance"),
      desc: i18next.t(
        "You can reach us Monday to Friday from 10 AM to 5 PM - please"
      ),
      link: "/contact-us",
      linkText: i18next.t("click here"),
      desc2: i18next.t("for more contact options!"),
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Crypto")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Are you ready for the next financial revolution?"
                    )}
                    subtitle={i18next.t(
                      "Crypto currencies, stable coins and tokens have driven a whole generation and created a completely new and decentralised world of wealth"
                    )}
                    buttonText={i18next.t("Create an account")}
                    onClickFunction={() => {
                      navigate("/registration");
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box sx={{ marginBottom: 5 }}>
                <ImageWithText2
                  backgroundImage={CryptoImg}
                  title={i18next.t("Crypto Made Easy")}
                  text={i18next.t(
                    "We are keeping this very simple and we make cryptocurrencies easy to deal with - no matter where you are!"
                  )}
                />
              </Box>
              <Box sx={{ padding: "4rem 0 4rem 0" }}>
                <TitleTextOverlayImg
                  title={i18next.t(
                    "Effortless Crypto Trading and Transactions"
                  )}
                  text={i18next.t(
                    "Quickly buy and sell crypto and stablecoins at great prices, switch between crypto and fiat easily, instantly deposit and withdraw at world’s largest crypto exchanges, connect with different wallets and more."
                  )}
                  direction="left"
                />
              </Box>
              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "Our clients can easily buy crypto with account or card balances for fair prices, within seconds and with maximum security. Through our website it´s possible to sell crypto for USD, EUR and other currencies, to receive great spreads through major crypto exchanges and to store, send and receive coins, such as BTC, ETH, USDT, USDC."
                  )}
                />
              </Box>
              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Cryptos;
