import React from "react";
import "./ImageWithText2.scss"; // Ensure this file is correctly linked
import { Box, Typography } from "@mui/material";

interface PaymentCardProps {
  backgroundImage: string;
  noiseImage?: string;
  title: string;
  text: string;
}

const ImageWithText2: React.FC<PaymentCardProps> = ({
  backgroundImage,
  noiseImage,
  title,
  text,
}) => {
  return (
    <Box className="container-img2">
      <img
        src={backgroundImage}
        alt="VicPay"
        style={{
          width: "100%",
          borderRadius: "24px",
          maxHeight: "622px",
          minHeight: "280px",
        }}
      />
      {noiseImage && (
        <img src={noiseImage} alt="noise" className="noiseImage" />
      )}
      <Box className="imageWithTextTitleText">
        <Box className="bottom-centered-title2">{title}</Box>
        <Box className="bottom-centered-text2">
          <Typography
            paragraph
            className="bottom-centered-text2"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageWithText2;
