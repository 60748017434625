import { useState } from "react";
import { MenuItem, Typography, Box, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./HeaderMobileMenu.scss";
import { CSSTransition } from "react-transition-group";
import { useLocation, useNavigate } from "react-router-dom";
import i18next from "i18next";

const HeaderMobileMenu = ({ triggerText, menuItems, closeMenu }: any) => {
  const [showText, setShowText] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setShowText(!showText);
  };

  const handleItemClick = (route: string) => {
    navigate(route);
  };

  const triggerTextActiveClass =
    (location.pathname === "/individual" ||
      location.pathname === "/business" ||
      location.pathname === "/multi-currency" ||
      location.pathname === "/open-account" ||
      location.pathname === "/download-app" ||
      location.pathname === "/contact-account-team" ||
      location.pathname === "/fees") &&
    triggerText === i18next.t("Accounts")
      ? "nav-link-mobile textActiveItemColor"
      : (location.pathname === "/payments" ||
          location.pathname === "/cryptos" ||
          location.pathname === "/mezzanine-capital" ||
          location.pathname === "/iban-accounts" ||
          location.pathname === "/download-app" ||
          location.pathname === "/instant-transfers" ||
          location.pathname === "/escrow-service" ||
          location.pathname === "/open-account" ||
          location.pathname === "/visa-card") &&
        triggerText === i18next.t("Services")
      ? "nav-link-mobile textActiveItemColor"
      : (location.pathname === "/client-security" ||
          location.pathname === "/license" ||
          location.pathname === "/regulation" ||
          location.pathname === "/safety-of-funds" ||
          location.pathname === "/compliance-desk" ||
          location.pathname === "/aml-policy") &&
        triggerText === i18next.t("Regulation")
      ? "nav-link-mobile textActiveItemColor"
      : (location.pathname === "/online-news-room" ||
          location.pathname === "/in-house-workshops") &&
        triggerText === i18next.t("News")
      ? "nav-link-mobile textActiveItemColor"
      : "nav-link-mobile";

  return (
    <>
      <MenuItem
        style={{
          justifyContent: "flex-start",
          paddingLeft: "0px",
          paddingBottom: "16px",
          paddingTop: "16px",
        }}
        onClick={handleClick}
      >
        <Box className="menuItemTextAndIconBox">
          <Typography className={`nav-link-mobile ${triggerTextActiveClass}`}>
            {i18next.t(triggerText)}
          </Typography>
          <KeyboardArrowDownIcon className="muiIcons" />
        </Box>
      </MenuItem>
      <CSSTransition
        in={showText}
        classNames="headerMobileMenuSlide"
        timeout={300}
        unmountOnExit
      >
        <Box className="mobileMenuItemsBox">
          {menuItems.map((item: any, index: number) =>
            item.route ? (
              <Typography
                key={index}
                className={`mobileMenuItemsText ${
                  location.pathname === item.route
                    ? "mobileMenuItemsTextActive"
                    : ""
                }`}
                onClick={() => handleItemClick(item.route)}
              >
                {i18next.t(item.text)}
              </Typography>
            ) : (
              <Typography className="mobileMenuItemsText" key={index}>
                {item.text}
              </Typography>
            )
          )}
        </Box>
      </CSSTransition>
      <Divider style={{ backgroundColor: "#e4e4e4" }} />
    </>
  );
};

export default HeaderMobileMenu;
