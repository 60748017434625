import i18next from "i18next";
import "../../pages/LandingPage.scss";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function OurVision() {
  const navigate = useNavigate();

  return (
    <Box className="ourVisionContainer">
      <Box className="ourVisonConatiner1">
        <Typography className="ourVisionText">
          {i18next.t("Our Vision")}
        </Typography>

        <Typography className="ourVisionDescText1">
          {i18next.t("Embrace innovation and security with VicPay")}
          {i18next.t(
            "Experience a new era of money transfer that offers unparalleled efficiency and safety"
          )}
        </Typography>
      </Box>

      <Box className="ourVisonConatiner2">
        <Typography className="ourVisionDescText">
          {i18next.t(
            "In a world where security is becoming increasingly important, VicPay offers future-proof and reliable fiat and cryptocurrency payments"
          )}
        </Typography>

        <Typography sx={{ mt: "30px" }} className="ourVisionDescText">
          {i18next.t(
            "We offer our customers a simple and intuitive modern alternative to traditional brokers or high street banks"
          )}{" "}
        </Typography>
      </Box>
    </Box>
  );
}

export default OurVision;
