import { ThemeProvider, Typography, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import International from "./International";
import SameCurrency from "./SameCurrency";
import api from "src/store/interceptors/api";
import "./AmountStep.scss";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import Crypto from "./Crypto";

const defaultTheme = createTheme();

interface Props {
  setStep: (value: number) => void;
  step: number;
  setQuest: (value: any) => void;
  setTransactionType: (value: any) => void;
  navigate: ReturnType<typeof useNavigate>;
}

function CurrencyStep({
  setStep,
  step,
  setTransactionType,
  setQuest,
  navigate,
}: Props) {
  const [currencies, setCurrencies] = useState<any>(null);
  const getInitialData = async () => {
    await api
      .get("/profile/balances")
      .then((response: any) => {
        setCurrencies(response.data);
        const cur = response?.data;
        setSelected(cur && cur.length > 1 ? "International" : "Same Currency")
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const [selected, setSelected] = useState<any>(null);

  const handleSelect = (option: any) => {
    setSelected(option);
    setTransactionType(option);
  };

  const getAnimationClass = () => {
    return selected === "Same Currency" ? "moveInLeft" : "moveInRight";
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="amountMain">
      <ThemeProvider theme={defaultTheme}>
        <div className="switch-selector mb-1-5rem">
          {currencies && currencies.length > 1 && (
            <div
              className={`option ${
                selected === "International" ? "active" : ""
              }`}
              onClick={() => handleSelect("International")}
            >
              <Typography>{i18next.t("international")}</Typography>
            </div>
          )}
          <div
            className={`option ${selected === "Same Currency" ? "active" : ""}`}
            onClick={() => handleSelect("Same Currency")}
          >
            <Typography>{i18next.t("Same Currency")}</Typography>
          </div>
          <div
            className={`option ${selected === "Crypto" ? "active" : ""}`}
            onClick={() => handleSelect("Crypto")}
          >
            <Typography>{i18next.t("Crypto")}</Typography>
          </div>
        </div>

        <div
          className={`animated-text ${
            selected ? "content-visible" : ""
          } ${getAnimationClass()}`}
          key={selected}
        >
          {selected === "Crypto" && (
            <>
              {currencies && (
                <Crypto
                  navigate={navigate}
                  step={step}
                  setStep={setStep}
                  setQuest={setQuest}
                  currencies={currencies}
                />
              )}
            </>
          )}
          {currencies &&
            currencies.length > 1 &&
            selected === "International" && (
              <>
                {currencies && (
                  <International
                    navigate={navigate}
                    step={step}
                    setStep={setStep}
                    setQuest={setQuest}
                    currencies={currencies}
                  />
                )}
              </>
            )}
          {selected === "Same Currency" && (
            <div>
              {currencies && (
                <SameCurrency
                  navigate={navigate}
                  step={step}
                  setStep={setStep}
                  setQuest={setQuest}
                  currencies={currencies}
                />
              )}
            </div>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}
export default CurrencyStep;
