import { Box, Typography } from "@mui/material";
import "./TitleDescGradientAnimation.scss";

interface titleDescProps {
  title: string;
  description: string;
  description2?: string;
}

const TitleDescGradientAnimation: React.FC<titleDescProps> = ({
  title,
  description,
  description2,
}) => {
  return (
    <Box className="titleDescContainer titleDescGradientContainer">
      <Typography className="titleDescGradientTitle">{title}</Typography>

      <Typography className="titleDescGradientDesc">{description}</Typography>
      <Typography className="titleDescGradientDesc">{description2}</Typography>
    </Box>
  );
};

export default TitleDescGradientAnimation;
