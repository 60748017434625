import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import AlarmPlay from "../../../assets/images/alarm-play.svg";
import CardTransfer from "../../../assets/images/card-transfer.svg";
import HandMoney from "../../../assets/images/hand-money.svg";
import CardSend from "../../../assets/images/card-send.svg";
import StarFall from "../../../assets/images/star-fall.svg";

const defaultTheme = createTheme();

const IbanAccounts: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/contact-us");
  };

  const items = [
    {
      subTitle: i18next.t(
        "Your business grows Do you need more than one IBAN account?"
      ),
      desc: i18next.t(
        "No problem! With VicPay you can increase the amount of your IBAN accounts easily up to 10 accounts! You can easily connect your VicPay Debit card with your IBAN account to save even more time, money and effort on transactions! - Coming soon"
      ),
    },
    {
      subTitle: i18next.t("Want to know more details? Contact us!"),
      link: "/contact-us",
      linkText: i18next.t("Click here"),
      desc2: i18next.t("for all contact options!"),
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("IBAN accounts")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Open a SEPA IBAN account for your business!"
                    )}
                    subtitle={i18next.t(
                      "Forget traditional banks! Open your IBAN account easily, online with VicPay!"
                    )}
                    buttonText={i18next.t("Contact Us")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <TitleTextOverlayImg
                  title={i18next.t("Streamline Payments with an IBAN Account")}
                  text={i18next.t(
                    "An IBAN account is a cheap and easy way to arrange payments, but it´s not easy to open IBAN accounts in case you aren´t resident of the European Community - and even being a resident applying for an IBAN account in a traditional bank might be time-consuming and means a lot of paperwork."
                  )}
                  direction="left"
                />
                <TitleTextOverlayImg
                  title={i18next.t(
                    "Effortless IBAN Accounts with Extra Features"
                  )}
                  text={i18next.t(
                    "As we are a partner of a fully regulated and licensed e-money institution, we can open IBAN accounts quickly and unbureaucratically"
                  )}
                  direction="right"
                />
              </Box>
              <Box className="titleTextVvidgetFlexBox">
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    showBoxStyle={true}
                    icon={StarFall}
                    title={i18next.t("Save and Schedule Transfers")}
                    text={i18next.t("Save and schedule regular transfers")}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    showBoxStyle={true}
                    icon={CardTransfer}
                    title={i18next.t("Send Multi-Currency Funds")}
                    text={i18next.t("Send funds in different currencies")}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    showBoxStyle={true}
                    icon={HandMoney}
                    title={i18next.t("Seamless SEPA Transfers")}
                    text={i18next.t(
                      "Make seamless SEPA transfers using your dedicated IBAN"
                    )}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    showBoxStyle={true}
                    icon={CardSend}
                    title={i18next.t("Instant VicPay to IBAN Transfers")}
                    text={i18next.t(
                      "Send money instantly from your VicPay account to your other IBAN account"
                    )}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    showBoxStyle={true}
                    icon={AlarmPlay}
                    title={i18next.t("Discover IBAN Account Benefits")}
                    text={i18next.t(
                      "Experience the advantages of an IBAN accounts for your company"
                    )}
                  />
                </Box>
              </Box>
              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default IbanAccounts;
