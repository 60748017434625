import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React, { useState, useEffect, ChangeEvent } from "react";
import FilePreview from "src/components/shared/FilePreview";
import CountrySelect from "src/components/shared/CountrySelect/CountrySelect";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { acceptableProofOfId, proofOfAddress } from "src/utils/helpers";

interface Person {
  proofOfId: any;
  proofOfAddress: any;
}
type SelectedFile = {
  file: File | null;
  preview: string;
};

type Director = Person;
type Shareholder = Person;

interface Props {
  formik: any;
  handleTextFieldChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleSelectChange: (e: SelectChangeEvent<any>) => void;
  handleSubmitForm: (data: any) => void;
  isSubmitting: boolean;
}
function DocumentsStep({
  formik,
  handleTextFieldChange,
  handleSelectChange,
  handleSubmitForm,
  isSubmitting,
}: Props) {
  const [directors, setDirectors] = useState<Director[]>([
    {
      proofOfId: { file: null, preview: null, type: null },
      proofOfAddress: { file: null, preview: null, type: null },
    },
  ]);
  const [shareholders, setShareholders] = useState<Shareholder[]>([]);

  const [selectedFiles, setSelectedFiles] = useState<
    Record<string, SelectedFile>
  >({
    companyRegistryExtract: { file: null, preview: "" },
    certificateOfIncorporation: { file: null, preview: "" },
    shareholderRegister: { file: null, preview: "" },
    annualReport: { file: null, preview: "" },
    companyBankStatement: { file: null, preview: "" },
    formOfDeals: { file: null, preview: "" },
  });

  const handleAddDirector = () => {
    if (
      directors &&
      directors.length > 0 &&
      directors[directors.length - 1] &&
      (!directors[directors.length - 1].proofOfId?.file ||
        !directors[directors.length - 1].proofOfAddress?.file)
    ) {
      alert(i18next.t("Please fill director details before adding new!"));
    } else {
      setDirectors([
        ...directors,
        {
          proofOfId: { file: null, preview: null, type: null },
          proofOfAddress: { file: null, preview: null, type: null },
        },
      ]);
    }
  };

  const handleAddShareholder = () => {
    if (
      shareholders &&
      shareholders.length > 0 &&
      shareholders[shareholders.length - 1] &&
      (!shareholders[shareholders.length - 1].proofOfId?.file ||
        !shareholders[shareholders.length - 1].proofOfAddress?.file)
    ) {
      alert(i18next.t("Please fill shareholder details before adding new!"));
    } else {
      setShareholders([
        ...shareholders,
        {
          proofOfId: { file: null, preview: null, type: null },
          proofOfAddress: { file: null, preview: null, type: null },
        },
      ]);
    }
  };

  const handleRemoveDirector = (index: number) => {
    if (directors.length > 1) {
      setDirectors(directors.filter((_, i) => i !== index));
    }
  };

  const handleRemoveShareholder = (index: number) => {
    setShareholders(shareholders.filter((_, i) => i !== index));
  };

  const prepareFiles = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    field: keyof Director | keyof Shareholder,
    type: "director" | "shareholder"
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const filePreview = reader.result as string;
        if (type === "director") {
          let data = [...directors];
          if (data[index] && data[index][field]) {
            data[index][field].preview = filePreview;
          }
          setDirectors(data);
        } else {
          let data = [...shareholders];
          if (data[index] && data[index][field]) {
            data[index][field].preview = filePreview;
          }
          setShareholders(data);
        }
      };
      reader.readAsDataURL(file);

      if (type === "director") {
        let data = [...directors];
        if (data[index] && data[index][field]) {
          data[index][field].file = file;
        }
        setDirectors(data);
      } else {
        let data = [...shareholders];
        if (data[index] && data[index][field]) {
          data[index][field].file = file;
        }
        setShareholders(data);
      }
    }
  };
  const handleCompanyDocumentUpload = (
    event: ChangeEvent<HTMLInputElement>,
    type:
      | "companyRegistryExtract"
      | "certificateOfIncorporation"
      | "shareholderRegister"
      | "annualReport"
      | "companyBankStatement"
      | "formOfDeals"
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const filePreview = reader.result as string;
        setSelectedFiles((prev) => ({
          ...prev,
          [type]: { file, preview: filePreview },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCountrySelect = (value: any) => {
    const event = {
      target: {
        name: "verification_country_code",
        value: value,
      },
    } as SelectChangeEvent<any>;

    handleSelectChange(event);
  };

  /*
  useEffect(() => {
    formik.setFieldValue("documents", selectedFiles);
  }, [companyDocuments]);
  */

  return (
    <Box className="settingsMain animated-text moveInRight">
      <Typography
        className="pageTitleFontSize"
        component="h1"
        variant="h5"
        sx={{
          fontFamily: "Helvetica, sans-serif",
          fontWeight: "600",
          color: "#0e0f0c",
          maxWidth: "500px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {i18next.t("Business description")}
      </Typography>

      <Box>
        <Box className="mb-big">
          <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "A detailed description of business activities including funds and goods flow overview"
              )}
            </Typography>
            <TextField
              minRows={3}
              style={{ width: "100%" }}
              multiline={true}
              value={formik.values.bussiness_activities}
              name="bussiness_activities"
              onChange={handleTextFieldChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px!important",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: "10px",
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                },
              }}
            />
            {formik.touched.bussiness_activities &&
              formik.errors.bussiness_activities && (
                <Typography
                  className="amountErrorForm"
                  style={{ marginTop: 8 }}
                >
                  {formik.errors.bussiness_activities.toString()}
                </Typography>
              )}
          </Box>

          <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "How long has the company been in business, and where is the business based and managed from?"
              )}
            </Typography>
            <TextField
              minRows={3}
              style={{ width: "100%" }}
              multiline={true}
              value={formik.values.company_in_bussiness}
              name="company_in_bussiness"
              onChange={handleTextFieldChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingRight: "0px!important",
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: "10px",
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                },
              }}
            />
            {formik.touched.company_in_bussiness &&
              formik.errors.company_in_bussiness && (
                <Typography
                  className="amountErrorForm"
                  style={{ marginTop: 8 }}
                >
                  {formik.errors.company_in_bussiness.toString()}
                </Typography>
              )}
          </Box>

          <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Typography className="labelsLocalIban">
              {i18next.t(
                "In what countries is the company currently operating and planning to operate in future?"
              )}
            </Typography>

            <Box
              className="country-code-container-personal mt-16"
              style={{ width: "100%" }}
            >
              <CountrySelect
                value={formik.values.verification_country_code}
                isTouched={formik.touched.verification_country_code}
                errors={formik.errors.verification_country_code}
                onSelect={handleCountrySelect}
                code={true}
              />
            </Box>
          </Box>
        </Box>

        <Box className="section">
          <Typography
            className="pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#0e0f0c",
              maxWidth: "500px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              mb: "10px",
            }}
          >
            {i18next.t("KYC")}
          </Typography>
          <Box>
            <Box className="mb-big">
              <Typography
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textAlign: "start",
                  mt: 4,
                  mb: 2,
                }}
              >
                {i18next.t("Directors")}
              </Typography>

              <Box>
                {directors.map((director, index) => (
                  <Box
                    key={`director-${index}`}
                    sx={{
                      mb: 3,
                      border: "1px solid #ccc",
                      p: 3,
                      borderRadius: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          mb: 1,
                        }}
                      >
                        {i18next.t("Director")} {index + 1}
                      </Typography>
                      {index > 0 && directors.length > 1 && (
                        <IconButton
                          onClick={() => handleRemoveDirector(index)}
                          color="error"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "start",
                        }}
                      >
                        {i18next.t("Acceptable proof of ID:")}
                      </Typography>

                      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Select
                          name={"director_proof_id" + index}
                          style={{ width: "100%" }}
                          sx={{
                            borderRadius: "10px",
                            height: "48px",
                            marginBottom: "20px",
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16.5px 14px 16.5px 14px",
                              },
                            "@media (max-width: 600px)": {
                              height: "42px",
                              fontSize: "14px",
                            },
                          }}
                          onChange={(event) => {
                            let updatedData = [...directors];
                            updatedData[index].proofOfId.file = null;
                            updatedData[index].proofOfId.preview = null;
                            updatedData[index].proofOfId.type =
                              event.target.value;
                            setDirectors(updatedData);
                          }}
                          defaultValue={director?.proofOfId?.type ?? ""}
                          placeholder="Select"
                        >
                          {acceptableProofOfId.map(
                            (menuItem: any, menuIndex: any) => {
                              return (
                                <MenuItem
                                  key={"proofOfId" + menuIndex + "_" + index}
                                  value={menuItem}
                                >
                                  {i18next.t(menuItem.title)}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {director?.proofOfId?.type && (
                          <Button
                            sx={{
                              width: "fit-content",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadOutlinedIcon />}
                          >
                            {director.proofOfId.file
                              ? i18next.t("Change")
                              : i18next.t("Upload")}

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                prepareFiles(e, index, "proofOfId", "director");
                              }}
                            />
                          </Button>
                        )}
                        {director?.proofOfId?.type &&
                          director.proofOfId?.preview && (
                            <Box sx={{ mt: 3 }}>
                              <FilePreview
                                src={director.proofOfId?.preview}
                                width="90%"
                                file={director.proofOfId?.file}
                                alt="Proof of ID"
                              />
                            </Box>
                          )}
                      </div>
                      <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />

                      <Typography
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "start",
                        }}
                      >
                        {i18next.t("Proof of address:")}
                      </Typography>
                      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Select
                          name={"director_proof_of_address" + index}
                          style={{ width: "100%" }}
                          sx={{
                            borderRadius: "10px",
                            height: "48px",
                            marginBottom: "20px",
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16.5px 14px 16.5px 14px",
                              },
                            "@media (max-width: 600px)": {
                              height: "42px",
                              fontSize: "14px",
                            },
                          }}
                          onChange={(event) => {
                            let updatedData = [...directors];
                            updatedData[index].proofOfAddress.file = null;
                            updatedData[index].proofOfAddress.preview = null;
                            updatedData[index].proofOfAddress.type =
                              event.target.value;
                            setDirectors(updatedData);
                          }}
                          defaultValue={director?.proofOfAddress?.type ?? ""}
                          placeholder="Select"
                        >
                          {proofOfAddress.map(
                            (menuItem: any, menuIndex: any) => {
                              return (
                                <MenuItem
                                  key={
                                    "proofOfAddress" + menuIndex + "_" + index
                                  }
                                  value={menuItem}
                                >
                                  {i18next.t(menuItem.title)}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {director?.proofOfAddress?.type && (
                          <Button
                            sx={{
                              width: "fit-content",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadOutlinedIcon />}
                          >
                            {director.proofOfAddress.file
                              ? i18next.t("Change")
                              : i18next.t("Upload")}

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                prepareFiles(
                                  e,
                                  index,
                                  "proofOfAddress",
                                  "director"
                                );
                              }}
                            />
                          </Button>
                        )}
                        {director?.proofOfAddress?.type &&
                          director?.proofOfAddress?.preview && (
                            <Box sx={{ mt: 3 }}>
                              <FilePreview
                                src={director?.proofOfAddress?.preview}
                                width="90%"
                                file={director?.proofOfAddress?.file}
                                alt="Proof of Address"
                              />
                            </Box>
                          )}
                      </div>
                    </Box>
                  </Box>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAddDirector}
                >
                  {i18next.t("Add Director")}
                </Button>
              </Box>
            </Box>
          </Box>

          {/* Shareholders */}
          <Box>
            <Box className="mb-big">
              <Typography
                sx={{
                  fontFamily: "Helvetica, sans-serif",
                  textAlign: "start",
                  mt: 4,
                  mb: 2,
                }}
              >
                {i18next.t("Shareholders")}
              </Typography>

              <Box>
                {shareholders.map((shareholder, index) => (
                  <Box
                    key={`shareholder-${index}`}
                    sx={{
                      mb: 3,
                      border: "1px solid #ccc",
                      p: 3,
                      borderRadius: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          mb: 1,
                        }}
                      >
                        {i18next.t("Shareholder")} {index + 1}
                      </Typography>
                      {shareholders.length > 0 && (
                        <IconButton
                          onClick={() => handleRemoveShareholder(index)}
                          color="error"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "start",
                        }}
                      >
                        {i18next.t("Acceptable proof of ID:")}
                      </Typography>

                      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Select
                          name={"shareholder_proof_id" + index}
                          style={{ width: "100%" }}
                          sx={{
                            borderRadius: "10px",
                            height: "48px",
                            marginBottom: "20px",
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16.5px 14px 16.5px 14px",
                              },
                            "@media (max-width: 600px)": {
                              height: "42px",
                              fontSize: "14px",
                            },
                          }}
                          onChange={(event) => {
                            let updatedData = [...shareholders];
                            updatedData[index].proofOfId.file = null;
                            updatedData[index].proofOfId.preview = null;
                            updatedData[index].proofOfId.type =
                              event.target.value;
                            setShareholders(updatedData);
                          }}
                          defaultValue={shareholder?.proofOfId?.type ?? ""}
                          placeholder="Select"
                        >
                          {acceptableProofOfId.map(
                            (menuItem: any, menuIndex: any) => {
                              return (
                                <MenuItem
                                  key={"proofOfId" + menuIndex + "_" + index}
                                  value={menuItem}
                                >
                                  {i18next.t(menuItem.title)}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {shareholder?.proofOfId?.type && (
                          <Button
                            sx={{
                              width: "fit-content",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadOutlinedIcon />}
                          >
                            {shareholder.proofOfId.file
                              ? i18next.t("Change")
                              : i18next.t("Upload")}

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                prepareFiles(
                                  e,
                                  index,
                                  "proofOfId",
                                  "shareholder"
                                );
                              }}
                            />
                          </Button>
                        )}
                        {shareholder?.proofOfId?.type &&
                          shareholder.proofOfId?.preview && (
                            <Box sx={{ mt: 3 }}>
                              <FilePreview
                                src={shareholder.proofOfId?.preview}
                                width="90%"
                                file={shareholder.proofOfId?.file}
                                alt="Proof of ID"
                              />
                            </Box>
                          )}
                      </div>
                      <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />

                      <Typography
                        sx={{
                          fontFamily: "Helvetica, sans-serif",
                          textAlign: "start",
                        }}
                      >
                        {i18next.t("Proof of address:")}
                      </Typography>
                      <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Select
                          name={"shareholder_proof_of_address" + index}
                          style={{ width: "100%" }}
                          sx={{
                            borderRadius: "10px",
                            height: "48px",
                            marginBottom: "20px",
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "16.5px 14px 16.5px 14px",
                              },
                            "@media (max-width: 600px)": {
                              height: "42px",
                              fontSize: "14px",
                            },
                          }}
                          onChange={(event) => {
                            let updatedData = [...shareholders];
                            updatedData[index].proofOfAddress.file = null;
                            updatedData[index].proofOfAddress.preview = null;
                            updatedData[index].proofOfAddress.type =
                              event.target.value;
                            setShareholders(updatedData);
                          }}
                          defaultValue={shareholder?.proofOfAddress?.type ?? ""}
                          placeholder="Select"
                        >
                          {proofOfAddress.map(
                            (menuItem: any, menuIndex: any) => {
                              return (
                                <MenuItem
                                  key={
                                    "proofOfAddress" + menuIndex + "_" + index
                                  }
                                  value={menuItem}
                                >
                                  {i18next.t(menuItem.title)}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>

                        {shareholder?.proofOfAddress?.type && (
                          <Button
                            sx={{
                              width: "fit-content",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#2A5182",
                                color: "white",
                              },
                            }}
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadOutlinedIcon />}
                          >
                            {shareholder.proofOfAddress.file
                              ? i18next.t("Change")
                              : i18next.t("Upload")}

                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                prepareFiles(
                                  e,
                                  index,
                                  "proofOfAddress",
                                  "shareholder"
                                );
                              }}
                            />
                          </Button>
                        )}
                        {shareholder?.proofOfAddress?.type &&
                          shareholder?.proofOfAddress?.preview && (
                            <Box sx={{ mt: 3 }}>
                              <FilePreview
                                src={shareholder?.proofOfAddress?.preview}
                                width="90%"
                                file={shareholder?.proofOfAddress?.file}
                                alt="Proof of Address"
                              />
                            </Box>
                          )}
                      </div>
                    </Box>
                  </Box>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAddShareholder}
                >
                  {i18next.t("Add Shareholder")}
                </Button>
              </Box>
            </Box>
          </Box>
          <Typography
            className="pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#0e0f0c",
              maxWidth: "500px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              mb: "10px",
            }}
          >
            {i18next.t("Company Documents")}
          </Typography>
          <Box
            sx={{
              mb: 3,
              border: "1px solid #ccc",
              p: 3,
              borderRadius: 4,
            }}
          >
            <Box>
              <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography className="labelsLocalIban">
                  {i18next.t(
                    "Company Registry Extract (no older than 6 months)"
                  )}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  {selectedFiles["companyRegistryExtract"]?.file
                    ? i18next.t("Change")
                    : i18next.t("Upload")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleCompanyDocumentUpload(e, "companyRegistryExtract")
                    }
                  />
                </Button>
                {selectedFiles["companyRegistryExtract"]?.file && (
                  <Box sx={{ mt: 3 }}>
                    <FilePreview
                      src={selectedFiles["companyRegistryExtract"].preview}
                      width="90%"
                      file={selectedFiles["companyRegistryExtract"].file}
                      alt="companyRegistryExtract"
                    />
                  </Box>
                )}
              </Box>
              <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />
              <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography className="labelsLocalIban">
                  {i18next.t(
                    "Certificate of incorporation / Articles of Association"
                  )}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  {selectedFiles["certificateOfIncorporation"]?.file
                    ? i18next.t("Change")
                    : i18next.t("Upload")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleCompanyDocumentUpload(
                        e,
                        "certificateOfIncorporation"
                      )
                    }
                  />
                </Button>
                {selectedFiles["certificateOfIncorporation"]?.file && (
                  <Box sx={{ mt: 3 }}>
                    <FilePreview
                      src={selectedFiles["certificateOfIncorporation"].preview}
                      width="90%"
                      file={selectedFiles["certificateOfIncorporation"].file}
                      alt="certificateOfIncorporation"
                    />
                  </Box>
                )}
              </Box>
              <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />
              <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography className="labelsLocalIban">
                  {i18next.t("Shareholder register (if available)")}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  {selectedFiles["shareholderRegister"]?.file
                    ? i18next.t("Change")
                    : i18next.t("Upload")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleCompanyDocumentUpload(e, "shareholderRegister")
                    }
                  />
                </Button>
                {selectedFiles["shareholderRegister"]?.file && (
                  <Box sx={{ mt: 3 }}>
                    <FilePreview
                      src={selectedFiles["shareholderRegister"].preview}
                      width="90%"
                      file={selectedFiles["shareholderRegister"].file}
                      alt="shareholderRegister"
                    />
                  </Box>
                )}
              </Box>
              <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />
              <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography className="labelsLocalIban">
                  {i18next.t(
                    "Annual Report/Financial Statement for the recent financial year (if available)"
                  )}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  {selectedFiles["annualReport"]?.file
                    ? i18next.t("Change")
                    : i18next.t("Upload")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleCompanyDocumentUpload(e, "annualReport")
                    }
                  />
                </Button>
                {selectedFiles["annualReport"]?.file && (
                  <Box sx={{ mt: 3 }}>
                    <FilePreview
                      src={selectedFiles["annualReport"].preview}
                      width="90%"
                      file={selectedFiles["annualReport"].file}
                      alt="annualReport"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Typography
            className="pageTitleFontSize"
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Helvetica, sans-serif",
              fontWeight: "600",
              color: "#0e0f0c",
              maxWidth: "500px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              mb: "10px",
            }}
          >
            {i18next.t("Source of funds")}
          </Typography>
          <Box
            sx={{
              mb: 3,
              border: "1px solid #ccc",
              p: 3,
              borderRadius: 4,
            }}
          >
            <Box>
              <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography className="labelsLocalIban">
                  {i18next.t(
                    "Company bank statement for last 6 months (if available)"
                  )}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  {selectedFiles["companyBankStatement"]?.file
                    ? i18next.t("Change")
                    : i18next.t("Upload")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleCompanyDocumentUpload(e, "companyBankStatement")
                    }
                  />
                </Button>
                {selectedFiles["companyBankStatement"]?.file && (
                  <Box sx={{ mt: 3 }}>
                    <FilePreview
                      src={selectedFiles["companyBankStatement"].preview}
                      width="90%"
                      file={selectedFiles["companyBankStatement"].file}
                      alt="companyBankStatement"
                    />
                  </Box>
                )}
              </Box>
              <Divider sx={{ background: "#ccc", mb: 3, mt: 3 }} />
              <Box style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography className="labelsLocalIban">
                  {i18next.t(
                    "Collect two forms of proof such as invoices, contracts, agreements"
                  )}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#2A5182",
                      color: "white",
                    },
                  }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  {selectedFiles["formOfDeals"]?.file
                    ? i18next.t("Change")
                    : i18next.t("Upload")}
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleCompanyDocumentUpload(e, "formOfDeals")
                    }
                  />
                </Button>
                {selectedFiles["formOfDeals"]?.file && (
                  <Box sx={{ mt: 3 }}>
                    <FilePreview
                      src={selectedFiles["formOfDeals"].preview}
                      width="90%"
                      file={selectedFiles["formOfDeals"].file}
                      alt="formOfDeals"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Button
            type="button"
            fullWidth
            variant="contained"
            className="continueBtn"
            disabled={isSubmitting}
            onClick={(e) => {
              //e.stopPropagation();
              let strError = "";
              let data: any = [];
              directors.forEach((item: Person, index) => {
                if (!item.proofOfId?.file)
                  strError +=
                    i18next.t("Director") +
                    " " +
                    (index + 1) +
                    " " +
                    i18next.t("proof of ID") +
                    ", ";

                if (!item.proofOfAddress?.file)
                  strError +=
                    i18next.t("Director") +
                    " " +
                    (index + 1) +
                    " " +
                    i18next.t("proof of address") +
                    ", ";

                data.push({
                  label:
                    i18next.t("Director") +
                    " " +
                    (index + 1) +
                    " " +
                    i18next.t("proof of ID"),
                  index,
                  name: "director",
                  id: "director_" + index,
                  proofOfId: {
                    file: item.proofOfId.file,
                    type: item.proofOfId.type,
                  },
                  proofOfAddress: {
                    file: item.proofOfAddress.file,
                    type: item.proofOfAddress.type,
                  },
                });
              });

              if (shareholders.length > 0) {
                shareholders.forEach((item: Person, index) => {
                  if (!item.proofOfId?.file)
                    strError +=
                      i18next.t("Shareholder") +
                      " " +
                      (index + 1) +
                      " " +
                      i18next.t("proof of ID") +
                      ", ";

                  if (!item.proofOfAddress?.file)
                    strError +=
                      i18next.t("Shareholder") +
                      " " +
                      (index + 1) +
                      " " +
                      i18next.t("proof of address") +
                      ", ";

                  data.push({
                    label:
                      i18next.t("Shareholder") +
                      " " +
                      (index + 1) +
                      " " +
                      i18next.t("proof of ID"),
                    index,
                    name: "shareholder",
                    id: "shareholder_" + index,
                    proofOfId: {
                      file: item.proofOfId.file,
                      type: item.proofOfId.type,
                    },
                    proofOfAddress: {
                      file: item.proofOfAddress.file,
                      type: item.proofOfAddress.type,
                    },
                  });
                });
              }

              if (!selectedFiles.companyRegistryExtract?.file)
                strError += i18next.t("Company registry extract") + ", ";

              if (!selectedFiles.certificateOfIncorporation?.file)
                strError += i18next.t("Certificate of Incorporation") + ", ";

              if (strError !== "") {
                alert(i18next.t("Please check the fields for:") + strError);
              } else {
                handleSubmitForm({
                  members: data,
                  selectedFiles,
                });
              }
            }}
          >
            {i18next.t("Continue")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentsStep;
