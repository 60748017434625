import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import currencyw from "../../../assets/images/cuurencywOverlay.png";
import majorOverlay from "../../../assets/images/major-currencies-noise.svg";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import IconTextWidget2 from "../components/IconTextWidget2/IconTextWidget2";
import CurrencyNewIcon from "../../../assets/images/currencies-new.svg";
import MerchantNewIcon from "../../../assets/images/merchant-new.svg";
import LanguagesNewIcon from "../../../assets/images/languages-new.svg";
import UsersNewIcon from "../../../assets/images/users-new.svg";
import Icon24 from "../../../assets/images/24-new.svg";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import TitleDescGradientAnimation from "../components/TitleDescGradientAnimation/TitleDescGradientAnimation";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import { ReactComponent as AvatarShadow } from "../../../assets/images/avatarWShadow.svg";
import { ReactComponent as CardSearch } from "../../../assets/images/cardSearch.svg";
import { ReactComponent as PhoneCallingRounded } from "../../../assets/images/phoneCallingRounded.svg";

const defaultTheme = createTheme();
const MultiCurrrency: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };
  const items = [
    {
      icon: <AvatarShadow />,
      subTitle: i18next.t("Effortless Account Management"),
      desc: i18next.t(
        "Our technology solutions and our worldwide partnerships with banks and financial services companies enable our clients to open, close or update multi-currency individual and business accounts whenever you want"
      ),
    },
    {
      icon: <CardSearch />,
      subTitle: i18next.t("Streamlined Treasury Transformation"),
      desc: i18next.t(
        "Transform your treasury management within minutes: Click"
      ),
      link: "/registration",
      linkText: i18next.t("here"),
      desc2: i18next.t(
        "to create a free business account and then complete our simple KYB process and verification - you´re already done and start"
      ),
    },
    {
      icon: <PhoneCallingRounded />,
      subTitle: i18next.t("Contact Us Anytime"),
      desc: i18next.t(
        "In case of any questions do not hesitate to send us a message to"
      ),
      email: "info@vicpayments.com",
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Multi-Currency")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Multi-currency accounts for your company"
                    )}
                    subtitle={i18next.t(
                      "Our secure online platform allow our customers to hold, send, receive and manage money across 65 currencies - all within one single account"
                    )}
                    buttonText={i18next.t("Create an account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <div className="icon-text-widgets-container">
                <IconTextWidget2
                  iconSrc={CurrencyNewIcon}
                  title={i18next.t("Accounts in 12 major currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget2
                  iconSrc={MerchantNewIcon}
                  title={i18next.t(
                    "Open, close or update accounts whenever you want"
                  )}
                  text={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                />
                <IconTextWidget2
                  iconSrc={LanguagesNewIcon}
                  title={i18next.t("Worldwide partnerships with banks")}
                  text=""
                />
              </div>
              <div className="icon-text-widgets-container2">
                <IconTextWidget2
                  iconSrc={UsersNewIcon}
                  title={i18next.t("Save time and money with our services")}
                  text={i18next.t(
                    "Robust multi-tier account security with 2FA"
                  )}
                />
                <IconTextWidget2
                  iconSrc={Icon24}
                  title={i18next.t("Easy and fast customer support")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>

              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "With our multi-currency accounts, your business can access accounts in 65 currencies. Your finance team and your customers can benefit with instant FX settlement. A seamless solution for every single payment in every single currency"
                  )}
                />
              </Box>
              <Box sx={{ marginTop: 5, marginBottom: 5 }}>
                <ImageWithText2
                  backgroundImage={currencyw}
                  noiseImage={majorOverlay}
                  title={i18next.t("65 currencies")}
                  text={i18next.t(
                    "With our multi-currency accounts, your business can access accounts in 65 currencies. A seamless solution for every single payment in every single currency"
                  )}
                />
              </Box>
              <Box>
                <TitleDescGradientAnimation
                  title={i18next.t("Multi currency accounts")}
                  description={i18next.t(
                    "No need to swap between apps and accounts anymore - our multi currency accounts and our crypto features perfectly match the needs of globally active companies"
                  )}
                  description2={i18next.t(
                    "WIth our services we offer streamlined and automated reconciliation, time saving while managing payments and full flexibility receiving different currencies and payments"
                  )}
                />
              </Box>
              <Box>
                <TitleIconSubTitleDesc
                  title={i18next.t("Transform your management")}
                  items={items}
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default MultiCurrrency;
