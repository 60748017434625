import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import IconTextWidget2 from "../components/IconTextWidget2/IconTextWidget2";
import paymentsImage from "../../../assets/images/paymentsImage.png";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import "./LandingPagesGlobal2.scss";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";
import ImagesWithText from "../components/ImagesWithText/ImagesWithText";
import { ReactComponent as ModernSolutions } from "../../../assets/images/modern-solutions.svg";
import { ReactComponent as AdaptiveServices } from "../../../assets/images/adaptiveServices.svg";
import { ReactComponent as StreamlinedSupport } from "../../../assets/images/streamlined-support.svg";
import { ReactComponent as SecurityReliability } from "../../../assets/images/security-reliability.svg";
import { ReactComponent as EfficiencyRedefined } from "../../../assets/images/efficiency-redefined.svg";
import CurrencyNewIcon from "../../../assets/images/currencies-new.svg";
import MerchantNewIcon from "../../../assets/images/merchant-new.svg";
import LanguagesNewIcon from "../../../assets/images/languages-new.svg";
import UsersNewIcon from "../../../assets/images/users-new.svg";
import Icon24 from "../../../assets/images/24-new.svg";

const offerContent = [
  {
    title: i18next.t("Modern Solutions for Modern Companies"),
    text: i18next.t(
      "The needs of companies in the 21st century have changed drastically, just as the demands and expectations of customers in traditional point of sale and online retail have changed drastically"
    ),
    icon: <ModernSolutions />,
  },
  {
    title: i18next.t("Adaptive Services"),
    text: i18next.t(
      "Unfortunately, however, very few traditional banks, brokers, and financial service providers have adapted to these changes and adapted their services accordingly"
    ),
    icon: <AdaptiveServices />,
  },
  {
    title: i18next.t("Streamlined Support"),
    text: i18next.t(
      "Our support and our tools for corporate clients are easy to start or stop using, do not include setup fees, further obligations, and are simple, secure, and cost-efficient"
    ),
    icon: <StreamlinedSupport />,
  },
  {
    title: i18next.t("Security & Reliability"),
    text: i18next.t(
      "We offer, together with our partners, a fully regulated and secure account environment, almost 100% server uptime, and dedicated and experienced account managers, available 24/7 for our business clients"
    ),
    icon: <SecurityReliability />,
  },
  {
    title: i18next.t("Efficiency Redefined"),
    text: i18next.t(
      "Let's talk about cutting costs by automating your crypto and fiat payments, integration and processing of crypto payments, international transactions, cheap currency conversion"
    ),
    icon: <EfficiencyRedefined />,
  },
];

const defaultTheme = createTheme();
const Business: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToRegistration = (event: any) => {
    navigate("/registration");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Business")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Customizable business solutions on the highest level"
                    )}
                    subtitle={i18next.t(
                      "With us, it's easy to send and receive payments, send payouts, and easily manage your funds"
                    )}
                    smallText={i18next.t("Join customers all over the world")}
                    buttonText={i18next.t("Create an account")}
                    onClickFunction={navigateToRegistration}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <div className="icon-text-widgets-container">
                <IconTextWidget2
                  iconSrc={CurrencyNewIcon}
                  title={i18next.t("Accounts in USD, EUR and other currencies")}
                  text={i18next.t("Accounts in USD, EUR and other currencies")}
                />
                <IconTextWidget2
                  iconSrc={MerchantNewIcon}
                  title={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                  text={i18next.t(
                    "Local and international deposits and withdrawals"
                  )}
                />
                <IconTextWidget2
                  iconSrc={LanguagesNewIcon}
                  title={i18next.t("Multilingual Support")}
                  text=""
                />
              </div>
              <div className="icon-text-widgets-container2">
                <IconTextWidget2
                  iconSrc={UsersNewIcon}
                  title={i18next.t("Robust multi-tier account security")}
                  text={i18next.t(
                    "Robust multi-tier account security with 2FA"
                  )}
                />
                <IconTextWidget2
                  iconSrc={Icon24}
                  title={i18next.t("First response in 1 hour or faster")}
                  text={i18next.t("First response in 1 hour or faster")}
                />
              </div>

              <Box className="">
                <LogoTextVvidget2
                  text={i18next.t(
                    "We offer you IBAN accounts, up to 65 currencies for your account balance, local and international payments, wire bank transfers, instant payments, cryptocurrencies."
                  )}
                />
              </Box>

              <Box sx={{ marginTop: 5, marginBottom: 5 }}>
                <ImageWithText2
                  backgroundImage={paymentsImage}
                  noiseImage="/img/payments-noise.png"
                  title={i18next.t("Flexibility & Innovation")}
                  text={i18next.t(
                    "We, on the other hand, are flexible, fast, regulated, and recognize the needs of companies and consumers"
                  )}
                />
              </Box>

              <Box>
                <ImagesWithText />
              </Box>
              <Box className="boxColumnContainerNew">
                {offerContent.map((item: any, key: number) => {
                  const isEven = key % 2 === 0;
                  const extraClass = isEven
                    ? "boxColumnItemLeft"
                    : "boxColumnItemRight";

                  let bgSet = "gradientBgSet1New";
                  if (key === 0) {
                    bgSet = "gradientBgSet1New";
                  } else if (key === 1) {
                    bgSet = "gradientBgSet2New";
                  } else if (key === 2) {
                    bgSet = "gradientBgSet3New";
                  } else if (key === 3) {
                    bgSet = "gradientBgSet4New";
                  } else if (key === 4) {
                    bgSet = "gradientBgSet1New";
                  } else {
                    bgSet = "gradientBgSet2New";
                  }

                  return (
                    <Box
                      key={key}
                      className={`boxColumnItemNew gradientBgSet ${extraClass}`}
                      sx={{
                        display: "flex",
                        flexDirection: isEven ? "row" : "row-reverse",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography className="textsLandingPageHeadingNew">
                          {item.title}
                        </Typography>
                        <Typography
                          paragraph
                          className="boxColumnContainerTextNew"
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      </Box>
                      <Box
                        className={`${bgSet}`}
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Business;
