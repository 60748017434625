import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";

const defaultTheme = createTheme();

const VictorumGroup: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigateToVicGroup = (event: any) => {
    window.open("https://victorum-group.com/", "_blank");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Victorum Group")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "We are part of a strong worldwide network with 32 branches in 17 countries"
                    )}
                    subtitle={i18next.t(
                      "Join Our Global Network, 32 Branches in 17 Countries"
                    )}
                    buttonText={i18next.t("Visit Victorum Group")}
                    onClickFunction={navigateToVicGroup}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew"></Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default VictorumGroup;
