import { useState, useEffect } from "react";
import { IconButton, Typography, Box, Button } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import "./HeaderAvatarIconButton.scss";
import Logo from "src/assets/images/logoPay.png";
import { useNavigate } from "react-router-dom";
import HeaderAvatarModal from "./HeaderAvatarModal";

const HeaderAvatarIconButton = ({ user }: any) => {
  const [boxOpen, setBoxOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleBoxToggle = () => {
    if (window.innerWidth <= 767) {
      setShowModal(true);
    } else {
      setBoxOpen(!boxOpen);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (!event.target.closest(".menu-btn2")) {
        setBoxOpen(false);
      }
    };

    const handleScroll = (event: any) => {
      if (boxOpen) {
        event.preventDefault();
      }
    };

    if (boxOpen) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("scroll", handleScroll, { passive: false });
    } else {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [boxOpen]);

  return (
    <div className="menu-btn2">
      <IconButton
      className="landingMobileIconBtns"
        edge="start"
        color="inherit"
        sx={{
          position: "relative",
        }}
        onClick={handleBoxToggle}
      >
        <PersonOutlineIcon
          style={{
            minWidth: "25px",
            maxWidth: "25px",
            minHeight: "25px",
            maxHeight: "25px",
          }}
        />
      </IconButton>
      {boxOpen && !showModal && (
        <Box className="headerAvatarBox">
          <Box sx={{ marginBottom: "20px" }}>
            <img src={Logo} alt="VicPay Logo" className="header-logo" />
          </Box>
          <Box sx={{ marginBottom: "20px", marginRight: "8px" }}>
            <Typography className="headerAvatarMenuTextMob">
            Your partner in worldwide money and crypto payments!
            </Typography>
          </Box>
          <Box>
            {user && user.id ? (
              <Button
                onClick={(e) => {
                  navigate("/home");
                }}
                className="headerAvatarMenuSignupMob"
              >
                My Account
              </Button>
            ) : (
              <>
                <Button
                  className="headerAvatarMenuLoginMob"
                  onClick={(e) => {
                    navigate("/login");
                  }}
                >
                  Log in
                </Button>
                <Button
                  className="headerAvatarMenuSignupMob"
                  onClick={(e) => {
                    navigate("/registration");
                  }}
                >
                  Sign up
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      {showModal && (
        <HeaderAvatarModal user={user} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default HeaderAvatarIconButton;
