import { useState, useCallback } from "react";
import { debounce } from "lodash";
import api from "src/store/interceptors/api";

const BankingApiUpdate = (userId: string) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleApiUpdate = useCallback(
    debounce(async (values: any) => {
      if(isSubmitting) return;
      setIsSubmitting(true);
      try {
        const formData = new FormData();

        if (values && values.documents) {
          for (const keyFile in values.documents) {
            if (values.documents.hasOwnProperty(keyFile)) {
              if (values.documents[keyFile] && values.documents[keyFile].file) {
                formData.append(keyFile, values.documents[keyFile].file);
              }
            }
          }
        }

        for (const key in values) {
          if (key !== "documents") {
            const value = values[key];
            if (Array.isArray(value)) {
              if (value.length > 0) {
                const valueString = value.join(", ");
                formData.append(key, valueString);
              }
            } else if (value !== null && value !== undefined && value !== "") {
              formData.append(key, value);
            }
          }
        }

        formData.append("verification_type", "onboarding");
        formData.append("_method", "PUT");

        await api.post(`/user-onboarding-data/${userId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }, 1000),
    [userId]
  );

  return { handleApiUpdate, isSubmitting };
};

export default BankingApiUpdate;
