import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import LogoTextVvidget2 from "../components/LogoTextVvidget/LogoTextVvidget2";

const defaultTheme = createTheme();

const WeHire: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContact = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("We hire")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Boost your career and join our rapidly growing team"
                    )}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={navigateToContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box>
                <LogoTextVvidget2
                  text={i18next.t(
                    "VicPay is planning to hire personnel for various areas as part of the company's expansion and we would be very pleased to get to know you"
                  )}
                />
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Innovating Financial Solutions: Join Our Team")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "As a leading fintech company in Canada, we work every day to innovate simple and straightforward financial solutions"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Our goal is to digitise and perfect processes and thus offer services within minutes that traditional banks need days or even weeks to provide"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "To achieve these goals, people are the key to our success - and that's exactly why we are looking for people who have their professional focus in these areas"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                  component="ul"
                  sx={{ marginLeft: "22px" }}
                >
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Stelle")} 1:
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Stelle")} 2:
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Stelle")} 3:
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Stelle")} 4:
                  </li>
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "Discover the VicPay Difference: Join Our Innovative Team"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Wondering what makes working at VicPay so special? Want to know why you'd be hard pressed to find a better employer in Vancouver"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t("It's simple")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                  component="ul"
                  sx={{ marginLeft: "22px" }}
                >
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("different business and activity fields")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Diverse international career opportunities")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Passionate and creative environment")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Passionate and creative environment")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Numerous opportunities for further training")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t(
                      "Central location and excellent transport links"
                    )}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Bright and open interior design")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Office and leisure benefits")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Above-average earning potential")}
                  </li>
                  <li className="titleIconSubTitleDescDesc">
                    {i18next.t("Flexible home office solutions")}
                  </li>
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "The world of finance urgently needs innovation - and we need you"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Simply send us your detailed application documents to"
                  )}{" "}
                  <a href="mailto:info@vicpayments.com" className="linksHover">
                    info@vicpayments.com
                  </a>{" "}
                  {i18next.t(
                    "or contact +1 604-260-0738 to receive information about vacancies quickly by telephone"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default WeHire;
