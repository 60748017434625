import { Box, Typography } from "@mui/material";
import "./TitleTextOverlayImg.scss";
import OverlayImg from "../../../../assets/images/titleTextOverlayImg.png";

interface TitleTextOverlayImgProps {
  icon?: any;
  title: string;
  text?: string;
  text2?: string;
  direction?: "left" | "right";
  hasOverlayImg?: boolean;
}

const TitleTextOverlayImg: React.FC<TitleTextOverlayImgProps> = ({
  icon,
  title,
  text,
  text2,
  direction = "left",
  hasOverlayImg = true,
}) => {
  return (
    <Box className="titleTextOverlayContainerMain">
      {hasOverlayImg && (
        <img
          src={OverlayImg}
          alt="Overlay"
          className={
            direction === "left" ? "overlay-icon-left" : "overlay-icon-right"
          }
        />
      )}
      {icon && (
        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: direction === "left" ? "flex-start" : "flex-end",
          }}
        >
          <img src={icon} alt="icon" />
        </Box>
      )}
      <Box className="titleTextOverlayContainer">
        <Typography
          variant="h3"
          sx={{
            marginBottom: "1rem",
            textAlign:
              direction === "left" ? "left !important" : "right !important",
          }}
          className="titleIconSubTitleDescTitle"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {text && (
          <Typography
            sx={{
              textAlign:
                direction === "left" ? "left !important" : "right !important",
              // maxWidth: direction === "left" ? "1192px" : "1010px",
              marginLeft: direction === "right" ? "auto" : "",
            }}
            variant="body1"
            paragraph
            className="titleIconSubTitleDescDesc"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
        {text2 && (
          <Typography
            sx={{
              textAlign:
                direction === "left" ? "left !important" : "right !important",
              // maxWidth: direction === "left" ? "1192px" : "1010px",
              marginLeft: direction === "right" ? "auto" : "",
            }}
            variant="body1"
            paragraph
            className="titleIconSubTitleDescDesc"
            dangerouslySetInnerHTML={{
              __html: text2,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default TitleTextOverlayImg;
