import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";

const defaultTheme = createTheme();

const License: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/aml-policy");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("License")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t("Better safe than sorry")}
                    subtitle={i18next.t(
                      "Our company complies with the 2019 FCA guidelines against Money Laundering and Terrorist Financing and also complies with the Terrorist Financing Act (PCMLTFA) and associated Regulations, as well as the requirements of other laws and regulations to the extent in which they relate to the Company’s operations"
                    )}
                    buttonText={i18next.t("AML Policy")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "Victorum Capital Services Limited partnered with:"
                  )}
                </Typography>
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Banking")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "3S Money and 3s.money are trading names of 3S Money Club Limited. 3S Money Club Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for issuing of electronic money and the provision of payment services with FCA registration number 900918. 3S Money Club Limited is registered in England and Wales, Company Number 8574246, with its registered office address at 45 Folgate Street, London, E1 6GL, United Kingdom. 3S Money Club Limited is registered with The United Kingdom Information Commissioner's Office with ICO registration number ZA495485."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "3S Money Club Limited (DIFC Branch) is regulated by the Dubai Financial Services Authority (DFSA) with DFSA reference number F007004, authorising it under Article 48 of the Regulatory Law 2004 to carry on the Financial Services comprised in Issuing Payment Instruments and Providing or Operating a Payment Account. 3S Money Club Limited (DIFC Branch) is registered in Dubai International Financial Centre, Licence Number CL5587, with its registered office address at Central Park Towers, Office 17-37, DIFC, Dubai, UAE."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "3S Money (Luxembourg) S.A. is authorised by the Commission de Surveillance du Secteur Financier (CSSF) under the law of 10 November 2009 on payment services for issuing, distribution and refund of electronic money with CSSF registration number W00000016. 3S Money (Luxembourg) S.A. is registered in Luxembourg, R.C.S number B245715, with its registered office address at 25C, Boulevard Royal, L- 2449, Luxembourg, Grand Duchy of Luxembourg"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Crypto")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Triple A is licensed as a Major Payment Institution (MPI) by MAS, the Monetary Authority of Singapore."
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t("License number: PS20200525")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default License;
