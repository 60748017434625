import { Box, Typography } from "@mui/material";
import "../../pages/LandingPagesGlobal2.scss";
import { ReactComponent as VicPayLogoSvg } from "../../../../assets/images/vicpaylogo.svg";
const LogoTextVvidget2 = ({
  text,
  email,
  link,
  linkText,
  text2,
  text3,
}: any) => {
  const emailContent = email
    ? `<a href="mailto:${email}" class="linksHoverWhite" style="margin-left: 5px">${email}</a>`
    : "";

  const combinedContent = `
  ${text || ""}
  ${link ? `<a href="${link}" class="linksHoverWhite">${linkText}</a>` : ""}
  ${text2 || ""}
  ${emailContent} <br>
  ${text3 || ""}

`;

  return (
    <>
      <Box className="logoContainer">
        <Box className="logoGradientBgContainerAnimation">
          <VicPayLogoSvg />
        </Box>

        <Box className="logoPayVvidget2">
          <Typography
            paragraph
            className="textsLandingPage"
            sx={{ color: "#fff !important" }}
            dangerouslySetInnerHTML={{
              __html: combinedContent,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LogoTextVvidget2;
