import React from "react";
import "./IconTextWidget.scss";

interface IconTextWidgetProps {
  iconSrc: string;
  title: string;
  text: string;
}

const IconTextWidget: React.FC<IconTextWidgetProps> = ({
  iconSrc,
  title,
  text,
}) => {
  return (
    <div className="icon-text-widget">
      <img src={iconSrc} alt={title} className="widget-icon" />
      <div className="widget-content">
        <p
          className="widget-text"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {/* <p className="widget-text">{text}</p> */}
      </div>
    </div>
  );
};

export default IconTextWidget;
