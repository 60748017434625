import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";

const defaultTheme = createTheme();

const OnlineNewsroom: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleNavigateContact = () => {
    navigate("/contact-us");
  };
  const sendEmail = (email: any) => {
    const mailtoUrl = `mailto:${email}`;
    window.location.href = mailtoUrl;
  };
  const handleOpenNewWindow = (route: any) => {
    window.open(route, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Legal Information")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "All relevant information regarding our company on a glance!"
                    )}
                    subtitle={""}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={handleNavigateContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t(
                    "This website, the app, social media accounts and online systems are operated by"
                  )}
                </Typography>
                <Box className="contactUsHeadingBoxRowReverse">
                  <Box>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage"
                    >
                      <span className="boldBlueFont">
                        {" "}
                        {i18next.t("Address")}:
                      </span>{" "}
                      <br />
                      <span className="textsLandingsmallNoFontSizeNew addressBoxAlignments">
                        Victorum Capital Services Limited
                        <br />
                        2/3 48 West George Street,
                        <br />
                        Glasgow, Scotland, G2 1BP
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont"> {i18next.t("CEO")} </span>
                      <span className="basicFont">Osbert Döhl</span>
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont">
                        {i18next.t("Company")}:
                      </span>{" "}
                      <span className="basicFont">
                        {i18next.t("Registered in Scotland, United Kingdom")}
                      </span>
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      className="textsLandingPage columnDirectionContactMobile"
                    >
                      <span className="boldBlueFont">
                        {i18next.t("Company number")}
                      </span>{" "}
                      <span className="basicFont">SC745907</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/*<Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("You can reach our company via")}
                </Typography>

                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="boldBlueFont">{i18next.t("Phone")}:</span>{" "}
                  <span className="basicFont"> +1 604-260-0738</span>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage"
                >
                  <span className="boldBlueFont">{i18next.t("Email")}:</span>{" "}
                  <span
                    className="basicFont linksHover"
                    onClick={() => sendEmail("info@vicpayments.com")}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    info@vicpayments.com
                  </span>
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Our company is regulated by")}
                </Typography>

                <Typography
                  variant="body1"
                  paragraph
                  className="textsLandingPage contactHeadingInfoBox"
                >
                  <span className="textsLandingsmallNoFontSizeNew">
                    {i18next.t("FINTRAC Canada")}
                    <br />
                    {i18next.t("112 Donald Trump Street")}
                    <br />
                    {i18next.t("Vancouver, BC V6B 0B6")}
                    <br />
                    <span
                      className="linksHover"
                      onClick={() => {
                        handleOpenNewWindow(
                          "https://fintrac-canafe.canada.ca/"
                        );
                      }}
                    >
                      www.fintrac.ca
                    </span>
                  </span>
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("Our status")}
                </Typography>

                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "Registered provider of money service business (MSB), regulated by the FINTRAC"
                  )}{" "}
                  <span
                    style={{ marginLeft: "5px" }}
                    className="linksHover"
                    onClick={() => {
                      handleOpenNewWindow("https://fintrac-canafe.canada.ca/");
                    }}
                  >
                    www.fintrac.ca
                  </span>
                </Typography>
              </Box>*/}
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default OnlineNewsroom;
