import api from "../interceptors/api";

import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import LoupeIcon from "@mui/icons-material/Loupe";

const getTransactions = async (searchValue?: string | null) => {
  let url = `/transactions`;
  if (searchValue) {
    url += searchValue;
  }
  const response = await api.get(url);
  return response.data;
};

const getAccountDataByStatus = async (searchValue?: string | null) => {
  let url = `/users-account-status`;
  if (searchValue) {
    url += searchValue;
  }
  const response = await api.get(url);
  return response.data;
};

const getAccountRequestsData = async (searchValue?: string | null) => {
  let url = `/user-balance/account-requests`;
  if (searchValue) {
    url += searchValue;
  }
  const response = await api.get(url);
  return response.data;
};

const getTransactionByUserId = async (userId: string | number) => {
  const response = await api.get(
    `/transactions?per_page=100&exact_search[user_id]=${userId}`
  );
  return response.data;
};

const getTransactionById = async (transactionId: string) => {
  const response = await api.get(`/transactions/${transactionId}`);
  return response.data;
};

const downloadTransactionInvoice = async (
  transactionId: string,
  onStart: any,
  onFinish: any
) => {
  try {
    onStart();

    const response = await api.get(`/transaction-invoice/${transactionId}`, {
      responseType: "blob",
    });
    const file = new Blob([response.data], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);

    const downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.setAttribute(
      "download",
      `Transaction-Invoice-${transactionId}.pdf`
    );
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    URL.revokeObjectURL(fileURL);

    onFinish();
  } catch (error) {
    console.error("Error downloading the PDF: ", error);
    onFinish();
  }
};

const uploadAttachment = async (transactionId: string, file: any) => {
  const formData = new FormData();
  formData.append("attachment", file);
  formData.append("_method", "PATCH");

  try {
    const response = await api.post(
      `/transactions/${transactionId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error uploading file: ", error);
    throw error;
  }
};

const statusOptions = [
  { name: "transaction_payment_waiting", label: "Transaction created" },
  { name: "transaction_processing", label: "Transaction in process" },
  { name: "transaction_entered", label: "Transaction in process" },
  {
    name: "transaction_payment_processing",
    label: "Transaction in process",
  },
  { name: "completed", label: "Completed" },
  { name: "transaction_rejected", label: "Rejected" },
];
function getStatus(status: string) {
  const obj = statusOptions.find((option) => option.name === status);
  if (obj) return obj;
  return null;
}

const translateStatus = (status: string) => {
  if (!status) return "";

  const findStatus = getStatus(status);
  if (findStatus && findStatus.label) return findStatus.label;
  const lowercaseStatus = status.toLowerCase();
  switch (lowercaseStatus) {
    case "transaction_payment_waiting":
    case "processing":
      return "By you · Sending Processing";
    case "funds_converted":
      return "By you - sending";
    case "incoming_payment_waiting":
      return "By you · Sending Waiting";
    case "outgoing_payment_sent":
      return "Sent by you";
    case "failed":
    case "funds_refunded":
    case "bounced_back":
    case "cancelled":
      return "Cancelled";
    default:
      return status;
  }
};

const getProperIconBasedOnStatusBalance = (status: string, type: string) => {
  if (!type) return;
  const lowercaseStatus = status.toLowerCase();
  const lowerCaseType = type.toLowerCase();
  switch (true) {
    case lowerCaseType.includes("balance_in_credit_card"):
      return "credit-card";
    case lowerCaseType.includes("balance_in_bank"):
      return "bank";
    case lowerCaseType.includes("balance_in_crypto"):
      return "crypto";
    case lowerCaseType.includes("balance_in"):
      return "plus";
    case lowerCaseType.includes("p_transfer_in"):
      return "plus";
    case lowerCaseType.includes("p_transfer_auto_in"):
      return "plus";
    case lowerCaseType.includes("p_conversion_in"):
      return "plus";

    case lowercaseStatus.includes("balance_out"):
      return "up";
    case lowercaseStatus.includes("m_transfer_out"):
      return "up";
    case lowercaseStatus.includes("p_transfer_auto_out"):
      return "up";
    case lowercaseStatus.includes("p_conversion_out"):
      return "up";
    case lowercaseStatus.includes("p_transfer_out"):
      return "up";
    case lowercaseStatus.includes("p_transfer_fee"):
      return "up";
    default:
      return "up";
  }
};

const getProperIconBasedOnStatus = (status: string) => {
  if (!status) return;
  const lowercaseStatus = status.toLowerCase();
  switch (true) {
    case lowercaseStatus.includes("incoming payment"):
      return "plus";
    case lowercaseStatus.includes("outgoing"):
      return "up";
    case lowercaseStatus === "processing":
    case lowercaseStatus === "funds_converted":
    case lowercaseStatus === "outgoing_payment_sent":
    case lowercaseStatus === "failed":
    case lowercaseStatus === "funds_refunded":
    case lowercaseStatus === "bounced_back":
    case lowercaseStatus === "sent":
    case lowercaseStatus === "m_transfer_out":
    case lowercaseStatus === "p_transfer_out":
    case lowercaseStatus === "p_transfer_auto_out":
    case lowercaseStatus === "p_conversion_out":
      return "up";
    default:
      return "up";
  }
};

const processTransactions = (transactions: any) => {
  const inProgressStatuses = ["processing", "funds_converted"];
  const canceledStatuses = ["funds_refunded", "bounced_back", "failed"];
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const formatDate = (date: any) => date.toISOString().split("T")[0];

  const sections: any = {
    inProgress: [],
    today: [],
    yesterday: [],
    byDate: {},
    canceled: [],
  };

  for (const transaction of transactions.data) {
    const transactionDate = new Date(transaction.created_at);
    const formattedDate = formatDate(transactionDate);

    if (inProgressStatuses.includes(transaction.status)) {
      sections.inProgress.push(transaction);
    } else if (canceledStatuses.includes(transaction.status)) {
      sections.canceled.push(transaction);
    } else {
      const isToday = formattedDate === formatDate(today);
      const isYesterday = formattedDate === formatDate(yesterday);

      if (isToday) {
        sections.today.push(transaction);
      } else if (isYesterday) {
        sections.yesterday.push(transaction);
      } else {
        if (!sections.byDate[formattedDate]) {
          sections.byDate[formattedDate] = [];
        }
        sections.byDate[formattedDate].push(transaction);
      }
    }
  }

  const sectionsArray: any = [];

  if (sections.inProgress?.length > 0) {
    sectionsArray.push({
      name: "In Progress",
      listOfTransactions: sections.inProgress,
    });
  }
  if (sections.today?.length > 0) {
    sectionsArray.push({ name: "Today", listOfTransactions: sections.today });
  }

  if (sections.yesterday?.length > 0) {
    sectionsArray.push({
      name: "Yesterday",
      listOfTransactions: sections.yesterday,
    });
  }

  Object.entries(sections.byDate).forEach(([date, transactions]) => {
    sectionsArray.push({ name: date, listOfTransactions: transactions });
  });

  if (sections.canceled?.length > 0) {
    sectionsArray.push({
      name: "Canceled",
      listOfTransactions: sections.canceled,
    });
  }
  return sectionsArray;
};

function formatNumber(value: any) {
  if (value === null || value === undefined) {
    return 0;
  }
  if (value % 1 === 0) {
    return parseInt(value);
  } else {
    const formattedValue =
      value && value > 0 ? parseFloat(value).toFixed(2) : 0;
    return formattedValue;
  }
}

function formatNumberCrypto(value: any) {
  if (value === null || value === undefined) {
    return 0;
  }
  if (value % 1 === 0) {
    return parseInt(value);
  } else {
    const formattedValue =
      value && value > 0 ? parseFloat(value).toFixed(8) : 0;
    return formattedValue;
  }
}

function formatNumberDecimal(value: any) {
  return value && value > 0 ? parseFloat(value).toFixed(2) : 0;
}

function formatNumberDecimalFormated(value: any) {
  let amount = value && value > 0 ? parseFloat(value).toFixed(2) : 0;
  const options: any = {
    style: "currency", // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return amount.toLocaleString("en-US", options);
}

function getProperReceivedText(status: string) {
  switch (status) {
    case "processing":
    case "funds_converted":
    case "outgoing_payment_sent":
    case "transaction_payment_waiting":
    case "failed":
    case "funds_refunded":
    case "bounced_back":
      return "up";
    case "incoming_payment_received":
    case "incoming_payment_converted":
    case "incoming_payment_refunded":
    case "incoming_payment_bounced_back":
    case "incoming_payment_failed":
    case "incoming_payment_waiting":
      return "plus";
    default:
      return "up";
  }
}

function getAccountDataKeywords(keyword: string) {
  switch (keyword) {
    case "legalType":
      return "Legal Type";
    case "swiftCCode":
      return "Swift Code";
    case "accountNumber":
      return "Account number";
    default:
      return keyword
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
  }
}
function getTransactionDetails(transaction: any) {
  let accountData = null;
  if (transaction) {
    if (transaction?.recipient?.meta?.details) {
      const details = transaction.recipient.meta.details;
      let fields: any = [];
      Object.keys(details).forEach((key) => {
        if (typeof details[key] === "object") {
        } else {
          fields = [
            ...fields,
            { title: getAccountDataKeywords(key), value: details[key] },
          ];
        }
      });
      accountData = { ...transaction, fields: fields };
      return accountData;
    }
  }
  return null;
}

const transactionsService = {
  getTransactions,
  getTransactionById,
  getTransactionByUserId,
  translateStatus,
  processTransactions,
  downloadTransactionInvoice,
  getProperIconBasedOnStatus,
  getProperIconBasedOnStatusBalance,
  formatNumber,
  formatNumberCrypto,
  formatNumberDecimal,
  formatNumberDecimalFormated,
  uploadAttachment,
  getProperReceivedText,
  getTransactionDetails,
  getAccountDataByStatus,
  getAccountRequestsData,
};

export default transactionsService;
