import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import TitleTextVvidget from "../components/TitleTextVvidget/TitleTextVvidget";
import FeesImage from "../../../assets/images/feesImage.png";
import FeesImageOverlay from "../../../assets/images/feesImgOverlayIcon.svg";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";
import ImageWithText2 from "../components/ImageWithText/ImageWithText2";
import UsersBlue from "../../../assets/images/users-blue-icon.svg";
import CardBlue from "../../../assets/images/card-icon.svg";
import SettingsBlue from "../../../assets/images/settings-icon.svg";
import CardSend from "../../../assets/images/card-send.svg";
import BankNote from "../../../assets/images/banknote.svg";
import CryptoBlue from "../../../assets/images/cryptoBlueIcon.svg";
import TitleIconSubTitleDesc from "../components/TitleIconSubTitleDesc/TitleIconSubTitleDesc";
import CardTransfer from "../../../assets/images/card-transfer.svg";

const defaultTheme = createTheme();
const Fees: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const navigateToContact = (event: any) => {
    navigate("/contact-us");
  };
  const items = [
    {
      subTitle: i18next.t("Contact us"),
      desc: i18next.t(
        "In case of any questions regarding our fees or any other account related questions, please do not hesitate to send us a message to"
      ),
      email: "info@vicpayments.com",
    },
  ];

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Fees")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "Faster, cheaper and better than the others"
                    )}
                    subtitle={i18next.t(
                      "VicPay is the partner for anyone who has had enough of slow branch banks and high fees!"
                    )}
                    smallText={i18next.t("In case of any questions")}
                    buttonText={i18next.t("Contact us")}
                    onClickFunction={navigateToContact}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <TitleTextOverlayImg
                  title={i18next.t("VicPay's Transparency")}
                  text={i18next.t(
                    "For you as a client this means that you’ll always know the fee for your transaction or payment process in advance"
                  )}
                  direction="left"
                />
                <TitleTextOverlayImg
                  title={i18next.t("VicPay for Everyone")}
                  text={i18next.t(
                    "By the way: to keep things easy for our clients our fee schedule applies to individual and business accounts"
                  )}
                  direction="right"
                />
              </Box>
              <Box sx={{ padding: "3rem 0 3rem 0" }}>
                <ImageWithText2
                  backgroundImage={FeesImage}
                  noiseImage={FeesImageOverlay}
                  title={i18next.t("Transparent Pricing, No Surprises")}
                  text={i18next.t(
                    "This means that we offer fair and transparent pricing, no hidden fees, no unnecessary subscriptions and no maintenance fees"
                  )}
                />
              </Box>

              <Box className="titleTextVvidgetFlexBox">
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    showBoxStyle={true}
                    icon={UsersBlue}
                    title={i18next.t("Accounts")}
                    text={i18next.t(
                      "Account opening MultiCurrency Account, an IBAN account: 150 EUR"
                    )}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    icon={CardBlue}
                    showBoxStyle={true}
                    title={i18next.t("Debit Card")}
                    text={i18next.t("Getting the VicPay card: -")}
                    text2={i18next.t("Yearly Fees: -")}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    icon={SettingsBlue}
                    showBoxStyle={true}
                    title={i18next.t("Maintenance")}
                    text={i18next.t(
                      "Monthly maintenance fee per client: 50 EUR"
                    )}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    icon={CardSend}
                    showBoxStyle={true}
                    title={i18next.t("Sending money")}
                    text={i18next.t("Sending money: 0,20%")}
                    text2={i18next.t("SWIFT: 25 EUR")}
                    text3={i18next.t("Local: 7 EUR")}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    icon={BankNote}
                    showBoxStyle={true}
                    title={i18next.t("Receiving Money")}
                    text={i18next.t("Receiving money: 0,20%")}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    icon={CryptoBlue}
                    showBoxStyle={true}
                    title={i18next.t("Crypto Currencies")}
                    text={i18next.t("Receiving Crypto Currencies: 1.3%")}
                  />
                </Box>
                <Box className="titleTextVvidgetFlexBoxItem">
                  <TitleTextVvidget
                    icon={CardTransfer}
                    showBoxStyle={true}
                    title={i18next.t("Escrow processing")}
                    text={i18next.t(
                      "Full processing: 0,40% from Escrow amount"
                    )}
                  />
                </Box>
              </Box>
              <Box>
                <TitleIconSubTitleDesc items={items} />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Fees;
