import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLanding from "../components/HeaderLanding/HeaderLanding";
import { useAppSelector } from "src/store";
import FooterLanding from "../components/FooterLanding/FooterLanding";
import "./LandingPagesGlobal.scss";
import i18next from "i18next";
import HeadlineVvidgetSmall2 from "../components/HeadlineVvidget/HeadlineVvidgetSmall2";
import TitleTextOverlayImg from "../components/TitleTextOverlayImg/TitleTextOverlayImg";

const defaultTheme = createTheme();

const Regulation: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToContactUs = (event: any) => {
    navigate("/aml-policy");
  };

  return (
    <div>
      <HeaderLanding user={user} />
      <div className="landingMainContainer">
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box className="titleHeadlineSmallBG">
              <Box className="landingPagesMainBox">
                <Box>
                  <Typography className="globalHeading">
                    {i18next.t("Regulation Authority")}
                  </Typography>
                </Box>
                <Box>
                  <HeadlineVvidgetSmall2
                    headline={i18next.t(
                      "A reliable service needs reliable regulation"
                    )}
                    subtitle={i18next.t(
                      "Our company complies with the 2019 FCA guidelines against Money Laundering and Terrorist Financing and also complies with the Terrorist Financing Act (PCMLTFA) and associated Regulations, as well as the requirements of other laws and regulations to the extent in which they relate to the Company’s operations"
                    )}
                    buttonText={i18next.t("AML Policy")}
                    onClickFunction={navigateToContactUs}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="landingPagesMainBoxNew">
              <Box>
                <TitleTextOverlayImg
                  title={i18next.t(
                    "Regulatory Oversight: www.vicpayments.com is operated by Victorum Capital Services Limited"
                  )}
                  text={i18next.t(
                    "All services excluding cryptocurrencies are handled as a tied registred agent of 3S Money Club Limited. 3S Money Club Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for issuing of electronic money and the provision of payment services with FCA registration number 900918. 3S Money Club Limited is registered with The United Kingdom Information Commissioner's Office with ICO registration number ZA495485"
                  )}
                  hasOverlayImg={false}
                />
              </Box>
              <Box>
                <TitleTextOverlayImg
                  title={i18next.t(
                    "Here the full contact details of the FCA, London"
                  )}
                  hasOverlayImg={false}
                />
              </Box>

              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("FCA Financial Conduct Authority")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t("Head Office")}
                  <br />
                  12 Endeavour Square
                  <br />
                  London E20 1JN
                  <br />
                  United Kingdom
                  <br />
                  0800 111 6768 {i18next.t("freephone")}
                  <br />
                  0300 500 8082 {i18next.t("from the UK")}
                  <br />
                  +44 207 066 1000 {i18next.t("from abroad")}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography variant="h3" className="titleIconSubTitleDescTitle">
                  {i18next.t("The UK Financial Services Authority")}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "The Financial Conduct Authority (FCA) is a financial regulatory body in the United Kingdom, which operates independently of the UK Government and is financed by charging fees to members of the financial services industry"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "The Financial Conduct Authority (FCA) is a financial regulatory body in the United Kingdom, which operates independently of the UK Government and is financed by charging fees to members of the financial services industry"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "It focuses on the regulation of conduct by both retail and wholesale financial services firms. Like its predecessor the FSA (Financial Services Authority), the FCA is structured as a company limited by guarantee"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "The FCA works alongside the Prudential Regulation Authority and the Financial Policy Committee to set regulatory requirements for the financial and insurance sector"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "So as you can see: we are giving our very best to ensure the maximum level of transparency and safety for each and every client"
                  )}
                </Typography>
              </Box>
              <Box className="titleTextOverlayContainerMain">
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t("So")}
                  <b> {i18next.t("don´t waste time")}</b>,{" "}
                  <span
                    className="linksHover"
                    onClick={() => navigate("/registration")}
                  >
                    {i18next.t("click here")}
                  </span>{" "}
                  {i18next.t(
                    "and open your free VicPay account and start saving money on transaction and currency exchange - "
                  )}{" "}
                  <b>{i18next.t("now")}</b>
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  className="titleIconSubTitleDescDesc"
                >
                  {i18next.t(
                    "In case of any open questions regarding the compliance and regulation process at VicPay, drop us a message to"
                  )}{" "}
                  <a href="mailto:info@vicpayments.com" className="linksHover">
                    info@vicpayments.com
                  </a>{" "}
                  {i18next.t("at anytime")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
      <Box sx={{ marginTop: "4rem" }}>
        <FooterLanding />
      </Box>
    </div>
  );
};

export default Regulation;
