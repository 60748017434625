import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl } from "@mui/material";
import { ReactComponent as DeutchIcon } from "../../../assets/images/deLang.svg";
import { ReactComponent as USAIcon } from "../../../assets/images/usaLang.svg";
import { ReactComponent as SpainIcon } from "../../../assets/images/spanishLang.svg";
import { ReactComponent as ItalyIcon } from "../../../assets/images/italianLang.svg";
import { ReactComponent as CroatiaIcon } from "../../../assets/images/croationLang.svg";
import { ReactComponent as ChinaIcon } from "../../../assets/images/chineseLang.svg";
import "./LanguageSelector.scss";

const aryLanguages = ["en-US", "de-DE", "es-ES", "it-IT", "hr-HR", "zh-CN"];
const LanguageSelector = ({ onChange, landing = false }: any) => {
  const { i18n } = useTranslation();
  const handleLanguageChange = (event: any) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("appLanguage", selectedLanguage);
    if (onChange) {
      onChange(selectedLanguage);
    }
    window.location.reload();
  };

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={aryLanguages.includes(i18n.language) ? i18n.language : "en-US"}
        onChange={handleLanguageChange}
        sx={{
          height: "25px",
          border: "none",
          "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
            {
              height: "25px",
              paddingLeft: "0",
              display: "flex",
              alignItems: "center",
              "@media (max-width: 500px)": {
                paddingRight: "22px",
              },
              "@media (max-width: 350px)": {
                paddingRight: "12px",
              },
            },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        renderValue={(value) => (
          <div className="iconLanguageAndText">
            {value === "en-US" ? (
              <>
                <USAIcon className="iconLanguage" />
                {!landing && i18n.t("English")}
              </>
            ) : value === "de-DE" ? (
              <>
                <DeutchIcon className="iconLanguage" />
                {!landing && i18n.t("German")}
              </>
            ) : value === "hr-HR" ? (
              <>
                <CroatiaIcon className="iconLanguage" />
                {!landing && i18n.t("Croatian")}
              </>
            ) : value === "it-IT" ? (
              <>
                <ItalyIcon className="iconLanguage" />
                {!landing && i18n.t("Italian")}
              </>
            ) : value === "es-ES" ? (
              <>
                <SpainIcon className="iconLanguage" />
                {!landing && i18n.t("Spanish")}
              </>
            ) : value === "zh-CN" ? (
              <>
                <ChinaIcon className="iconLanguage" />
                {!landing && i18n.t("Chinese")}
              </>
            ) : null}
          </div>
        )}
      >
        <MenuItem value="en-US">
          <div className="iconLanguageAndText">
            <USAIcon className="iconLanguage" />
            {!landing && i18n.t("English")}
          </div>
        </MenuItem>
        <MenuItem value="de-DE">
          <div className="iconLanguageAndText">
            <DeutchIcon className="iconLanguage" />
            {!landing && i18n.t("German")}
          </div>
        </MenuItem>
        <MenuItem value="hr-HR">
          <div className="iconLanguageAndText">
            <CroatiaIcon className="iconLanguage" />
            {!landing && i18n.t("Croatian")}
          </div>
        </MenuItem>
        <MenuItem value="it-IT">
          <div className="iconLanguageAndText">
            <ItalyIcon className="iconLanguage" />
            {!landing && i18n.t("Italian")}
          </div>
        </MenuItem>
        <MenuItem value="es-ES">
          <div className="iconLanguageAndText">
            <SpainIcon className="iconLanguage" />
            {!landing && i18n.t("Spanish")}
          </div>
        </MenuItem>
        <MenuItem value="zh-CN">
          <div className="iconLanguageAndText">
            <ChinaIcon className="iconLanguage" />
            {!landing && i18n.t("Chinese")}
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
